import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/index'

import { MESSAGES } from '@global/message'
import { TOKEN_HELPERS } from '@utils/helpers'
import { workspaceAdapter } from '@containers/dashboard/adapter/workspace.adapter'

import { WORKSPACE_ACTIONS } from './actions'
import { WORKSPACE } from './types'
import { EDITORS_WORKSPACE, USERS_WORKSPACE, VIEWERS_WORKSPACE } from '../mock'

const apiCalls = {
  getWorkspaceSummaryApi: ({ token, workspaceId }) => Axios.get(`workspace/${workspaceId}/`,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  editWorkspaceSummaryApi: ({ token, workspaceId, data }) => Axios.put(`workspace/${workspaceId}/`, data,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  regenerateApiKeyApi: ({ token, workspaceId }) => Axios.post(`workspace/${workspaceId}/regenerate_api_key/`, {},
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  removeWorkspaceApi: ({ token, workspaceId }) => Axios.delete(`workspace/${workspaceId}/`,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    })
}

export function* getWorkspaceData ({ payload }) {
  try {
    let { data: workspace } = yield call(apiCalls.getWorkspaceSummaryApi, payload)

    workspace = {
      ...workspaceAdapter(workspace),
      users: USERS_WORKSPACE,
      editors: EDITORS_WORKSPACE,
      viewers: VIEWERS_WORKSPACE
    }
    yield put(WORKSPACE_ACTIONS.WORKSPACE_DATA_SUCCESS(workspace))
  } catch (error) {
    yield put(WORKSPACE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* getWorkspaceSummary ({ payload }) {
  try {
    let { data: workspaceSummary } = yield call(apiCalls.getWorkspaceSummaryApi, payload)
    workspaceSummary = workspaceAdapter(workspaceSummary)

    yield put(WORKSPACE_ACTIONS.WORKSPACE_SUMMARY_SUCCESS(workspaceSummary))
  } catch (error) {
    yield put(WORKSPACE_ACTIONS.WORKSPACE_SUMMARY_ERROR(error))
  }
}

export function* editWorkspaceSummary ({ payload }) {
  try {
    const { token, workspaceId } = payload
    yield call(apiCalls.editWorkspaceSummaryApi, payload)
    const { status, data } = yield call(apiCalls.getWorkspaceSummaryApi, { token, workspaceId })
    const workspaceSummaryEdited = workspaceAdapter(data)
    yield put(WORKSPACE_ACTIONS.WORKSPACE_SUMMARY_SUCCESS(workspaceSummaryEdited))
    yield put(WORKSPACE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.SUCCESSFULLY_EDITED_WORKSPACE }))
  } catch (error) {
    yield put(WORKSPACE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* regenerateApiKey ({ payload }) {
  try {
    const { status, data } = yield call(apiCalls.regenerateApiKeyApi, payload)
    const workspaceSummaryEdited = workspaceAdapter(data)
    yield put(WORKSPACE_ACTIONS.WORKSPACE_SUMMARY_SUCCESS(workspaceSummaryEdited))
    yield put(WORKSPACE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.API_KEY_REGENERATED }))
  } catch (error) {
    yield put(WORKSPACE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* removeWorkspace ({ payload }) {
  try {
    const { status } = yield call(apiCalls.removeWorkspaceApi, payload)

    yield put(WORKSPACE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.WORKSPACE_REMOVED }))
  } catch (error) {
    yield put(WORKSPACE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export default function* saga () {
  yield takeLatest(WORKSPACE.GET_WORKSPACE_DATA, getWorkspaceData)
  yield takeLatest(WORKSPACE.GET_WORKSPACE_SUMMARY, getWorkspaceSummary)
  yield takeLatest(WORKSPACE.EDIT_WORKSPACE_SUMMARY, editWorkspaceSummary)
  yield takeLatest(WORKSPACE.REGENERATE_API_KEY, regenerateApiKey)
  yield takeLatest(WORKSPACE.REMOVE_WORKSPACE, removeWorkspace)
}

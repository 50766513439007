import styled from 'styled-components'
import { spacing } from '@global/theme'
import { HEADER_HEIGHT } from '@routes/constants'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const Head = styled.header`
  position: absolute;
  width: 100%;
  grid-area: header;
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.primary.main
    return theme.colors.grays.dark
  }};
  z-index: 3;
  height: ${HEADER_HEIGHT};
  padding: ${spacing.two} ${spacing.three};
  display: flex;
  justify-content: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'space-between' : 'end')};
  align-items: center;

  & > p {
    color: ${({ theme }) => (theme.colors.text.content)}!important;
  }

  & > span {
    color: ${({ theme }) => (theme.colors.text.white)}!important;
    cursor: pointer;
    transition: none !important;
  
    &:hover {
      transition: none !important;
      border-radius: 50%;
      background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.background.light
    return theme.colors.primary.hover
  }};
        
      outline: ${({ theme }) => {
    const currentColor = theme.name === THEME_NAME.DARK
      ? theme.colors.background.light
      : theme.colors.primary.hover
    return `${spacing.half} solid ${currentColor}`
  }};
        
      outline-offset: -1px;
    }
  }
`

export const ProfileData = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  > p {
    color: ${({ theme }) => theme.colors.text.white}!important;
  }
`

export const Img = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => (borderRadius && borderRadius)};
  object-fit: cover;
`

export const WraperImgProfile = styled.div`
  cursor: pointer;
  margin: 0 ${spacing.two};
`

import styled from 'styled-components'
import { spacing } from '@global/theme'
import { COLUMN_TYPE } from '@global/constants'

export const Wrapper = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: ${({ type }) => (type === COLUMN_TYPE.HORIZONTAL ? 'row' : 'column')};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-star'};
  align-items: ${({ alignItems }) => alignItems || 'center'};

  padding: ${spacing.one} 0;
  box-sizing: border-box;
  margin: 0 !important;

  & > div {
    margin-right: ${spacing.two}!important;
  }
`

export const Item = styled.div`
  height: ${spacing.three};
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  margin: 0 !important;
`

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: ${spacing.half};
  width: ${spacing.oneAndAHalf};
  height: ${spacing.oneAndAHalf};
  border-radius: 50%;
  background:  ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.text.disabled};
`

export const RadioButton = styled.input`
margin: 0;
margin-right: ${spacing.half};
opacity: 0;
z-index: 1;
cursor: pointer;
width: ${spacing.three};
height: ${spacing.three};

&:hover ~ ${RadioButtonLabel} {
  border-color: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
  &::after {
    display: block;
    color: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
    width: ${spacing.oneAndAHalf};
    height: ${spacing.oneAndAHalf};
    margin: ${spacing.half};
  }
}

&:checked + ${Item} {
  background: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
  border: 0 solid ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
}

&:checked + ${RadioButtonLabel} {
  background: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
  border: 1px solid ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
  &::after {
    display: block;
    color: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
    width: ${spacing.oneAndAHalf};
    height: ${spacing.oneAndAHalf};
    margin: ${spacing.half};
  }
}

&:active:enabled  + ${RadioButtonLabel} {
    outline: ${spacing.quarter} solid ${({ theme, color, disabled }) => {
  if (disabled) return theme.colors.background.main
  return theme.colors[color]?.main
}
};
    outline-offset: ${spacing.quarter};
  }
}
`

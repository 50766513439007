import React, { Suspense, lazy, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { TextContainer, WrapperLoading } from '@global/styles'
import { debounce } from '@utils/helpers'
import {
  TEXT_CONTENT,
  TOAST_PROPERTIES,
  TOAST_POSITION,
  RESOURCE_TYPE
} from '@global/constants'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import Loading from '@components/loading'
import { useWindowDimensions } from '@components/windowDimensions'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'
import DropdownMenuFloat from '@components/dropdownMenu'
import { useNotification } from 'src/context/notification.context'
import { Text } from '@baseComponents/texts'
import DropdownItem from '@components/dropdownMenu/components/dropdownItem'
import { useUser } from '@containers/authentication/utils/hook'

import { FileManagerContainer, FilesCardContainer } from './styles'
import FileCard from './components/fileCard'
import UploadFileModal from './components/uploadFileModal'
import UrlModal from './components/urlModal'

import useRedux from './redux'
import { MEDIA_FILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { MENU_ITEMS, MENU_ITEMS_FOLDER, MENU_ITEMS_FUNCTION } from './data'
import CreateEditFolderModal from './components/createEditFolderModal'

const Modal = lazy(() => import('@components/modal'))

const MODAL_TITLE = {
  UPLOAD_FILE: 'Upload media file',
  EDIT_FILE: 'Edit media file',
  URL_FILE: 'Url media file',
  EDIT_FOLDER: 'Edit folder',
  CREATE_FOLDER: 'Create folder'
}

const FileManager = forwardRef((props, ref) => {
  const {
    rowGrid,
    columnStart,
    columnEnd,
    folderClick,
    rootFolderId,
    breadcrumbs
  } = props

  useRedux()
  const { device } = useWindowDimensions()
  const { getNotification } = useNotification()
  const dispatch = useDispatch()
  const { GetToken } = useUser()
  const history = useHistory()
  const { pathname } = useLocation()

  const [resources, setResources] = useState([])
  const [filesSelected, setFilesSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [uploadFileModal, setUploadFileModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [removeMediaFile, setRemoveMediaFile] = useState(null)
  const [dropdownMenuValues, setDropdownMenuValues] = useState(null)
  const [fileId, setFileId] = useState(null)
  const [urlFileModal, setUrlFileModal] = useState(false)
  const [editFolderModal, setEditFolderModal] = useState(false)
  const [linkedFolders, setLinkedFolders] = useState([])

  const successMessage = useSelector(SELECTORS.SUCCESS_MESSAGE)
  const resourcesLoading = useSelector(SELECTORS.LOADING_RESOURCES)
  const resourcesData = useSelector(SELECTORS.RESOURCES)
  const urlMediaFileData = useSelector(SELECTORS.URL_MEDIA_FILE)
  const messageError = useSelector(SELECTORS.ERROR_MESSAGE)

  const handleChangeFileCard = (e, valueId, checked) => {
    e.stopPropagation()
    if (checked) setFilesSelected([...filesSelected, valueId])
    else setFilesSelected(prevState => prevState.filter(f => f !== valueId))
  }

  const handleCloseModal = () => {
    setOpen(false)
    setUploadFileModal(null)
    setRemoveMediaFile(null)
    setUrlFileModal(null)
    setEditFolderModal(null)
  }

  const handleSuccess = async message => {
    getNotification({
      title: TOAST_PROPERTIES.SUCCESS.title,
      message,
      icon: TOAST_PROPERTIES.SUCCESS.icon,
      color: TOAST_PROPERTIES.SUCCESS.color,
      position: TOAST_POSITION.rightTop,
      open: true
    })
    const token = await GetToken()
    dispatch(MEDIA_FILE_ACTIONS.GET_RESOURCES({ token, rootFolderId }))
  }

  const handleUploadFile = () => {
    setModalTitle(MODAL_TITLE.UPLOAD_FILE.toUpperCase())
    setUploadFileModal({
      close: handleCloseModal,
      successResult: handleSuccess,
      rootFolderId,
      linkedFolders
    })
    setOpen(true)
  }

  const handleCreateFolder = () => {
    setModalTitle(MODAL_TITLE.CREATE_FOLDER.toUpperCase())
    setEditFolderModal({
      close: handleCloseModal,
      successResult: handleSuccess,
      rootFolderId,
      edit: false
    })
    setOpen(true)
  }

  useImperativeHandle(ref, () => ({ handleUploadFile, handleCreateFolder }))
  // Tag functions
  const handleDropdownMenuClose = ({ clientX, clientY, widthSize }) => {
    setDropdownMenuValues({
      clientX,
      clientY,
      widthSize,
      visible: false
    })

    debounce(setDropdownMenuValues(null), 1000)
  }

  const handleDropdownMenuFileCard = (e, type, name, folderId) => {
    e.stopPropagation()
    const { clientX, clientY, target: { id } } = e
    setFileId(id)
    setDropdownMenuValues({
      clientX,
      clientY,
      id,
      visible: true,
      widthSize: 160,
      close: handleDropdownMenuClose,
      type,
      name,
      folderId
    })
  }

  const handleClickCard = async (itemId, itemType) => {
    if (itemType === RESOURCE_TYPE.FOLDER.name) {
      const token = await GetToken()
      folderClick(itemId)
      dispatch(MEDIA_FILE_ACTIONS.GET_RESOURCES({ token, folderId: itemId }))
    } else {
      history.push(
        {
          pathname: `${pathname}${SITE.FILE_PAGE}/${itemId}`,
          state: { breadcrumbs, fileId: itemId, rootFolderId, linkedFolders }
        }
      )
    }
  }

  const deleteMediaFile = mediaFileId => {
    dispatch(MEDIA_FILE_ACTIONS.REMOVE_MEDIA_FILE(mediaFileId))
  }

  const handleRemoveMediaFile = () => {
    setRemoveMediaFile({
      close: handleCloseModal,
      text: `${MESSAGES.REMOVE_MEDIA_FILE} Do you wish to continue?`,
      itemId: fileId,
      removeItem: deleteMediaFile
    })
    setOpen(true)
  }

  const handleEditMediaFile = () => {
    const selectedMediaFile = resources.find(mf => mf.id === fileId)
    setModalTitle(MODAL_TITLE.EDIT_FILE.toUpperCase())
    setUploadFileModal({
      close: handleCloseModal,
      successResult: handleSuccess,
      rootFolderId,
      edit: true,
      oldData: selectedMediaFile
    })
    setOpen(true)
  }

  const handleGetUrlMediaFile = async () => {
    const token = await GetToken()
    dispatch(MEDIA_FILE_ACTIONS.GET_URL_MEDIA_FILE({ token, imageId: fileId }))
    handleDropdownMenuClose({
      clientX: dropdownMenuValues.clientX,
      clientY: dropdownMenuValues.clientY,
      widthSize: dropdownMenuValues.widthSize
    })
  }

  const MENU_HANDLERS_FILES = {
    [MENU_ITEMS_FUNCTION.DELETE]: handleRemoveMediaFile,
    [MENU_ITEMS_FUNCTION.EDIT]: handleEditMediaFile,
    [MENU_ITEMS_FUNCTION.URLS]: handleGetUrlMediaFile
  }

  const handleEditFolder = () => {
    setModalTitle(MODAL_TITLE.EDIT_FOLDER.toUpperCase())
    setEditFolderModal({
      close: handleCloseModal,
      oldData: { id: dropdownMenuValues?.folderId, name: dropdownMenuValues?.name },
      successResult: handleSuccess,
      edit: true
    })
    setOpen(true)
  }

  const MENU_HANDLERS_FOLDER = {
    [MENU_ITEMS_FUNCTION.EDIT]: handleEditFolder
  }

  useEffect(async () => {
    const token = await GetToken()
    dispatch(MEDIA_FILE_ACTIONS.GET_RESOURCES({ token, folderId: rootFolderId }))
  }, [])

  useEffect(() => {
    if (resourcesData) {
      setResources(resourcesData)
      setLinkedFolders(resourcesData.filter(lf => lf.type === RESOURCE_TYPE.FOLDER.name))
    }
  }, [resourcesData])

  useEffect(() => {
    if (urlMediaFileData) {
      const url = Object.values(urlMediaFileData)[0]
      setModalTitle(MODAL_TITLE.URL_FILE.toUpperCase())
      setUrlFileModal({ url })
      setOpen(true)
    }
  }, [urlMediaFileData])

  useEffect(async () => {
    if (successMessage) {
      const { status, message } = successMessage
      getNotification({
        title: TOAST_PROPERTIES.SUCCESS.title,
        message: message || status,
        icon: TOAST_PROPERTIES.SUCCESS.icon,
        color: TOAST_PROPERTIES.SUCCESS.color,
        position: TOAST_POSITION.rightTop,
        open: true
      })
      const token = await GetToken()
      // after edit, create folder or file
      if (status === 200 || status === 201 || status === 204) {
        dispatch(MEDIA_FILE_ACTIONS.GET_RESOURCES({ token, folderId: rootFolderId }))
      }
      setTimeout(() => {
        dispatch(MEDIA_FILE_ACTIONS.CLEAR_SUCCESS_MESSAGE())
      }, 2000)
    }
  }, [successMessage])

  useEffect(async () => {
    if (messageError) {
      const { status, message } = messageError
      getNotification({
        title: TOAST_PROPERTIES.ERROR.title,
        message: message || status,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color,
        position: TOAST_POSITION.rightTop,
        open: true
      })
      const token = await GetToken()
      dispatch(MEDIA_FILE_ACTIONS.GET_RESOURCES({ token, folderId: rootFolderId }))
      setTimeout(() => {
        dispatch(MEDIA_FILE_ACTIONS.CLEAR_ERROR_MESSAGE())
      }, 2000)
    }
  }, [messageError])

  return (
    <>
      <FileManagerContainer
        name='FileManagerContainerd'
        rowStart={rowGrid}
        rowEnd={rowGrid}
        columnStart={columnStart}
        columnEnd={columnEnd}
      >
        <FilesCardContainer device={device}>
          {resourcesLoading
            ? (
              <WrapperLoading>
                <Loading color='primary' size='xsmall' weight='bold' />
              </WrapperLoading>
            )
            : (
              <>
                {resources.length > 0
                  ? resources?.map(f => (
                    <FileCard
                      id={f.id}
                      name={f.name}
                      size={f.size}
                      fileType={f.type}
                      items={f.items}
                      dateCreated={f.createdAt}
                      onChange={e => handleChangeFileCard(e, 1, true)}
                      device={device}
                      moreIconClick={e => handleDropdownMenuFileCard(e, f.type, f.name, f.id)}
                      onClick={handleClickCard}
                      extension={f?.extension}
                    />
                  ))
                  : (
                    <TextContainer columnStart={6} columnEnd={13}>
                      <Text text={TEXT_CONTENT.FILES_NOT_FOUND} size='large' />
                    </TextContainer>
                  )}
              </>
            )}
        </FilesCardContainer>
      </FileManagerContainer>
      <Suspense fallback={<Loading color='primary' size='xsmall' weight='bold' />}>
        <Modal
          isShowing={open}
          close={handleCloseModal}
          title={modalTitle}
          minHeight='380px'
          padding='16px'
        >
          {uploadFileModal && (<UploadFileModal {...uploadFileModal} />)}
          {removeMediaFile && (<DeleteConfirmationModal {...removeMediaFile} />)}
          {urlFileModal && (<UrlModal {...urlFileModal} />)}
          {editFolderModal && (<CreateEditFolderModal {...editFolderModal} />)}
        </Modal>
      </Suspense>
      {dropdownMenuValues
        && (
        <DropdownMenuFloat {...dropdownMenuValues}>
          {dropdownMenuValues.type !== RESOURCE_TYPE.FOLDER.name
          && MENU_ITEMS.map(i => (
            <DropdownItem
              id={i.id}
              iconName={i.icon}
              label={i.label}
              onClick={MENU_HANDLERS_FILES[i.label]}
            />
          ))}
          {dropdownMenuValues.type === RESOURCE_TYPE.FOLDER.name
          && MENU_ITEMS_FOLDER.map(i => (
            <DropdownItem
              id={i.id}
              iconName={i.icon}
              label={i.label}
              onClick={MENU_HANDLERS_FOLDER[i.label]}
            />
          ))}
        </DropdownMenuFloat>
        )}
    </>
  )
})

FileManager.propTypes = {
  rowGrid: PropTypes.number,
  columnStart: PropTypes.number,
  columnEnd: PropTypes.number,
  folderClick: PropTypes.func,
  rootFolderId: PropTypes.string,
  breadcrumbs: PropTypes.object
}

export default FileManager

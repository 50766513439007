import { call, put, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'
import { TOKEN_HELPERS } from '@utils/helpers'

import { PROFILE } from './types'
import { PROFILE_ACTIONS } from './actions'
import { M_PROFILE, M_PROFILE_UPDATED } from '../mock'

const apiCalls = {
  getProfile: () => M_PROFILE,
  // getImageProfile: () => imageProfile,
  editProfile: () => M_PROFILE_UPDATED,
  //   getProfile: ({ userId, token }) => (
  //     Axios(`Profile/${userId}`,
  //       {
  //         headers: {
  //           Authorization: TOKEN_HELPERS.SET_BEARER(token)
  //         }
  //       }))

  getImageProfile: ({ fileId, token }) => (
    Axios(`File/${fileId}/download`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),

  //   editProfile: ({ userId, token }) => (
  //     Axios(`Profile/${userId}/edit`,
  //       {
  //         headers: {
  //           Authorization: TOKEN_HELPERS.SET_BEARER(token)
  //         }
  //       }))

  editImageProfile: ({ imageToSend, token }) => (Axios.post('File/upload',
    imageToSend,
    {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }))
}

export function* getProfile ({ payload }) {
  try {
    const { token } = payload
    let { data: profile } = yield call(apiCalls.getProfile, payload)
    const fileId = '437'
    const { data: profileImage } = yield call(apiCalls.getImageProfile, { fileId, token })

    profile = {
      ...profile,
      profileImage
    }

    yield put(PROFILE_ACTIONS.GET_PROFILE_SUCCESS(profile))
  } catch (error) {
    yield put(PROFILE_ACTIONS.GET_PROFILE_ERROR(error))
  }
}

export function* editProfile ({ payload }) {
  try {
    const { status, data } = yield call(apiCalls.editProfile, payload)

    yield put(PROFILE_ACTIONS.PROFILE_UPDATED(data))
    yield put(PROFILE_ACTIONS.MESSAGE_INFORMATION({ status }))
  } catch (error) {
    yield put(PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* editImageProfile ({ payload }) {
  try {
    const { status, data } = yield call(apiCalls.editImageProfile, payload)

    yield put(PROFILE_ACTIONS.MESSAGE_INFORMATION({ status, data }))
  } catch (error) {
    yield put(PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export default function* sagas () {
  yield takeLatest(PROFILE.GET_PROFILE, getProfile)
  yield takeLatest(PROFILE.EDIT_PROFILE, editProfile)
  yield takeLatest(PROFILE.EDIT_IMAGE_PROFILE, editImageProfile)
}

import React from 'react'
import PropTypes from 'prop-types'

import { Divider } from '@global/styles'

import { WraperMenuForm } from '../styles'
import BodyMenuForm from '../bodyMenuForm'
import FooterMenuForm from '../footerMenuForm'
import HeaderMenuForm from '../headerMenuForm'

const MenuForm = ({ userName, userEmail, userId, logout, close }) => (
  <WraperMenuForm>
    <HeaderMenuForm userName={userName} userEmail={userEmail} />
    <Divider />
    <BodyMenuForm userId={userId} close={close} />
    <Divider />
    <FooterMenuForm logout={logout} close={close} />
  </WraperMenuForm>
)

MenuForm.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userId: PropTypes.string,
  logout: PropTypes.func,
  close: PropTypes.func
}

export default MenuForm

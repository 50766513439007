import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectProfile, state => get(state, 'loading', false)
)

const selectProfileSuccess = createSelector(
  selectProfile, state => get(state, 'profile', null)
)

const selectProfileError = createSelector(
  selectProfile, state => get(state, 'error', null)
)

const selectLoadingProfile = createSelector(
  selectProfile, state => get(state, 'loadingProfile', null)
)

const selectMessageInformation = createSelector(
  selectProfile, state => get(state, 'messageInformation', null)
)

export default {
  LOADING: selectLoading,
  PROFILE_SUCCESS: selectProfileSuccess,
  PROFILE_ERROR: selectProfileError,
  LOADING_PROFILE: selectLoadingProfile,
  MESSAGE_INFORMATION: selectMessageInformation
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { spacing } from '@global/theme'
import { Box, ButtonModalContainer, ModalDataContainer } from '@global/styles'
import {
  BREAKPOINTS,
  FUNCTION_TYPE,
  SUBTITLES,
  TEXT_CONTENT,
  TOAST_POSITION,
  TOAST_PROPERTIES
} from '@global/constants'
import { changeNameForLabel } from '@utils/helpers'
import { MESSAGES } from '@global/message'

import { SolidButton } from '@baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'
import SelectInput from '@components/select'
import { Text } from '@baseComponents/texts'
import Icon from '@baseComponents/icon'
import { useNotification } from 'src/context/notification.context'

import useRedux from '../../redux'
import { WORKSPACE_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddOperationModal = ({ profileId, successResult, close, oldProfiles, accordionOpen }) => {
  useRedux()
  const { device } = useWindowDimensions()
  const { GetToken } = useUser()
  const dispatch = useDispatch()
  const { getNotification } = useNotification()

  const [operationList, setOperationList] = useState([])
  const [selectedOperation, setSelectedOperation] = useState(null)
  const [errorText, setErrorText] = useState(null)

  const messageSuccess = useSelector(SELECTORS.SUCCESS_MESSAGE)
  const errorMesasage = useSelector(SELECTORS.ERROR_MESSAGE)
  const allOperationsData = useSelector(SELECTORS.ALL_OPERATIONS)
  const loadingOperations = useSelector(SELECTORS.LOADING_ALL_OPERATIONS)

  const handleSelectChange = value => {
    if (value.length === 0) {
      setSelectedOperation(value)
    } else {
      const selected = value[0]
      const currentOperations = oldProfiles.find(p => p.id === profileId).operations
      const exist = currentOperations.find(op => op.id === selected.id)
      if (exist) {
        setErrorText(MESSAGES.OPERATION_EXISTS)
        setTimeout(() => {
          setErrorText(null)
        }, 2000)
      } else setSelectedOperation(selected)
    }
  }

  const handleClick = async () => {
    const data = {
      operationId: selectedOperation.id
    }

    const token = await GetToken()
    dispatch(WORKSPACE_PROFILE_ACTIONS.ADD_PROFILE_OPERATION({
      token,
      profileId,
      data,
      oldProfiles,
      accordionOpen
    }))
  }

  useEffect(async () => {
    const token = await GetToken()
    dispatch(WORKSPACE_PROFILE_ACTIONS.GET_ALL_OPERATIONS(token))
  }, [])

  useEffect(() => {
    if (allOperationsData) {
      setOperationList(changeNameForLabel(allOperationsData))
    }
  }, [allOperationsData])

  useEffect(() => {
    if (messageSuccess) {
      successResult()
      close()
    }
  }, [messageSuccess])

  useEffect(() => {
    if (errorMesasage) {
      getNotification({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMesasage?.message || errorMesasage?.status,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color,
        position: TOAST_POSITION.leftTop,
        open: true
      })
    }
  }, [errorMesasage])

  return (
    <ModalDataContainer flexDirection='column'>
      <SelectInput
        label={SUBTITLES.OPERATIONS}
        onChange={handleSelectChange}
        options={operationList}
        value={selectedOperation}
        size='large'
        color='primary'
        isMulti
        loading={loadingOperations}
        loadingMessage={() => <Icon name='sync' color='primary' size='xxlarge' weight='bold' spin />}
        noOptionsMessage={() => <Text weight='bold' align='center'>{TEXT_CONTENT.NO_OPTIONS}</Text>}
      />
      {errorText
        && (
          <>
            <Box heightBox={spacing.two} />
            <Text text={errorText} size='medium' color='error' weight='semibold' />
          </>
        )}
      <ButtonModalContainer oneButton marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'large' : 'medium'}
          color='primary'
          text={FUNCTION_TYPE.SAVE.label}
          icon='upload'
          onClick={handleClick}
          block={device === BREAKPOINTS.MOBILE}
          disabled={!selectedOperation}
        />
      </ButtonModalContainer>
    </ModalDataContainer>
  )
}

AddOperationModal.propTypes = {
  profileId: PropTypes.string,
  successResult: PropTypes.func,
  close: PropTypes.func,
  oldProfiles: PropTypes.array,
  accordionOpen: PropTypes.bool
}

export default AddOperationModal

import React from 'react'
import PropTypes from 'prop-types'

import { Avatar } from '@global/styles'
import { Text } from '@baseComponents/texts'

import { Tooltip } from '@routes/components/nav/styles'
import { AvatarsWrapper, Wrapper } from './styles'

const AvatarsGroup = ({ users, maxUsersToShow = 2 }) => {
  let usersFiltered = []
  let equal = false
  if (users.length <= maxUsersToShow || maxUsersToShow <= 0) {
    usersFiltered = users
    equal = true
  } else {
    const restUsers = users.slice(maxUsersToShow)
    const initialName = ''
    const lastItem = {
      id: restUsers[0]?.id,
      name: restUsers.reduce((acc, currentValue) => (`${acc} ${currentValue.name}`), initialName)
    }
    usersFiltered = [
      ...users.slice(0, maxUsersToShow),
      lastItem
    ]
  }

  return (
    usersFiltered.map((u, idx) => (
      <Wrapper>
        <AvatarsWrapper key={u.id}>
          {idx < usersFiltered.length - 1 || equal
            ? (
              <Avatar
                src={u.img}
                alt={u.name}
                width='100%'
                height='100%'
                borderRadius='50%'
              />
            )
            : (
              <Text text={`+${users.slice(maxUsersToShow).length}`} size='medium' weight='bold' />
            )}
        </AvatarsWrapper>
        <Tooltip>{u.name}</Tooltip>
      </Wrapper>
    )))
}

AvatarsGroup.propTypes = {
  users: PropTypes.arrayOf({
    id: PropTypes.number,
    name: PropTypes.name,
    imageUrl: PropTypes.avatar
  }),
  maxUsersToShow: PropTypes.number
}

export default AvatarsGroup

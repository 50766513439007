import styled from 'styled-components'

import { spacing } from '@global/theme'

import { Label, Message } from '@components/inputs/inputs/styles'

export const Wrapper = styled.div`
  margin-right: auto;
`

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'checkbox';

  > * {
    grid-area: checkbox;
  }
`

export const Check = styled.div`
  position: relative;

  width: ${spacing.twoAndAQuarter};
  height: ${spacing.twoAndAQuarter};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${spacing.half};
  border: 1px solid ${({ theme, checked, color, disabled }) => {
    if (disabled) return theme.colors[color]?.disabled
    if (checked) return theme.colors[color]?.main
  }
};
  background-color:${({ theme, checked, color, disabled }) => {
    if (disabled) return theme.colors.grays.light
    if (checked) return theme.colors[color]?.main || theme.colors.primary.main
  }};
  
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:active{
    outline: ${spacing.quarter} solid ${({ theme, color, disabled }) => {
  if (disabled) return theme.colors.background.main
  return theme.colors[color]?.main
}
};
    outline-offset: ${spacing.quarter};
  }

  transition: background-color .2s;
`

export const StyledCheckbox = styled.input`
  width: ${spacing.two};
  height: ${spacing.two};
  margin: 0;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const MyLabel = styled(Label)`
  display: flex;
  align-items: center;

  cursor: pointer;

  gap: ${spacing.oneAndAHalf};
  
  margin: 0;
  
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  > p {
    color: ${({ theme, error }) => (error ? theme.colors.error.main : theme.colors.text.content)};
  }
`

export const MyMessage = styled(Message)`
  margin-left: ${spacing.threeAndAHalf};
  margin-top: ${spacing.one};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

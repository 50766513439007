import styled from 'styled-components'
import { spacing } from '@global/theme'
import { SourceCodePro } from '@global/fonts'
import { PADDING_HORIZONTAL, PADDING_HORIZONTAL_DESKTOP, PADDING_HORIZONTAL_BIG_SCREEN } from '@routes/constants'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const TableStyled = styled.table`
  ${SourceCodePro};
  width: ${({ width, device }) => {
    if (!width) return '100%'
    let padding = PADDING_HORIZONTAL
    if (device === BREAKPOINTS.DESKTOP) padding = PADDING_HORIZONTAL_DESKTOP
    if (device === BREAKPOINTS.BIG_SCREEN) padding = PADDING_HORIZONTAL_BIG_SCREEN
    const size = width - padding.slice(0, padding.length - 2)
    return `${size}px`
  }};
  max-width: 100%;
  height: auto;
  padding:  0 0 ${spacing.oneAndAHalf} 0;
  background-color: ${({ theme }) => theme.colors.background.main};

  display: block;
  overflow-x: auto; 
  border-spacing: 0;
  table-layout: fixed;
`

export const Head = styled.thead`
 width: fit-content;
 position: sticky;
 top: 0;
 height: ${spacing.fiveAndAHalf};
`
export const Body = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`

  height: ${spacing.four};
  background-color: ${({ index, theme }) => {
    if (index % 2 === 0) {
      if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.ultralight
      return theme.colors.background.variant
    }
    return theme.colors.background.content
  }};
  cursor: ${({ type, boolOnClick }) => (type === 'header' || !boolOnClick ? 'default' : 'pointer')};
  
  & > :last-child {
    padding-right: ${spacing.three};
  }

  &:hover {
    background-color: ${({ type, theme }) => type !== 'header' && theme.colors.background.variant};
  }

`

export const Th = styled.th`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main}; 
  width: ${({ widthTh, tableWidth }) => {
    const porcentage = Number(widthTh.slice(0, widthTh.length - 1))
    return widthTh ? `${(porcentage * tableWidth) / 100}px` : 'inherit'
  }};
  text-align: left;
  padding: ${spacing.one} 0 ${spacing.one} ${spacing.three};
`

export const Td = styled.td` 
  text-align: left;
  padding: ${spacing.one} 0 ${spacing.one} ${spacing.three};
  > p {
    color: ${({ theme, value }) => {
    if (value === 'deudor') return theme.colors.error.main
    return theme.colors.text.colors
  }}
  }
`

export const ColumnTitle = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: uppercase;
  > p, span {
    color: ${({ theme, selectedColumn, color }) => {
    if (selectedColumn) return theme.colors[color]?.main || theme.colors.primary.main
    return theme.colors.text.content
  }}!important;
  }
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TextContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TableChildrenContainer = styled.div`
  width: 100%;
  height: 60px;
  margin-top: ${spacing.four};
  padding: 0 ${spacing.three};
  // overflow-y: auto; 
`

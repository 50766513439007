import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { BREAKPOINTS, TEXT_CONTENT, TITLES } from '@global/constants'
import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'

import { useWindowDimensions } from '@components/windowDimensions'
import { Heading } from 'src/baseComponents/texts'
import Loading from '@components/loading'

import {
  Wrapper,
  Header,
  Body,
  LoadingContainer
} from '../../styles'
import Form from './form'

import { USER_ACTIONS } from '../../redux/action'
import useRedux from '../../redux'

const Login = () => {
  useRedux()
  const { device } = useWindowDimensions()
  const dispatch = useDispatch()
  const history = useHistory()
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0()

  const handleKeyDown = e => {
    if (e.keyCode === 10 || e.keyCode === 13) loginWithRedirect()
  }

  const getToken = async () => {
    const auth0Token = await getAccessTokenSilently({
      detailedResponse: true
    })

    if (auth0Token) {
      const userRedux = {
        username: user.name,
        image: user.picture
      }
      localStorage.setItem('user', JSON.stringify(userRedux))
      dispatch(USER_ACTIONS.SUCCESS(userRedux))
      dispatch(USER_ACTIONS.SEND_DATA_USER({
        token: auth0Token.access_token,
        data: { email: user.email, username: user.name }
      }))
      history.push(SITE.DASHBOARD)
    }
  }

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: SITE.SIGN_IN
      },
      authorizationParams: {
        screen_hint: 'signup'
      }
    })
  }

  useEffect(async () => {
    if (isAuthenticated) {
      await getToken()
    }
  }, [isAuthenticated])

  return (
    <>
      {isLoading ? (
        <LoadingContainer>
          <Loading color='primary' text={TITLES.DASHBOARD} size='xxlarge' weight='bold' />
        </LoadingContainer>
      )
        : (
          <Wrapper
            device={device}
            mobileHeight='540px'
            desktopHeight='460px'
            mobileWidth='310px'
            desktopWidth='430px'
          >
            <Header device={device}>
              <Heading
                size={device === BREAKPOINTS.MOBILE ? 'medium' : 'xlarge'}
                type='h2'
                marginBottom={device === BREAKPOINTS.MOBILE ? 'half' : 'one'}
                weight='bold'
                color='primary'
                align='center'
              >
                {TEXT_CONTENT.LOGIN}
              </Heading>
            </Header>
            <Body device={device} paddingTopBot={spacing.one} paddingLefRig={spacing.one}>
              <Form
                onSubmit={loginWithRedirect}
                device={device}
                onKeyDown={handleKeyDown}
                signUp={handleSignUp}
              />
            </Body>
          </Wrapper>
        )}
    </>
  )
}

export default Login

import styled, { css } from 'styled-components'

export const ToggleContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${({ showText }) => (showText ? '80px' : 'auto')};

  & > p {
    margin-right: 8px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.text.white};
  }
`
export const CustomLabel = styled('label')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const SunIcon = styled('span')`
  font-size: 1.5rem;
  color: ${({ theme, color }) => (color ? theme.colors[color]?.main : theme.colors.primary.main)};
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, .5));
  transition: 1s ease;
  transition-delay: 0.5s;
`

export const MoonIcon = styled('span')`
  position: absolute;
  font-size: 1.5rem;
  color: ${({ theme, color }) => (color ? theme.colors[color]?.main : theme.colors.primary.main)};
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, .5));
  transition: 1s ease;
  transform: scale(0);
`

const boxShadow = css`
  inset 0 8px 60px rgba(0, 0, 0, 0.1), inset 0 8px 8px rgba(0, 0, 0, 0.1),
  inset 0 -4px 4px rgba(0, 0, 0, 0.1)
`

export const ToggleSpan = styled('span')`
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background: ${({ checked }) => (checked ? '#363535' : '#f8f8f8')};
  border-radius: 50%;
  box-shadow: ${boxShadow};
  z-index: -1;
  transition: 1s;
`

export const CustomCheckbox = styled('input')`
  position: absolute;
  opacity: 0;

  &:checked~${SunIcon} {
    transform: rotate(360deg) scale(0);
    transition-delay: 0s;
  }
  &:checked~${MoonIcon} {
    transition-delay: 0.5s;
    transform: scale(1) rotate(360deg);
  }
`

import styled, { keyframes, css } from 'styled-components'

import { spacing } from '@global/theme'
import { Column, Row } from '@global/styles'

export const toastInRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`

export const toastInLeft = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`

export const leftTop = css`
  top: ${spacing.two};
  left: ${spacing.five};
  animation: ${toastInLeft} .7s;
`

export const rightTop = css`
  top: ${spacing.two};
  right: ${spacing.five};
  animation: ${toastInRight} 0.7s;
`

export const leftBottom = css`
  bottom: ${spacing.two};
  left: ${spacing.five};
  animation: ${toastInLeft} 0.7s;
`

export const rightBottom = css`
  bottom: ${spacing.two};
  right: ${spacing.five};
  animation: ${toastInRight} 0.7s;
`

export const Area = styled(Column)`
  position: fixed;

  width: 350px;
  height: ${spacing.nine};
  min-height: ${spacing.nine};

  ${({ position }) => {
    switch (position) {
      case 'left-top':
        return leftTop
      case 'left-bottom':
        return leftBottom
      case 'right-bottom':
        return rightBottom
      default:
        return rightTop
    }
  }}

  z-index: 20;
  border: 3px solid ${({ theme, color }) => theme.colors[color]?.main || theme.colors.text.content};
  border-radius: 12px;
  box-shadow: ${spacing.half} ${spacing.half} ${spacing.half} rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  backdrop-filter: blur( 5px );
  -webkit-backdrop-filter: blur( 20px );

  > span {
    margin-left: ${spacing.oneAndAHalf};
  }

  > :last-child {
      margin: ${spacing.oneAndAHalf};

      color: ${({ theme }) => theme.colors.text.content}!important; 
      &:hover {
        color: ${({ theme, color }) => theme.colors[color].main}!important; 
      }
  }
`

export const Rectangle = styled.div`
 width: ${spacing.one};
 height: ${spacing.seven};

 margin-left: ${({ marginLeft }) => (marginLeft || '8px')};

 border-radius: 5px;
 background-color: ${({ theme, color }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
`

export const TextContainer = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  margin: ${spacing.two} ${spacing.half};
  margin-left: ${({ marginLeft }) => (marginLeft || '8px')};
  text-overflow: ellipsis;
  overflow: hidden;
`

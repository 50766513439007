import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { spacing } from '@global/theme'
import { ButtonModalContainer, ModalDataContainer } from '@global/styles'
import {
  BREAKPOINTS,
  COLUMN_TYPE,
  FLEX_PROPERTIES,
  FUNCTION_TYPE,
  INITIAL_STATES,
  RADIO_BUTTON_STATUS_OPTIONS,
  SUBTITLES
} from '@global/constants'

import { SolidButton } from '@baseComponents/button'
import { TextInput } from '@components/inputs/inputs'
import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'

import useRedux from '@containers/fileManager/redux'
import { MEDIA_FILE_ACTIONS } from '@containers/fileManager/redux/actions'
import SELECTORS from '@containers/fileManager/redux/selectors'
import RadioInputs from '@components/inputs/inputs/components/radio'

const CreateEditFolderModal = ({ oldData, close, successResult, edit, rootFolderId }) => {
  useRedux()
  const { device } = useWindowDimensions()
  const { GetToken } = useUser()
  const dispatch = useDispatch()

  const [folderName, setFolderName] = useState(
    edit
      ? { ...INITIAL_STATES.INPUT, value: oldData?.name }
      : INITIAL_STATES.INPUT
  )
  const [description, setDescription] = useState(
    edit
      ? { ...INITIAL_STATES.INPUT, value: oldData?.description }
      : INITIAL_STATES.INPUT
  )
  const [radioButtonSelected, setRadioButtonSelected] = useState(edit ? oldData?.status : null)

  const successMessage = useSelector(SELECTORS.SUCCESS_MESSAGE)

  const handleInputChange = val => {
    const { name } = val
    if (name === SUBTITLES.NAME) setFolderName(val)
    else setDescription(val)
  }

  const handleChangeRadioButtons = value => setRadioButtonSelected(value)

  const handleClick = async () => {
    let data = {
      name: folderName.value,
      description: description.value,
      status: radioButtonSelected
    }
    const token = await GetToken()
    if (edit) dispatch(MEDIA_FILE_ACTIONS.EDIT_FOLDER({ token, folderId: oldData.id, data }))
    else {
      data = { ...data, parent: rootFolderId }
      dispatch(MEDIA_FILE_ACTIONS.CREATE_FOLDER({ token, data }))
    }
  }

  useEffect(async () => {
    if (successMessage) {
      const { message, status } = successMessage
      successResult(message || status)
      close()
    }
  }, [successMessage])

  return (
    <ModalDataContainer flexDirection='column'>
      <RadioInputs
        type={device === BREAKPOINTS.MOBILE ? COLUMN_TYPE.VERTICAL : COLUMN_TYPE.HORIZONTAL}
        name='statusFolder'
        options={RADIO_BUTTON_STATUS_OPTIONS}
        onChange={handleChangeRadioButtons}
        alignItems={device === BREAKPOINTS.MOBILE ? FLEX_PROPERTIES.ALIGN_ITEMS.FLEX_START : 'none'}
      />
      <TextInput
        name={SUBTITLES.NAME}
        label={SUBTITLES.NAME}
        onChange={handleInputChange}
        color='primary'
        value={folderName.value}
      />
      <TextInput
        name={SUBTITLES.DESCRIPTION}
        label={SUBTITLES.DESCRIPTION}
        onChange={handleInputChange}
        color='primary'
        value={description.value}
      />
      <ButtonModalContainer oneButton marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'large' : 'medium'}
          color='primary'
          text={edit ? FUNCTION_TYPE.EDIT.label : FUNCTION_TYPE.SAVE.label}
          icon='upload'
          onClick={handleClick}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonModalContainer>
    </ModalDataContainer>
  )
}

CreateEditFolderModal.propTypes = {
  close: PropTypes.func,
  oldData: PropTypes.object,
  successResult: PropTypes.func,
  edit: PropTypes.bool,
  rootFolderId: PropTypes.string
}

export default CreateEditFolderModal

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { TextContainer, WrapperLoading } from '@global/styles'
import { TEXT_CONTENT } from '@global/constants'

import { Text } from '@baseComponents/texts'
import Icon from '@baseComponents/icon'
import Loading from '@components/loading'
import { useUser } from '@containers/authentication/utils/hook'

import useRedux from '../../redux'
import SELECTORS from '../../redux/selectors'
import { WORKSPACE_PROFILE_ACTIONS } from '../../redux/actions'

import { ProfileListContainer, ProfileListHeader, ProfileListDetails, HeaderTitle } from './styles'
import AccordionProfile from '../accordionProfile'

const HEADER_TITLES = {
  NAME: 'NAME',
  OPERATION: 'OPERATION',
  TYPE: 'TYPE'
}

const ProfileList = ({ profileIds, rowGrid, columnStart, columnEnd, handleWorkspaceProfile }) => {
  useRedux()
  const dispatch = useDispatch()
  const { GetToken } = useUser()

  const [profiles, setProfiles] = useState([])
  const [selectedColumn, setSelectedColumn] = useState(HEADER_TITLES.NAME)

  const workspaceProfiles = useSelector(SELECTORS.WORKSPACE_PROFILES)

  const handleSelectColumn = colName => {
    setSelectedColumn(colName)
  }

  useEffect(async () => {
    const token = await GetToken()
    const arrProfileId = profileIds.map(pi => pi.id)
    dispatch(WORKSPACE_PROFILE_ACTIONS.GET_WORKSPACE_PROFILES({ token, arrProfileId }))
  }, [])

  useEffect(() => {
    if (workspaceProfiles) setProfiles(workspaceProfiles)
  }, [workspaceProfiles])

  return (
    <>
      {profiles.length > 0
        ? (
          <ProfileListContainer rowGrid={rowGrid} columnStart={columnStart} columnEnd={columnEnd}>
            <ProfileListHeader>
              {
                Object.values(HEADER_TITLES).map(ht => (
                  <HeaderTitle key={ht} onClick={() => handleSelectColumn(ht)}>
                    <Text text={ht} size='large' weight='bold' />
                    <Icon
                      name={ht === selectedColumn ? 'expand_less' : 'expand_more'}
                      color='primary'
                      size='medium'
                      weight='bold'
                    />
                  </HeaderTitle>
                ))
              }
            </ProfileListHeader>
            <ProfileListDetails>
              {!profiles
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : profiles?.map(p => (
                  <AccordionProfile
                    key={p.id}
                    profileId={p.id}
                    title={p.name}
                    numOperation={p.operations.length}
                    handleWorkspaceProfile={handleWorkspaceProfile}
                  />
                ))}
            </ProfileListDetails>
          </ProfileListContainer>
        )

        : (
          <TextContainer columnStart={1} columnEnd={13}>
            <Text weight='bold' align='center'>{TEXT_CONTENT.PROFILES_NOT_FOUND}</Text>
          </TextContainer>
        )}
    </>
  )
}

ProfileList.propTypes = {
  profileIds: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
    operations: PropTypes.number,
    workspace: PropTypes.string
  }),
  rowGrid: PropTypes.number,
  columnStart: PropTypes.number,
  columnEnd: PropTypes.number,
  handleWorkspaceProfile: PropTypes.func
}

export default ProfileList

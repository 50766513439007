import { MEDIA_FILES } from './types'

export const getResources = payload => ({
  type: MEDIA_FILES.GET_RESOURCES,
  payload
})

export const getResourcesSuccess = payload => ({
  type: MEDIA_FILES.RESOURCES_SUCCESS,
  payload
})

export const removeMediaFile = payload => ({
  type: MEDIA_FILES.REMOVE_MEDIA_FILE,
  payload
})

export const createFolder = payload => ({
  type: MEDIA_FILES.CREATE_FOLDER,
  payload
})

export const editFolder = payload => ({
  type: MEDIA_FILES.EDIT_FOLDER,
  payload
})

export const getMediaFile = payload => ({
  type: MEDIA_FILES.GET_MEDIA_FILE,
  payload
})

export const getMediaFileSuccess = payload => ({
  type: MEDIA_FILES.GET_MEDIA_FILE_SUCCESS,
  payload
})

export const getUrlMediaFile = payload => ({
  type: MEDIA_FILES.GET_URL_MEDIA_FILE,
  payload
})

export const getUrlMediaFileSuccess = payload => ({
  type: MEDIA_FILES.GET_URL_MEDIA_FILE_SUCCESS,
  payload
})

// MESSAGES
export const successMessage = payload => ({
  type: MEDIA_FILES.SUCCESS_MESSAGE,
  payload
})

export const clearSuccessMessage = () => ({
  type: MEDIA_FILES.CLEAR_SUCCESS_MESSAGE
})

export const errorMessage = payload => ({
  type: MEDIA_FILES.ERROR_MESSAGE,
  payload
})

export const clearErrorMessage = () => ({
  type: MEDIA_FILES.CLEAR_ERROR_MESSAGE
})

export const MEDIA_FILE_ACTIONS = {
  REMOVE_MEDIA_FILE: removeMediaFile,

  GET_RESOURCES: getResources,
  RESOURCES_SUCCESS: getResourcesSuccess,
  CREATE_FOLDER: createFolder,
  EDIT_FOLDER: editFolder,
  GET_MEDIA_FILE: getMediaFile,
  GET_MEDIA_FILE_SUCCESS: getMediaFileSuccess,
  GET_URL_MEDIA_FILE: getUrlMediaFile,
  GET_URL_MEDIA_FILE_SUCCESS: getUrlMediaFileSuccess,

  SUCCESS_MESSAGE: successMessage,
  CLEAR_SUCCESS_MESSAGE: clearSuccessMessage,
  ERROR_MESSAGE: errorMessage,
  CLEAR_ERROR_MESSAGE: clearErrorMessage
}

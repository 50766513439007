import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'

import { Container, Page } from '../../styles'
import Nav from '../nav'
import Header from '../header'

const PrivateLayout = ({ children }) => {
  const { device } = useWindowDimensions()
  const { GetUser } = useUser()
  const user = GetUser()
  const navRef = useRef()

  const handleShowNav = () => {
    navRef.current?.showNavFromParent()
  }

  return (
    <Container device={device} name='container' id='container'>
      <Header showSidebar={handleShowNav} />
      <Nav user={user} ref={navRef} color='primary' />
      <Page scroll={user} id='page' device={device}>
        {children}
      </Page>
    </Container>
  )
}
PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired
}

export default PrivateLayout

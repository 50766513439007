import produce from 'immer'

import { WORKSPACE_PROFILE } from './types'

export const initialState = {
  loading: false,
  workspaceProfiles: null,
  loadingWorkspaceProfiles: true,
  profileOperations: {},
  loadingProfileOperations: false,
  messageSuccess: null,
  messageError: null,
  allOperations: null,
  loadingAllOperations: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case WORKSPACE_PROFILE.LOADING:
      draft.loading = true
      break
    case WORKSPACE_PROFILE.LOADING_FALSE:
      draft.loading = false
      break
    case WORKSPACE_PROFILE.GET_WORKSPACE_PROFILES:
      draft.loadingWorkspaceProfiles = true
      break
    case WORKSPACE_PROFILE.EDIT_WORKSPACE_PROFILE:
      draft.loadingWorkspaceProfiles = true
      break
    case WORKSPACE_PROFILE.WORKSPACE_PROFILES_SUCCESS:
      draft.loadingWorkspaceProfiles = false
      draft.loadingProfileOperations = false
      draft.workspaceProfiles = payload
      break
    case WORKSPACE_PROFILE.GET_PROFILE_OPERATION:
      draft.loadingProfileOperations = true
      break
    case WORKSPACE_PROFILE.REMOVE_PROFILE_OPERATION:
      draft.loadingProfileOperations = true
      break
    case WORKSPACE_PROFILE.PROFILE_OPERATION_SUCCESS: {
      const { profileId, operations } = payload
      draft.loadingProfileOperations = false
      draft.profileOperations = { ...draft.profileOperations, [profileId]: operations }
      break
    }
    case WORKSPACE_PROFILE.ADD_PROFILE_OPERATION:
      draft.loadingProfileOperations = true
      break
    case WORKSPACE_PROFILE.GET_ALL_OPERATIONS:
      draft.loadingAllOperations = true
      break
    case WORKSPACE_PROFILE.ALL_OPERATIONS_SUCCESS:
      draft.loadingAllOperations = false
      draft.allOperations = payload
      break
    case WORKSPACE_PROFILE.SUCCESS_MESSAGE:
      draft.loadingWorkspaceProfiles = false
      draft.loadingProfileOperations = false
      draft.messageSuccess = payload
      break
    case WORKSPACE_PROFILE.CLEAR_SUCCESS_MESSAGE:
      draft.messageSuccess = null
      break
    case WORKSPACE_PROFILE.ERROR_MESSAGE:
      draft.messageError = payload
      draft.loadingWorkspaceProfiles = false
      draft.loadingProfileOperations = false
      draft.loading = false
      break
    case WORKSPACE_PROFILE.CLEAR_ERROR_MESSAGE:
      draft.messageError = null
      break
    default:
      return initialState
  }
})

export default reducer

import React from 'react'
import PropTypes from 'prop-types'

import MyPropTypes from '@global/propTypes'

import Icon from 'src/baseComponents/icon'
import { Text } from 'src/baseComponents/texts'

import { Wrapper } from './styles'

const Loading = ({ text, color, size, weight }) => (
  <Wrapper>
    <Icon name='rotate_left' color={color} size='big' spin />
    {text && <Text text={`Loading ${text}...`} align='center' weight={weight} color={color} size={size} />}
  </Wrapper>
)

export default Loading

Loading.propTypes = {
  text: PropTypes.string,
  color: MyPropTypes.color,
  size: MyPropTypes.size,
  weight: MyPropTypes.weight
}

import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from '@global/constants'
import defaultAvatar from '@assets/male-avatar.jpg'
import { DividerVertical } from '@global/styles'

import { Text } from 'src/baseComponents/texts'
import Icon from 'src/baseComponents/icon'
import { useUser } from '@containers/authentication/utils/hook'
import { ToggleButtonTheme } from '@components/toggleButtonTheme'
import { useWindowDimensions } from '@components/windowDimensions'
import DropdownMenuFloat from '@components/dropdownMenu'
import { ThemeContext } from 'src/context/themeContext'

import { HEADER_HEIGHT, IMG_PROFILE_SIZE } from '@routes/constants'
import { Head, Img, ProfileData, WraperImgProfile } from './styles'

import MenuForm from './components/menuForm'

export const Header = ({ showSidebar }) => {
  const { device } = useWindowDimensions()
  const { GetUser, Logout } = useUser()
  const user = GetUser()
  const { theme, toggleTheme } = useContext(ThemeContext)

  const [imageProfile, setImageProfile] = useState(defaultAvatar)
  const [dropdownMenuValues, setDropdownMenuValues] = useState(false)

  const handleClick = () => {
    showSidebar(true)
  }

  const handleCloseProfileMenu = data => {
    setDropdownMenuValues({
      ...data || dropdownMenuValues,
      visible: false
    })
  }

  const handleProfileMenu = () => {
    const div = document.getElementById('wraperImgProfile')
    const clientRect = div.getBoundingClientRect()
    const cx = clientRect.right - (IMG_PROFILE_SIZE.slice(0, -2) / 2)

    setDropdownMenuValues({
      clientX: cx,
      clientY: Number(HEADER_HEIGHT.slice(0, -2)),
      visible: true,
      widthSize: 200,
      padding: '0px',
      close: handleCloseProfileMenu
    })
  }

  useEffect(() => {
    if (user) {
      setImageProfile(user.image)
    }
  }, [])

  return (
    <>
      <Head device={device}>
        {device === BREAKPOINTS.MOBILE
        && <Icon name='menu' color='text' size='xlarge' onClick={handleClick} />}
        <ProfileData>
          <Icon
            name='notifications'
            size={device === BREAKPOINTS.MOBILE ? 'xlarge' : 'large'}
            onClick={() => {}}
          />
          {device !== BREAKPOINTS.MOBILE
            && (
            <>
              <DividerVertical />
              <Text
                size='medium'
                align='center'
              >
                {user?.username}
              </Text>
            </>
            )}
          <WraperImgProfile id='wraperImgProfile' onClick={handleProfileMenu}>
            <Img
              src={imageProfile}
              alt='user'
              width={IMG_PROFILE_SIZE}
              height={IMG_PROFILE_SIZE}
              borderRadius='50%'
            />
          </WraperImgProfile>
          <ToggleButtonTheme onChange={toggleTheme} theme={theme} showText={device !== BREAKPOINTS.MOBILE} />
        </ProfileData>

      </Head>
      {dropdownMenuValues
        && (
        <DropdownMenuFloat {...dropdownMenuValues}>
          <MenuForm
            userName={user?.username}
            userEmail={user?.email}
            userId={user?.id}
            logout={Logout}
            close={handleCloseProfileMenu}
          />
        </DropdownMenuFloat>
        )}
    </>

  )
}

Header.propTypes = {
  showSidebar: PropTypes.func.isRequired
}

export default Header

import { FUNCTION_TYPE, OPERATION_STATUS } from '@global/constants'
import avatar1 from '@assets/avatars/avatar1.png'
import avatar2 from '@assets/avatars/avatar2.png'
import avatar3 from '@assets/avatars/avatar3.png'

export const TOOLTIP_ITEMS = [
  {
    id: 1,
    label: 'Eliminar',
    icon: 'delete',
    function: FUNCTION_TYPE.DELETE.name
  },
  {
    id: 2,
    label: 'Editar',
    icon: 'edit',
    function: FUNCTION_TYPE.EDIT.name
  }
]

export const USERS_WORKSPACE = [
  { id: 1, name: 'User 1', role: 'Admin', img: avatar1 },
  { id: 2, name: 'User 2', role: 'Admin', img: avatar2 },
  { id: 3, name: 'User 3', role: 'Admin', img: avatar3 },
  { id: 4, name: 'User 4', role: 'Admin', img: avatar2 },
  { id: 5, name: 'User 5', role: 'Admin', img: avatar3 }
]

export const VIEWERS_WORKSPACE = [
  { id: 1, name: 'User 1', role: 'Admin', img: avatar1 },
  { id: 2, name: 'User 2', role: 'Admin', img: avatar2 },
  { id: 3, name: 'User 3', role: 'Admin', img: avatar3 },
  { id: 4, name: 'User 4', role: 'Admin', img: avatar2 },
  { id: 5, name: 'User 5', role: 'Admin', img: avatar3 }
]

export const EDITORS_WORKSPACE = [
  { id: 1, name: 'User 1', role: 'Admin', img: avatar1 },
  { id: 3, name: 'User 3', role: 'Admin', img: avatar3 },
  { id: 5, name: 'User 5', role: 'Admin', img: avatar3 }
]

export const OPERATIONS_MOCK_1 = [
  {
    id: '35128abf-0511-4df4-baf6-d2b3a436a8c9',
    name: 'Squad',
    description: 'Description squad',
    command: '50x50.jpeg',
    status: OPERATION_STATUS.ACTIVE
  },
  {
    id: '3c4de0ad-003e-4628-864f-c6e1861f8ce0',
    name: 'Landscape',
    description: 'Landscape  nulla deserunt excepteur culpa proident non exercitation.',
    command: '150x750.jpeg',
    status: OPERATION_STATUS.INACTIVE
  }
]

export const OPERATIONS_MOCK_2 = [
  {
    id: '051ed8a5-c3cd-469a-876f-1f4a8d663815',
    name: 'Phone',
    description: 'Description phone',
    command: '75x150.jpeg',
    status: OPERATION_STATUS.ACTIVE
  },
  {
    id: '4b724d52-6d05-4e4d-94d2-3cf866180bcd',
    name: 'operation 4',
    description: 'Description operation 4',
    command: '50x50.png',
    status: OPERATION_STATUS.ACTIVE
  }
]

export const PROFILE_MOCK_1 = {
  id: '94be6376-75b8-4607-ad9b-c61de86a4a31',
  name: 'Profile 1',
  operations: 2,
  workspace: '4463a512-e467-4cc8-bd80-466e603dac59'
}

export const PROFILE_MOCK_2 = {
  id: 'b08c9231-a28f-498b-980d-ce236c502c46',
  name: 'Profile 2',
  operations: 2,
  workspace: '4463a512-e467-4cc8-bd80-466e603dac59'
}

export const PROFILE_LIST_MOCK = [
  PROFILE_MOCK_1,
  PROFILE_MOCK_2
]

export const key = 'PROFILE'

export const PROFILE = {
  GET_PROFILE: 'PROFILE/GET_PROFILE',
  GET_PROFILE_SUCCESS: 'PROFILE/GET_PROFILE/SUCCESS',
  GET_PROFILE_ERROR: 'PROFILE/GET_PROFILE/ERROR',
  EDIT_PROFILE: 'PROFILE/EDIT_PROFILE',
  PROFILE_UPDATED: 'PROFILE/PROFILE_UPDATED',
  MESSAGE_INFORMATION: 'PROFILE/MESSAGE_INFORMATION',
  CLEAR: 'PROFILE/CLEAR',
  EDIT_IMAGE_PROFILE: 'PROFILE/EDIT_IMAGE_PROFILE'
}

export default key

export const MENU_ITEMS_FUNCTION = {
  DELETE: 'Delete',
  EDIT: 'Edit',
  CONVERT: 'Convert',
  DOWNLOAD: 'Download',
  URLS: 'Urls',
  VERSIONS: 'Versions'
}

export const MENU_ITEMS = [
  {
    id: 1,
    label: MENU_ITEMS_FUNCTION.DELETE,
    icon: 'delete'
  },
  {
    id: 2,
    label: MENU_ITEMS_FUNCTION.EDIT,
    icon: 'edit'
  },
  {
    id: 3,
    label: MENU_ITEMS_FUNCTION.CONVERT,
    icon: 'transform'
  },
  {
    id: 4,
    label: MENU_ITEMS_FUNCTION.DOWNLOAD,
    icon: 'download'
  },
  {
    id: 5,
    label: MENU_ITEMS_FUNCTION.URLS,
    icon: 'http'
  },
  {
    id: 6,
    label: MENU_ITEMS_FUNCTION.VERSIONS,
    icon: 'abc'
  }
]

export const MENU_ITEMS_FOLDER = [
  {
    id: 1,
    label: MENU_ITEMS_FUNCTION.EDIT,
    icon: 'edit'
  }
]

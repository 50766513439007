import produce from 'immer'

import { MEDIA_FILES } from './types'

export const initialState = {
  loadingResources: true,
  resources: null,
  messageSuccess: null,
  messageError: null,
  mediaFile: null,
  urlMediaFile: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case MEDIA_FILES.GET_RESOURCES:
      draft.loadingResources = true
      break
    case MEDIA_FILES.RESOURCES_SUCCESS: {
      draft.loadingResources = false
      draft.resources = payload
      break
    }
    case MEDIA_FILES.CREATE_FOLDER:
      draft.loadingResources = true
      break
    case MEDIA_FILES.GET_MEDIA_FILE_SUCCESS:
      draft.mediaFile = payload
      break
    case MEDIA_FILES.GET_URL_MEDIA_FILE:
      draft.loadingResources = true
      break
    case MEDIA_FILES.GET_URL_MEDIA_FILE_SUCCESS:
      draft.urlMediaFile = payload
      draft.loadingResources = false
      break
    // messages
    case MEDIA_FILES.SUCCESS_MESSAGE:
      draft.messageSuccess = payload
      break
    case MEDIA_FILES.CLEAR_SUCCESS_MESSAGE:
      draft.messageSuccess = null
      break
    case MEDIA_FILES.ERROR_MESSAGE:
      draft.messageError = payload
      draft.loadingResources = false
      break
    case MEDIA_FILES.CLEAR_ERROR_MESSAGE:
      draft.messageError = null
      break
    default:
      return initialState
  }
})

export default reducer

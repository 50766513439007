import { THEME_NAME } from '@global/constants'
import { spacing } from '@global/theme'
import styled from 'styled-components'
import { OPERATION_HEIGHT } from '../operationCard/styles'

export const AccordionContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  align-items: center;
`

export const AccordionSummary = styled.div`
  width: 100%;
  height: ${spacing.five};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${spacing.one};
  align-items: center;
  padding: ${spacing.one};
  cursor: pointer;
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.ultralight
    return theme.colors.background.content
  }};
  border-top: 1px solid;
  border-top-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.disabled
    return theme.colors.grays.main
  }};
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.disabled
    return theme.colors.grays.main
  }};

  & > :nth-child(1) {
    grid-column-start: ${({ columnStart }) => columnStart || 1};
    grid-column-end: ${({ columnEnd }) => columnEnd || 1};
    color: ${({ theme }) => theme.colors.text.content}};
  }

  & > :nth-child(2) {
    grid-column-start: ${({ columnStart }) => columnStart || 2};
    grid-column-end: ${({ columnEnd }) => columnEnd || 5};
  }

  & > :nth-child(3) {
    grid-column-start: ${({ columnStart }) => columnStart || 5};
    grid-column-end: ${({ columnEnd }) => columnEnd || 9};
  }

  & > :nth-child(4) {
    grid-column-start: ${({ columnStart }) => columnStart || 9};
    grid-column-end: ${({ columnEnd }) => columnEnd || 13};
    text-align: end;
  }

  & > :nth-child(5){
    grid-column-start: ${({ columnStart }) => columnStart || 14};
    grid-column-end: ${({ columnEnd }) => columnEnd || 14};
    color: ${({ theme }) => theme.colors.text.content}};
  }
`

export const AccordionDetails = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ open }) => (open ? spacing.one : 0)};

  transition: all .45s ease;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  height: ${({ open, numberOperations }) => {
    const height = numberOperations * OPERATION_HEIGHT.slice(0, -2)
    return (open ? `${height}px` : '0px')
  }};
  opacity: ${({ open }) => (open ? 1 : 0)};
`

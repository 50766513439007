import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, TEXT_CONTENT, TITLES } from '@global/constants'

import { SolidButton } from 'src/baseComponents/button'

const Form = ({ onSubmit, onKeyDown, device, signUp }) => (
  <>
    <SolidButton
      block
      color='primary'
      size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
      text={TITLES.SIGN_IN}
      onClick={() => onSubmit()}
      onKeyDown={onKeyDown}
    />
    <SolidButton
      block
      color='primary'
      size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
      text={TEXT_CONTENT.SIGN_UP}
      onClick={() => signUp()}
      onKeyDown={onKeyDown}
    />
  </>
)

export default Form

Form.propTypes = {
  onSubmit: PropTypes.func,
  onKeyDown: PropTypes.func,
  device: PropTypes.number,
  signUp: PropTypes.func
}

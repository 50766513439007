import styled from 'styled-components'

import { spacing } from '@global/theme'
import { semibold } from '@global/fonts'
import { THEME_NAME } from '@global/constants'

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin: ${spacing.one} 0;
  > p, span {
    color: ${({ theme }) => theme.colors.error.main};
  }

  > button {
    > p, span {
      color: ${({ type, theme, disabled }) => {
    if (disabled) return theme.colors.grays.disabled
    if (type === 'solid') return theme.colors.text.white
    return theme.colors.text.content
  }}!important;
      ${semibold};
    }
    background-color: ${({ type, color, theme }) => {
    if (type === 'solid') return theme.colors[color]?.white
    return 'transparent'
  }}!important;

    width: ${({ block }) => (block ? '100%' : 'min-content')};
    height: ${({ height }) => {
    switch (height) {
      case 'small':
        return spacing.threeAndAHalf
      case 'large':
        return spacing.fiveAndAHalf
      default:
        return spacing.five
    }
  }};
    justify-content: space-between;
    min-width: ${({ columnHeader }) => (columnHeader ? '178px' : '144px')};
    border: 1px solid ${({ theme, disabled }) => {
    if (disabled) return theme.colors.grays.main
    return theme.colors.grays.disabled
  }};

    &:focus {
        border: 2px solid ${({ color, theme }) => theme.colors[color]?.focus};
        outline: none;
        background-color: ${({ type, color, theme }) => {
    if (type === 'solid') return theme.colors[color]?.focus
    return theme.colors.background.content
  }};
    }

    &:hover {
        border: ${({ disabled, color }) => {
    if (disabled) return 'none'
    if (color) return `2px solid ${({ theme }) => theme.colors[color]?.hover}`
  }};
    > p, span {
      color: ${({ theme, color, type, disabled }) => {
    if (disabled) return theme.colors.grays.disabled
    if (type === 'solid') return theme.colors.text.white
    return theme.colors[color]?.main
  }
}!important;
    }

    &:active {
        border: ${({ theme, disabled, color }) => {
    if (disabled) return 'none'
    if (color) return `2px solid ${theme.colors[color]?.selected}`
  }};
    }
  }
`

export const Options = styled.ul`
  height: min-content;
  max-height: 150px;
  overflow-y: auto;
  padding: 0px;
  position: absolute;
  z-index: 10;
  width: ${({ block, columnHeader }) => {
    if (block) return '100%'
    if (columnHeader) return '178px'
    return '144px'
  }};
  background-color: ${({ theme }) => theme.colors.background.content};
  box-shadow:  4px 4px 4px -1px rgb(0 0 0 / 0.25);
  margin-top: ${spacing.one};
  display: ${({ open }) => (open ? 'block' : 'none')};
  top: ${({ overflowing }) => (overflowing ? 'auto' : '100%')};
  bottom: ${({ overflowing }) => (overflowing ? '100%' : 'auto')};
`

export const Group = styled.li`
  padding-left: ${spacing.two};
  padding-right: ${spacing.two};
  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};

  display: flex;
  justify-content: space-between;
`

export const Option = styled.li`
  padding-left: ${spacing.two};
  padding-right: ${spacing.two};
  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};

  display: flex;
  justify-content: space-between;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.disabled
    return theme.colors.grays.main
  }};
    > p, span {
      color: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.text.content)}};
    }
  }

  > span {
   color: ${({ theme, selected, columnHeader, color }) => (
    (selected && !columnHeader) ? theme.colors[color]?.main : theme.colors.text.content
  )}}!important;
  > p {
   color: ${({ theme, selected, columnHeader }) => (selected && !columnHeader && theme.colors.primary.main)};
  }

  &::marker {
    color: white;
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import { BUTTON_TYPES } from '@global/constants'

import Button from '@baseComponents/button'

import { PaginationContainer, ArrowsContainer } from './styles'

const BidirectionalPagination = props => {
  const {
    columnStart,
    columnEnd,
    rowGrid,
    justifyContent,
    type,
    size,
    previousFunc,
    nextFunc,
    disabledPrev,
    disabledNext
  } = props
  const handlePreviousClic = () => previousFunc()
  const handleNextClic = () => nextFunc()

  return (
    <PaginationContainer
      columnStart={columnStart}
      columnEnd={columnEnd}
      rowGrid={rowGrid}
      justifyContent={justifyContent}
    >
      <ArrowsContainer>
        <Button
          type={type}
          icon='arrow_back_ios'
          size={size}
          color='primary'
          onClick={handlePreviousClic}
          rounded
          disabled={disabledPrev}
        />
        <Button
          type={type}
          icon='arrow_forward_ios'
          size={size}
          color='primary'
          onClick={handleNextClic}
          rounded
          disabled={disabledNext}
        />
      </ArrowsContainer>
    </PaginationContainer>
  )
}

BidirectionalPagination.propTypes = {
  rowGrid: PropTypes.number,
  columnStart: PropTypes.number,
  columnEnd: PropTypes.number,
  justifyContent: PropTypes.string,
  type: PropTypes.oneOf([
    BUTTON_TYPES.GHOST,
    BUTTON_TYPES.LINE,
    BUTTON_TYPES.SOLID
  ]),
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large'
  ]),
  previousFunc: PropTypes.func,
  nextFunc: PropTypes.func,
  disabledPrev: PropTypes.bool,
  disabledNext: PropTypes.bool
}

export default BidirectionalPagination

import React from 'react'
import PropTypes from 'prop-types'

import { SITE } from '@routes/paths'

import { BREAKPOINTS } from '@global/constants'
import Icon from '@baseComponents/icon'

import { MyLink, Tooltip, MenuItemStyle } from '../../styles'

const MenuItem = ({ pathname, to, label, icon, onClick, collapse, user, type, device }) => (
  <MenuItemStyle
    device={device}
    isActive={pathname.includes(to)}
    key={label}
    onClick={onClick}
    type={type}
  >
    <MyLink
      to={to !== SITE.PROFILE ? to : `${to}/${user?.userId}`}
      device={device}
      active={pathname.includes(to)}
      name='MyLink'
    >
      <Icon
        name={icon}
        size={device === BREAKPOINTS.DESKTOP ? 'xlarge' : 'large'}
        color='text'
      />
      {collapse && label}
    </MyLink>
    <Tooltip name='tooltip'>{label}</Tooltip>
  </MenuItemStyle>
)

MenuItem.propTypes = {
  pathname: PropTypes.string,
  to: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
  user: PropTypes.object,
  type: PropTypes.string,
  device: PropTypes.number
}

export default MenuItem

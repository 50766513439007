import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '@baseComponents/icon'

import { PreviewImg, ImageZoomInOutContainer, ButtonZoomContainer } from './styles'

const BASE_POSITION = { x: 0, y: 0 }

const ImageZoomInOut = ({ imageUrl, name }) => {
  const [scale, setScale] = useState(1)
  const [position, setPosition] = useState(BASE_POSITION)
  const imgRef = useRef(null)

  const handleZoomIn = () => {
    setScale(prevScale => prevScale + 0.1)
  }

  const handleZoomOut = () => {
    setScale(prevScale => prevScale - 0.1)
  }

  useEffect(() => {
    const image = imgRef.current
    let isDragging = false
    let previousPosition = BASE_POSITION

    const handleMouseDown = e => {
      isDragging = true
      previousPosition = { x: e.clientX, y: e.clientY }
    }

    const handleMouseMove = e => {
      if (!isDragging) return
      const deltaX = e.clientX - previousPosition.x
      const deltaY = e.clientY - previousPosition.y
      previousPosition = { x: e.clientX, y: e.clientY }
      setPosition(prevPosition => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY
      }))
    }

    const handleMoveUp = () => {
      isDragging = false
    }

    image?.addEventListener('mousedown', handleMouseDown)
    image?.addEventListener('mousemove', handleMouseMove)
    image?.addEventListener('mouseup', handleMoveUp)

    return () => {
      image?.removeEventListener('mousedown', handleMouseDown)
      image?.removeEventListener('mousemove', handleMouseMove)
      image?.removeEventListener('mousemoveup', handleMoveUp)
    }
  }, [imgRef, scale])

  return (
    <ImageZoomInOutContainer>
      <ButtonZoomContainer>
        <Icon
          size='large'
          color='primary'
          name='zoom_in'
          onClick={handleZoomIn}
        />
        <Icon
          size='large'
          color='primary'
          name='zoom_out'
          onClick={handleZoomOut}
        />
      </ButtonZoomContainer>
      <PreviewImg
        src={imageUrl}
        alt={name}
        ref={imgRef}
        draggable={false}
        scale={scale}
        position={position}
      />
    </ImageZoomInOutContainer>
  )
}

ImageZoomInOut.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string
}

export default ImageZoomInOut

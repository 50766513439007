export const MESSAGES = {
  FIELD_EMPTY: 'The field is empty.',
  BASIC_ERROR: 'An error ocurred. Try again later.',
  INVALID_CREDENTIALS: 'Invalid credentials.',
  PASSWORD_CONFIRM_SUCCESS: 'The password was changed successfully.',
  PASSWORD_CONFIRM_ERROR: 'An error ocurred in the precess. Please try again.',
  EXPIRED_TOKEN: 'Your token expired.',
  ERROR_MINOR_FROM_DATE: 'The start date can not be later than the end date.',
  SUCCESSFUL_ACTION: 'Successful action.',
  FAILED_ACTION: 'Failed action.',
  SUCCESSFULLY_CREATED_PERSON: 'Person successfully created.',
  SUCCESSFULLY_CREATED_USER: 'User successfully created.',
  PASSWORD_MODIFIED_SUCCESSFULLY: 'Password successfully changed.',
  USER_PROFILE_MODIFIED_SUCCESSFULLY: 'Profile successfully changed.',
  UPLOAD_FILE_ERROR: 'An error ocurred while uploading files.',
  UPLOAD_FILE_SUCCESS: 'File uploaded successfully.',
  GENERIC_SUCCESSFUL_CREATION: 'Creation successful.',
  NO_DATA_FOUND: 'Data not found.',
  SERVER_ERROR: 'Server error. Plaese try again.',
  IS_PROCESSING: 'Processing data',
  UPLOAD_CANCELED: 'Upload cancelled.',
  SUCCESSFULLY_CREATED_WORKSPACE: 'Workspace created successfully.',
  MSG_SENDED_SUCCESSFULLY: 'Message sended successfully.',
  INPUT_BLANK: 'The input can not be blank.',
  API_KEY_REGENERATED: 'Api key regenerated successfully.',
  REMOVE_WORKSPACE: 'Delete your workspace and all of your source data. This is irreversible.',
  WORKSPACE_REMOVED: 'Workspace removed.',
  FOLDER_CREATED: 'Folder created successfully.',
  REMOVE_MEDIA_FILE: 'Delete this media file is irreversible.',
  IMAGE_REMOVED: 'Image removed successfully.',
  COPY_LINK: 'URL copied successfully.',
  WORKSPACE_PROFILE_CREATED: 'Profile created successfully.',
  WORKSPACE_PROFILE_EDITED: 'Profile edited successfully.',
  WORKSPACE_PROFILE_REMOVED: 'Profile removed successfully.',
  REMOVE_WORKSPACE_PROFILE: 'Delete this workspace profile is irreversible.',
  PROFILE_OPERATION_REMOVED: 'Operation removed successfully.',
  REMOVE_PROFILE_OPERATION: 'Delete this operation is irreversible.',
  PROFILE_OPERATION_CREATED: 'Operation created successfully.',
  FOLDER_EDITED: 'Folder edited successfully.',
  FILE_EDITED: 'File edited successfully.',
  SUCCESSFULLY_EDITED_WORKSPACE: 'Workspace edited successfully.',
  REMOVE_OPERATION: 'Delete this operation is irreversible.',
  OPERATION_EXISTS: 'This operation already exists in the profile.',
  MEDIA_FILE_REMOVED: 'Media file removed successfully.'
}

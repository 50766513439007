import { MESSAGES } from '@global/message'
import { TOAST_PROPERTIES, FILTER_TYPES, MONTHS } from '@global/constants'

const setTokenDate = minutes => {
  const currentDateObj = new Date()
  const numberOfMlSeconds = currentDateObj.getTime()
  const addMlSeconds = minutes * 60000
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds)
  return newDateObj
}

const setBearer = token => (`Bearer ${token}`)

const tokenTimeValidator = tokenTime => {
  const res = { validate: false, error: '' }
  if (tokenTime > new Date()) return { ...res, validate: true }
  return { ...res, validate: false, error: MESSAGES.EXPIRED_TOKEN }
}

const isValidToken = tokenCreationDate => {
  const { validate, error } = tokenTimeValidator(tokenCreationDate)
  if (validate) return { isValid: true, error: '' }
  return { isValid: false, error }
}

const expiredToken = (error, setShowToast, setToastData, logout) => {
  setShowToast(true)
  setToastData({
    title: TOAST_PROPERTIES.ERROR.title,
    message: error || MESSAGES.EXPIRED_TOKEN,
    icon: TOAST_PROPERTIES.ERROR.icon,
    color: TOAST_PROPERTIES.ERROR.color
  })
  logout()
}

export const TOKEN_HELPERS = {
  SET_TOKEN_DATE: setTokenDate,
  SET_BEARER: setBearer,
  TOKEN_TIME_VALIDATOR: tokenTimeValidator,
  IS_VALID_TOKEN: isValidToken,
  EXPIRED_TOKEN: expiredToken
}

export const capitalizeFirstLetter = str => str && str.charAt(0).toUpperCase() + str.slice(1)

export const formatDateBasic = (
  stringJSONDate, type = 'long', nameDay = true, numberMonth = true, dateTime = false
) => {
  const nameDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']

  if (!stringJSONDate) return 'N/A'

  const date = new Date(stringJSONDate)
  const time = dateTime ? date.toLocaleTimeString() : ''

  if (type === 'short') return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}${time ? ` ${time}` : ''}`

  const weekDay = nameDay ? nameDays[date.getDay() - 1] : ''
  const day = date.getDate()
  const month = numberMonth ? date.getMonth() + 1 : MONTHS[date.getMonth()]?.label
  const year = date.getFullYear()
  const strDate = `${nameDay ? weekDay : ''} ${day} 
  ${numberMonth ? '/' : ' de '}${month} ${numberMonth ? '/' : ' de '}
  ${year} 
  ${dateTime ? time : ''}`
  return strDate
}

export const transformArrToObj = (array, key) => {
  let newObj = {}
  for (let i = 0; i < array.length; i++) {
    newObj = {
      ...newObj,
      [array[i][key]]: array[i]
    }
  }
  return newObj
}

export const sortByKey = (arr, key) => {
  if (arr.length === 0) return arr
  const newArr = [...arr]
  const SortArray = (a, b) => {
    if (a[key] < b[key]) return -1
    return 1
  }

  return newArr.sort(SortArray)
}

export const changeNameForLabel = arrData => {
  const newData = arrData.map(item => ({
    id: item.id,
    label: item.name
  }))
  return newData
}

const removeParameterFromActiveFilters = (parameters, activeFilters) => {
  const currentActiveFilters = { ...activeFilters }

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const property in currentActiveFilters) {
    const propWanted = parameters.find(p => p === property)
    let wanted = ''
    if (!propWanted) {
      wanted = Object.keys(currentActiveFilters)
        .find(af => af === property)
        .toLowerCase()
        .includes('date')
    }

    if (propWanted || wanted) {
      delete currentActiveFilters[property]
    }
  }
  return currentActiveFilters
}

export const filterBodyBuilder = (filters, pageSize, setActiveFilters, currentActiveFilters, parametersFilters) => {
  currentActiveFilters = removeParameterFromActiveFilters(parametersFilters, currentActiveFilters)

  let body = currentActiveFilters ? { ...currentActiveFilters } : { pageNumber: 1, pageSize }

  for (let i = 0; i < filters.length; i++) {
    const { type, name, items: filterItems } = filters[i]
    let value = null
    if (type !== FILTER_TYPES.DATE_PICKER) {
      value = type === FILTER_TYPES.CHECKBOX
        ? filterItems.map(item => Number(item.id))
        : filterItems[0]
      body = {
        ...body,
        [name]: value
      }
    } else {
      for (let j = 0; j < filterItems.length; j++) {
        const { name: dateName, value: dateValue } = filterItems[j]
        body = {
          ...body,
          [dateName]: dateValue
        }
      }
    }
  }

  if (filters.length > 0) {
    const activesFilters = { ...body }
    delete activesFilters.pageNumber
    delete activesFilters.pageSize
    setActiveFilters(activesFilters)
  } else setActiveFilters(body)

  return body
}

export const handleClickPaginationItem = (n, filterState, pageSize, sendBody, setNewItemSelected) => {
  let body = null
  if (filterState) {
    body = filterState
    body = {
      ...body,
      pageNumber: (n + 1),
      pageSize
    }
  } else {
    body = {
      pageNumber: (n + 1),
      pageSize
    }
  }

  sendBody(body)
  setNewItemSelected(n)
}

export const filterOrderBuilder = props => {
  const {
    filterState,
    setFilterState,
    orderByDesc,
    currentPageSize,
    currentPageNumber,
    setNewItemSelected
  } = props
  let { columnName } = props
  let body = null
  let flag = false
  if (columnName.toLowerCase() === 'code') {
    columnName = 'Id'
  }
  if (filterState) {
    if (filterState.orderByColumn && columnName !== filterState.orderByColumn) {
      setNewItemSelected(0)
      flag = true
    }
    if (!filterState.orderByColumn) flag = true

    body = { ...filterState }
    body = {
      ...body,
      pageNumber: flag ? 1 : currentPageNumber + 1,
      pageSize: currentPageSize,
      orderByColumn: columnName,
      orderByDesc
    }
  } else {
    body = {
      pageNumber: 1,
      pageSize: currentPageSize,
      orderByColumn: columnName,
      orderByDesc
    }
  }
  setFilterState({
    ...filterState,
    orderByColumn: columnName,
    orderByDesc
  })
  return body
}

export const getMonthName = id => {
  const monthName = MONTHS.find(m => m.id === id - 1)
  return monthName.label
}

export const searcTraslation = (strWanted, siteObj, translationObj) => {
  let translation = 'Registro de historia'
  const urlWanted = Object.entries(siteObj).find(u => u[1] === strWanted)
  if (urlWanted) {
    translation = translationObj[urlWanted[0]]
  }
  return translation
}

export const arrayBufferToBase64 = buffer => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return btoa(binary)
}

export const bytesToMegabytes = bytes => {
  const megabytes = bytes / (1024 * 1024)
  return megabytes.toFixed(2)
}

export const debounce = (func, delay) => {
  let timeoutId

  return (...args) => {
    const context = this
    const args2 = args

    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(context, args2)
    }, delay)
  }
}

// Simulate asynchronous processing when using mocks
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

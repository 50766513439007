import { THEME_NAME } from '@global/constants'
import { bold, SourceCodePro } from '@global/fonts'
import { spacing } from '@global/theme'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

export const WraperMenuForm = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormMenuItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ padding }) => padding || spacing.two};
`
export const ListItemWrapper = styled.div`
  width: 100%;
`

export const ListItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  ${SourceCodePro};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  font-size: 14px;
  ${bold};
  letter-spacing: 0.5px;
  padding: ${spacing.one};
  border-radius: ${spacing.one};
  color: ${({ color, theme }) => {
    if (color) return theme.colors[color].main
    return theme.colors.text.content
  }};

  & > span {
    margin-right: 16px;
    color: ${({ color, theme }) => {
    if (color) return theme.colors[color].main
    return theme.colors.text.content
  }}!important;
  }

  &:hover {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.dark
    return theme.colors.grays.light
  }};
      
  }
`

import { THEME_NAME } from '@global/constants'
import { semibold, bold, WEIGHT, regular } from '@global/fonts'
import { spacing } from '@global/theme'

export const handleType = (color, isFocus, isDisabled, theme) => {
  const defaultColor = color || 'text'

  const disabledState = {
    backgroundColor: theme.colors.text.disabled,
    color: theme.colors.text.disabled,
    border: `${spacing.quarter} solid ${theme.colors.grays.main}`
  }

  const focusState = {
    backgroundColor: 'transparent',
    color: theme.colors.text.content,
    border: isFocus && `${spacing.quarter} solid ${theme.colors[defaultColor]?.main}`,
    ':hover': {
      borderColor: theme.colors[defaultColor]?.hover
    }
  }

  const mainState = {
    backgroundColor: theme.name === THEME_NAME.LIGHT ? 'transparent' : theme.colors.background.content,
    border: `1px solid ${theme.name === THEME_NAME.LIGHT ? theme.colors.grays.disabled : theme.colors.grays.main}`,
    ':hover': {
      borderColor: theme.colors[defaultColor]?.main
    }
  }

  if (isDisabled) return disabledState
  if (isFocus) return focusState
  return mainState
}

export const SelectStyles = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  control: (provided, state) => {
    const { color, theme } = state.selectProps
    const { isFocused, isDisabled } = state

    return {
      ...provided,
      border: `1px solid ${theme.colors.grays.disabled}`,
      borderRadius: '5px',
      boxSizing: 'border-box',
      boxShadow: 'none',
      cursor: 'pointer',
      minHeight: `${spacing.fiveAndAHalf}`,
      height: 'auto',
      color: theme.colors.text.content,
      ...(handleType(color, isFocused, isDisabled, theme))
    }
  },
  menu: (provided, state) => {
    const { theme } = state.selectProps
    return {
      ...provided,
      color: theme.name === THEME_NAME.LIGHT ? theme.colors.text.content : theme.colors.grays.main,
      padding: '8px 16px 8px 8px',
      minWidth: 'fit-content',
      backgroundColor: theme.colors.background.content
    }
  },
  indicatorSeparator: (provided, state) => {
    const { theme } = state.selectProps

    return {
      ...provided,
      backgroundColor: theme.name === THEME_NAME.LIGHT ? theme.colors.grays.disabled : theme.colors.grays.main
    }
  },
  indicatorsContainer: (provided, state) => {
    const { theme } = state.selectProps

    return {
      ...provided,
      color: theme.name === THEME_NAME.LIGHT ? theme.colors.text.content : theme.colors.grays.main,
      ':hover': {
        color: theme.colors.text.content
      }
    }
  },
  placeholder: (provided, state) => {
    const { theme } = state.selectProps
    return {
      ...provided,
      color: theme.colors.text.content,
      fontFamily: 'Source Code Pro',
      fontSize: '14px',
      fontWeight: regular
    }
  },
  singleValue: provided => ({
    ...provided,
    fontFamily: 'Source Code Pro',
    fontSize: '14px',
    fontWeight: semibold
  }),
  dropdownIndicator: (provided, state) => {
    const { theme } = state.selectProps
    return {
      ...provided,
      color: theme.name === THEME_NAME.LIGHT ? theme.colors.text.content : theme.colors.grays.main,
      padding: '8px 16px 8px 8px',
      ':hover': {
        color: theme.colors.text.content
      }
    }
  },
  option: (provided, { isFocused, selectProps }) => {
    const { color, theme } = selectProps
    const defaultColor = color || 'text'

    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'space-between',
      fontFamily: 'Source Code Pro',
      fontSize: '14px',
      padding: `${spacing.one} ${spacing.two} ${spacing.one} ${spacing.two}`,
      backgroundColor: theme.colors.background.content,
      color: theme.colors.text.content,
      fontWeight: isFocused && defaultColor === 'text' ? semibold : null,
      ':hover': {
        backgroundColor: theme.colors.primary.hover,
        color: theme.colors.text.white
      }
    }
  },
  multiValue: (provided, { selectProps }) => {
    const { theme } = selectProps
    return {
      ...provided,
      width: 'fit-content',
      minWidth: '101px',
      height: `${spacing.four}`,
      fontFamily: 'Source Code Pro',
      fontSize: `${spacing.two}`,
      fontWeight: `${WEIGHT.main}`,
      borderRadius: '2px',
      backgroundColor: theme.name === THEME_NAME.LIGHT
        ? theme.colors.background.variant : theme.colors.background.light,
      boxSizing: 'border-box',
      color: `${theme.colors.text.content}`,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  multiValueRemove: (provided, { selectProps }) => {
    const { color, theme } = selectProps
    const defaultColor = color || 'text'

    return {
      ...provided,
      color: theme.colors.text.content,
      ':hover': {
        color:
        defaultColor === 'text'
          ? theme.colors[defaultColor].main
          : theme.colors[defaultColor].hover
      }
    }
  },
  noOptionsMessage: (provided, { selectProps }) => {
    const { color, theme } = selectProps
    const defaultColor = color || 'text'

    return {
      ...provided,
      color: theme.colors[defaultColor].main,
      backgroundColor: theme.colors.background.content,
      fontWeight: bold,
      fontFamily: 'Source Code Pro',
      fontSize: `${spacing.five}`
    }
  }
}

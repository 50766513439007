import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/index'
import { MESSAGES } from '@global/message'
import { NOT_APPLICABLE, RESOURCE_TYPE } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'

import { folderAdapter } from '@containers/fileManager/adapter/folder.adapter'
import { imageAdapter } from '../adapter/image.adapter'

import { MEDIA_FILE_ACTIONS } from './actions'
import { MEDIA_FILES } from './types'

const apiCalls = {
  folderCalls: {
    getAllResourcesApi: ({ token, folderId }) => Axios.get(`folder/${folderId}/`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    createFolderApi: ({ token, data }) => Axios.post('folder/', data, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
    editFolderApi: ({ token, folderId, data }) => Axios.put(`folder/${folderId}/`, data, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    })
  },
  imageCalls: {
    removeImageApi: ({ token, imageId }) => Axios.delete(`image/${imageId}/`, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
    getImageApi: ({ token, imageId }) => Axios.get(`image/${imageId}/`, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
    getUrlImageApi: ({ token, imageId }) => Axios.get(`image/${imageId}/urls/`, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    })
  }

}

export function* getResources ({ payload }) {
  try {
    const { folderCalls } = apiCalls
    const { data: folder } = yield call(folderCalls.getAllResourcesApi, payload)
    const folderData = folderAdapter(folder)
    const { linkedFolders, contents } = folderData

    const linkedFoldersDataMapped = linkedFolders.map(lfd => {
      const linkedFolder = folderAdapter(lfd)
      let size = linkedFolder.size * (1 * (10 ** -6))
      size = size ? size.toFixed(2) : NOT_APPLICABLE
      return {
        ...linkedFolder,
        type: RESOURCE_TYPE.FOLDER.name,
        items: linkedFolders.length,
        size
      }
    })

    const imagesDataMapped = contents.map(lfd => {
      const imageFile = imageAdapter(lfd)
      let size = imageFile.size * (1 * (10 ** -6))
      size = size ? size.toFixed(2) : NOT_APPLICABLE
      return {
        ...imageFile,
        type: RESOURCE_TYPE.IMAGE.name,
        size
      }
    })

    const allResources = [
      ...linkedFoldersDataMapped,
      ...imagesDataMapped
    ]
    yield put(MEDIA_FILE_ACTIONS.RESOURCES_SUCCESS(allResources))
  } catch (error) {
    yield put(MEDIA_FILE_ACTIONS.ERROR_MESSAGE(error))
  }
}
// FOLDER
export function* createFolder ({ payload }) {
  const { folderCalls } = apiCalls
  try {
    const { status } = yield call(folderCalls.createFolderApi, payload)
    yield put(MEDIA_FILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.FOLDER_CREATED }))
  } catch (error) {
    yield put(MEDIA_FILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* editFolder ({ payload }) {
  const { folderCalls } = apiCalls
  try {
    const { status } = yield call(folderCalls.editFolderApi, payload)

    yield put(MEDIA_FILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.FOLDER_EDITED }))
  } catch (error) {
    yield put(MEDIA_FILE_ACTIONS.ERROR_MESSAGE(error))
  }
}
// MEDIA FILES
export function* removeMediaFile ({ payload }) {
  // payload should have the file type
  const { imageCalls } = apiCalls
  try {
    const { status } = yield call(imageCalls.removeImageApi, payload)

    yield put(MEDIA_FILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.IMAGE_REMOVED }))
  } catch (error) {
    yield put(MEDIA_FILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* getMediaFile ({ payload }) {
  // payload should have the file type
  const { imageCalls } = apiCalls
  try {
    const { data: mediaFileData } = yield call(imageCalls.getImageApi, payload)

    yield put(MEDIA_FILE_ACTIONS.GET_MEDIA_FILE_SUCCESS(mediaFileData))
  } catch (error) {
    yield put(MEDIA_FILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* getUrlMediaFile ({ payload }) {
  // payload should have the file type
  const { imageCalls } = apiCalls
  try {
    const { data: urlMediaFileData } = yield call(imageCalls.getUrlImageApi, payload)

    yield put(MEDIA_FILE_ACTIONS.GET_URL_MEDIA_FILE_SUCCESS(urlMediaFileData))
  } catch (error) {
    yield put(MEDIA_FILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export default function* saga () {
  yield takeLatest(MEDIA_FILES.GET_RESOURCES, getResources)
  yield takeLatest(MEDIA_FILES.CREATE_FOLDER, createFolder)
  yield takeLatest(MEDIA_FILES.EDIT_FOLDER, editFolder)
  yield takeLatest(MEDIA_FILES.REMOVE_MEDIA_FILE, removeMediaFile)
  yield takeLatest(MEDIA_FILES.GET_MEDIA_FILE, getMediaFile)
  yield takeLatest(MEDIA_FILES.GET_URL_MEDIA_FILE, getUrlMediaFile)
}

import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { DASHBOARD_ACTIONS } from './actions'
import { DASHBOARD } from './types'
import { newWorkspaceAdapter, workspaceAdapter } from '../adapter'
import { MOCK_OPERATIONS } from '../mock'

const apiCalls = {
  getWorkspacesApi: ({ token, page }) => (
    Axios(`workspace/?page=${page}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createWorkspace: ({ token, data }) => Axios.post('workspace/', data,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getOperationsApi: () => MOCK_OPERATIONS
}

export function* getWorkspaces ({ payload }) {
  try {
    let { data } = yield call(apiCalls.getWorkspacesApi, payload)
    const resultsAdapted = data?.results.map(w => workspaceAdapter(w))
    data = {
      ...data,
      results: resultsAdapted
    }
    yield put(DASHBOARD_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(DASHBOARD_ACTIONS.ERROR(error))
  }
}

export function* createWorkspace ({ payload }) {
  try {
    let { data: newWorkspace } = yield call(apiCalls.createWorkspace, payload)
    newWorkspace = newWorkspaceAdapter(newWorkspace)

    yield put(DASHBOARD_ACTIONS.WORKSPACE_SUCCESS(newWorkspace))
  } catch (error) {
    yield put(DASHBOARD_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* getOperations ({ payload }) {
  try {
    const { data: operations } = yield call(apiCalls.getOperationsApi, payload)
    // newWorkspace = newWorkspaceAdapter(newWorkspace)

    yield put(DASHBOARD_ACTIONS.OPERATIONS_SUCCESS(operations))
  } catch (error) {
    yield put(DASHBOARD_ACTIONS.ERROR_MESSAGE(error))
  }
}

export default function* saga () {
  yield takeLatest(DASHBOARD.GET, getWorkspaces)
  yield takeLatest(DASHBOARD.CREATE_WORKSPACE, createWorkspace)
  yield takeLatest(DASHBOARD.GET_OPERATIONS, getOperations)
}

import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  BREAKPOINTS,
  FLEX_PROPERTIES,
  FUNCTION_TYPE,
  SUBTITLES,
  TEXT_CONTENT,
  TITLES,
  TOAST_POSITION
} from '@global/constants'
import { MESSAGES } from '@global/message'
import { TOKEN_HELPERS } from '@utils/helpers'
import {
  WrapperLoading,
  WrapperHeading,
  Box,
  Column
} from '@global/styles'
import defaultAvatar from '@assets/male-avatar.jpg'

import Loading from '@components/loading'
import { Heading, Text } from 'src/baseComponents/texts'
import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'
import Tabs from '@components/tabs'
import Card from '@components/cards'
import { GhostButton, LineButton } from '@baseComponents/button'
import Icon from '@baseComponents/icon'
import { PasswordInput, PasswordRepeatInput, TextInput } from '@components/inputs/inputs'
import { useNotification } from 'src/context/notification.context'

import {
  BodyCard,
  HeaderProfile,
  WrapperImg,
  ImgProfile,
  ImgProfileHover,
  TitleContainer,
  WrapperInput,
  ImageInput
} from './styles'

import useRedux from './redux'
import { PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { INPUT_NAMES } from './constants'

const PROFILE_TAB_LIST = [
  { id: 1, text: 'General' },
  { id: 2, text: 'Security' }
]

function Profile () {
  useRedux()
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { GetToken, GetUser } = useUser()
  const { device } = useWindowDimensions()
  const history = useHistory()
  const { getNotification } = useNotification()

  const [currentToken] = useState(GetToken())
  const [profile, setProfile] = useState()
  const [editEmail, setEditEmail] = useState(false)
  const [imageProfile, setImageProfile] = useState(defaultAvatar)
  const [errorFlag, setErrorFlag] = useState(false)
  const [tabActive, setTabActive] = useState(1)
  const [passwordInput, setPasswordInput] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [passwordRepeat, setPasswordRepeat] = useState({
    name: '',
    value: '',
    error: ''
  })

  const loading = useSelector(SELECTORS.LOADING)
  // const loadingProfile = useSelector(SELECTORS.LOADING_PROFILE)
  // const profileData = useSelector(SELECTORS.PROFILE_SUCCESS)
  const profileError = useSelector(SELECTORS.PROFILE_ERROR)

  const getProfileData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PROFILE_ACTIONS.GET_PROFILE({ userId, token }))
    }
  }

  const handleInputChange = e => {
    const { name, value } = e
    if (name === INPUT_NAMES.FULL_NAME) {
      setProfile({
        ...profile,
        fullname: value
      })
    } else {
      setProfile({
        ...profile,
        email: value
      })
    }
  }

  const handleEditProfile = () => {
    dispatch(PROFILE_ACTIONS.EDIT_PROFILE({ profile, token: currentToken.token }))
  }

  const activateEditionProfile = () => setEditEmail(preVal => !preVal)

  const handleSaveImage = file => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
    fileReader.onloadend = () => {
      if (fileReader.readyState === 2) {
        const blob = new Blob([fileReader.result])
        const formData = new FormData()
        formData.append('formFile', blob, file.name)
        if (fileReader.result) {
          const { token } = currentToken
          dispatch(PROFILE_ACTIONS.EDIT_IMAGE_PROFILE({ imageToSend: formData, token }))
        }
      }
    }
  }

  const handleChange = e => {
    const image = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(image)

    reader.onloadend = () => {
      if (reader.readyState === 2) {
        if (reader.result) {
          setImageProfile(reader.result)
        }
      }
    }
  }

  const handlePasswordChange = e => {
    setPasswordInput(e)
  }

  const handleChangePasswordRepeat = name => setPasswordRepeat(name)

  const handleChangePassword = () => {}

  const handleRemoveAccount = () => {
    getNotification({
      title: 'Error',
      message: MESSAGES.BASIC_ERROR,
      icon: 'cancel',
      color: 'error',
      position: TOAST_POSITION.rightTop,
      open: true,
      timeOut: 40000
    })
  }

  useEffect(() => {
    const user = GetUser()
    setProfile(user)
    setImageProfile(user.image)
  }, [])

  // useEffect(() => {
  //   if (profileData) {
  //     setProfile(profileData)

  //     const blob = new Blob([profileData.profileImage], { type: 'image/*' })
  //     const imageUrl = URL.createObjectURL(blob)
  //     setImageProfile(imageUrl)
  //   }
  // }, [profileData])

  useEffect(() => {
    if (profileError) {
      if (!errorFlag) {
        getProfileData()
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [profileError])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            <WrapperHeading>
              <Heading text={TITLES.PROFILE} type='h1' size='large' />
            </WrapperHeading>
            <Tabs
              tapList={PROFILE_TAB_LIST}
              columnStart={1}
              columnEnd={13}
              rowStart={2}
              rowEnd={2}
              color='primary'
              onChange={setTabActive}
            />
            {tabActive === 1
              ? (
                <>
                  <Card
                    type={device >= BREAKPOINTS.TABLET ? 'horizontal' : 'vertical'}
                    width='100%'
                    rowStart={3}
                    rowEnd={3}
                    alignItems={FLEX_PROPERTIES.ALIGN_ITEMS.FLEX_START}
                  >
                    <TitleContainer device={device} minHeight='166px'>
                      <Heading text={TEXT_CONTENT.BASIC_DETAILS} size='small' type='h5' />
                    </TitleContainer>
                    <BodyCard name='profileData'>
                      <HeaderProfile>
                        <WrapperImg>
                          <ImgProfileHover>
                            <Icon name='photo_camera' size='medium' />
                            <Text text={TEXT_CONTENT.SELECT} size='medium' weight='bold' />
                            <ImageInput type='file' onChange={handleChange} />
                          </ImgProfileHover>
                          <ImgProfile src={imageProfile} alt='profile' />
                        </WrapperImg>
                        <GhostButton
                          text={FUNCTION_TYPE.EDIT.label}
                          rounded
                          color='primary'
                          onClick={handleSaveImage}
                          disabled
                        />
                      </HeaderProfile>
                      <WrapperInput>
                        <TextInput
                          name={INPUT_NAMES.FULL_NAME}
                          label={SUBTITLES.FULL_NAME}
                          onChange={handleInputChange}
                          color='primary'
                          value={profile?.username ?? ''}
                        />
                        <GhostButton
                          text={FUNCTION_TYPE.SAVE.label}
                          rounded
                          color='primary'
                          block
                          onClick={handleEditProfile}
                          disabled
                        />
                      </WrapperInput>
                      <WrapperInput>
                        <TextInput
                          name={INPUT_NAMES.EMAIL}
                          label={SUBTITLES.EMAIL}
                          onChange={handleInputChange}
                          color='primary'
                          value={profile?.email ?? ''}
                          required
                          disabled={!editEmail}
                        />
                        <GhostButton
                          text={FUNCTION_TYPE.EDIT.label}
                          rounded
                          color='primary'
                          block
                          onClick={activateEditionProfile}
                          disabled
                        />
                      </WrapperInput>
                    </BodyCard>
                  </Card>
                  <Card
                    type={device >= BREAKPOINTS.TABLET ? 'horizontal' : 'vertical'}
                    width='100%'
                    rowStart={4}
                    rowEnd={4}
                  >
                    <TitleContainer device={device} minHeight='56px'>
                      <Heading text={TEXT_CONTENT.REMOVE_ACCOUNT} size='small' type='h5' />
                    </TitleContainer>
                    <BodyCard>
                      <Text size='medium' weight='bold'>
                        Minim deserunt commodo velit consectetur ut occaecat qui occaecat aliqua laboris ullamco duis.
                      </Text>
                      <Box />
                      <LineButton
                        text={TEXT_CONTENT.REMOVE_ACCOUNT}
                        color='error'
                        onClick={handleRemoveAccount}
                        disabled
                      />
                    </BodyCard>
                  </Card>
                </>
              )
              : null}
            {tabActive === 2
              ? (
                <Card
                  type={device >= BREAKPOINTS.TABLET ? 'horizontal' : 'vertical'}
                  width='100%'
                  rowStart={4}
                  rowEnd={4}
                >
                  <TitleContainer device={device} minHeight='156px'>
                    <Heading text={SUBTITLES.CHANGE_PASSWORD} size='small' type='h5' />
                  </TitleContainer>
                  <BodyCard>
                    <WrapperInput>
                      <Column>
                        <PasswordInput
                          label={SUBTITLES.PASSWORD}
                          placeholder={TEXT_CONTENT.ENTER_PASSWORD}
                          name='password'
                          value={passwordInput.value}
                          error={passwordInput.error}
                          height='medium'
                          color='primary'
                          onChange={handlePasswordChange}
                          bits={[{ icon: 'password', position: 'left', colorBit: 'primary' }]}
                        />
                        <PasswordRepeatInput
                          name='passwordRepaeat'
                          label={SUBTITLES.REPEAT_PASSWORD}
                          placeholder={TEXT_CONTENT.ENTER_PASSWORD}
                          pwRepeat={passwordInput.value}
                          onChange={handleChangePasswordRepeat}
                          color='primary'
                          error={passwordRepeat.error}
                          message={passwordRepeat.error}
                          bits={[{ icon: 'password', position: 'left', colorBit: 'primary' }]}
                          height='medium'
                          required
                        />

                      </Column>
                      <GhostButton
                        text={FUNCTION_TYPE.EDIT.label}
                        rounded
                        color='primary'
                        block
                        onClick={handleChangePassword}
                        // disabled={!passwordRepeat.value}
                        disabled
                      />
                    </WrapperInput>
                  </BodyCard>
                </Card>
              )
              : null}
          </>
        )}
    </>
  )
}

export default Profile

import produce from 'immer'

import { PROFILE } from './types'

export const initialState = {
  loading: false,
  error: false,
  profile: null,
  loadingProfile: null,
  messageInformation: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PROFILE.GET_PROFILE:
      draft.loading = true
      break
    case PROFILE.GET_PROFILE_SUCCESS:
      draft.loading = false
      draft.profile = payload
      break
    case PROFILE.GET_PROFILE_ERROR:
      draft.loading = false
      draft.error = payload
      break
    case PROFILE.EDIT_PROFILE:
      draft.loadingProfile = true
      break
    case PROFILE.PROFILE_UPDATED:
      draft.loadingProfile = false
      draft.profile = payload
      break
    case PROFILE.MESSAGE_INFORMATION:
      draft.loadingProfile = false
      draft.loading = false
      draft.messageInformation = payload
      break
    default:
      return initialState
  }
})

export default reducer

import React from 'react'
import PropTypes from 'prop-types'

import { Text } from 'src/baseComponents/texts'
import { FileItemsNoAcceptedContainer } from './styles'

const NoAcceptedFilesComponent = ({ noAcceptedFiles, text }) => noAcceptedFiles.length > 0
      && (
      <>
        <Text size='medium' weight='main' align='left'>
          {`${text} ${noAcceptedFiles.length}`}
        </Text>
        <FileItemsNoAcceptedContainer>
          {noAcceptedFiles.map(f => (
            <Text size='medium' weight='main' align='left' color='error'>
              {`${f.name} - ${f.errorType}`}
            </Text>
          ))}
        </FileItemsNoAcceptedContainer>
      </>
      )

NoAcceptedFilesComponent.propTypes = {
  noAcceptedFiles: PropTypes.array,
  text: PropTypes.string
}

export default NoAcceptedFilesComponent

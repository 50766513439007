import styled from 'styled-components'

import { spacing } from '@global/theme'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || 0};
  flex: 1;
  margin-bottom: ${({ device }) => (device <= BREAKPOINTS.TABLET ? '32px' : 0)};
`

export const BodyCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  height: 100%;
`

export const HeaderProfile = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${spacing.two};
  & > :first-child {
    margin-right: ${spacing.two};
  }
`

export const WrapperImg = styled.div`
  margin-bottom: ${spacing.two};
  border-radius: 50%;
  border: ${({ theme }) => {
    const currentColor = theme.name === THEME_NAME.DARK
      ? theme.colors.grays.dark
      : theme.colors.grays.light
    return `${spacing.quarter} dashed ${currentColor}`
  }};
  padding: ${spacing.one};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
`

export const ImgProfileHover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
  background-color: #2f374680;
  z-index: 3;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  & > :first-child {
    margin-right: ${spacing.one};
  }

  & > span, p {
    color: ${({ theme }) => theme.colors.text.white}!important;
  }
`

export const ImgProfile = styled.img`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  position: absolute;
  background-color: ${({ theme }) => (theme.colors.background.content)};
  object-fit: cover;
  z-index: 2;
`

export const ImageInput = styled.input`
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

export const WrapperInput = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  & > :first-child {
    margin-right: ${spacing.two};
    flex: 4;
  }
  & > :last-child {
    flex: 1;
    margin-bottom: ${spacing.three};
  }
`

import { WORKSPACE } from './types'

export const getWorkspaceData = payload => ({
  type: WORKSPACE.GET_WORKSPACE_DATA,
  payload
})

export const workspaceDataSuccess = payload => ({
  type: WORKSPACE.WORKSPACE_DATA_SUCCESS,
  payload
})

export const getWorkspaceSummary = payload => ({
  type: WORKSPACE.GET_WORKSPACE_SUMMARY,
  payload
})

export const getWorkspaceSummarySuccess = payload => ({
  type: WORKSPACE.WORKSPACE_SUMMARY_SUCCESS,
  payload
})

export const getWorkspaceSummaryError = payload => ({
  type: WORKSPACE.WORKSPACE_SUMMARY_ERROR,
  payload
})

export const editWorkspaceSummary = payload => ({
  type: WORKSPACE.EDIT_WORKSPACE_SUMMARY,
  payload
})

export const successMessage = payload => ({
  type: WORKSPACE.SUCCESS_MESSAGE,
  payload
})

export const clearSuccessMessage = () => ({
  type: WORKSPACE.CLEAR_SUCCESS_MESSAGE
})

export const errorMessage = payload => ({
  type: WORKSPACE.ERROR_MESSAGE,
  payload
})

export const clearErrorMessage = () => ({
  type: WORKSPACE.CLEAR_ERROR_MESSAGE
})

export const regenerateApiKey = payload => ({
  type: WORKSPACE.REGENERATE_API_KEY,
  payload
})

export const removeWorkspace = payload => ({
  type: WORKSPACE.REMOVE_WORKSPACE,
  payload
})

export const WORKSPACE_ACTIONS = {
  GET_WORKSPACE_DATA: getWorkspaceData,
  WORKSPACE_DATA_SUCCESS: workspaceDataSuccess,
  GET_WORKSPACE_SUMMARY: getWorkspaceSummary,
  WORKSPACE_SUMMARY_SUCCESS: getWorkspaceSummarySuccess,
  WORKSPACE_SUMMARY_ERROR: getWorkspaceSummaryError,
  EDIT_WORKSPACE_SUMMARY: editWorkspaceSummary,
  SUCCESS_MESSAGE: successMessage,
  CLEAR_SUCCESS_MESSAGE: clearSuccessMessage,
  ERROR_MESSAGE: errorMessage,
  CLEAR_ERROR_MESSAGE: clearErrorMessage,
  REGENERATE_API_KEY: regenerateApiKey,
  REMOVE_WORKSPACE: removeWorkspace
}

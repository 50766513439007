import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

import { SourceCodePro, bold } from '@global/fonts'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'
import { spacing } from '@global/theme'

import {
  PADDING_TOP_PAGE,
  PADDING_BOTTOM_PAGE,
  PADDING_HORIZONTAL,
  NAV_WIDTH,
  HEADER_HEIGHT,
  PADDING_HORIZONTAL_DESKTOP,
  PADDING_HORIZONTAL_BIG_SCREEN
} from './constants'

export const PublicLayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr;
  background-color: ${({ theme, device }) => {
    if (device < BREAKPOINTS.TABLET) return theme.colors.primary.main
    return theme.colors.background.main
  }}!important;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  
  display: grid;
  grid-template-columns: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return '0 auto'
    if (device >= BREAKPOINTS.DESKTOP) return `${NAV_WIDTH.DESKTOP} auto`
    if (device < BREAKPOINTS.TABLET || device > BREAKPOINTS.TABLET_PORTRAIT) return `${NAV_WIDTH.MOBILE} auto`
  }};
  grid-template-rows: ${HEADER_HEIGHT} 1fr;

  grid-template-areas:
  'nav header'
  'nav page';
  background-color: ${({ theme, background }) => {
    if (background) return theme.colors.background.main
    return theme.colors.background.main
  }}!important;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const Page = styled.main`
  height: auto;
  grid-area: page;

  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${spacing.three};
  grid-row-gap: ${spacing.two};
  align-items: start;

  padding: ${({ device }) => {
    let horizontalPadding = PADDING_HORIZONTAL
    if (device === BREAKPOINTS.DESKTOP) horizontalPadding = PADDING_HORIZONTAL_DESKTOP
    if (device === BREAKPOINTS.BIG_SCREEN) horizontalPadding = PADDING_HORIZONTAL_BIG_SCREEN
    return `${PADDING_TOP_PAGE} ${horizontalPadding} ${PADDING_BOTTOM_PAGE}`
  }};

  background-color: ${({ theme }) => (theme.colors.background.main)};
  overflow-y: ${({ scroll }) => (scroll ? 'auto' : null)};
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: ${spacing.oneAndAQuarter};
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.main};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.dark
    return theme.colors.grays.disabled
  }};
    border-radius: ${spacing.one};
  }
  
`

export const MyLink = styled(NavLink)`
  ${SourceCodePro};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  font-size: 14px;
  ${bold};
  letter-spacing: 0.5px;
  color: ${({ color, theme }) => {
    if (color) return theme.colors[color].main
    return theme.colors.text.main
  }};
`

import { spacing } from '@global/theme'

export const PADDING_TOP_PAGE = spacing.two
export const PADDING_BOTTOM_PAGE = spacing.four
export const PADDING_HORIZONTAL = spacing.oneAndAHalf
export const PADDING_HORIZONTAL_DESKTOP = spacing.sevenAndAHalf
export const PADDING_HORIZONTAL_BIG_SCREEN = '152px'
export const NAV_WIDTH = {
  DESKTOP: '248px',
  MOBILE: '80px'
}

export const HEADER_HEIGHT = spacing.eight

export const OFFSET_BUTTON_COLLAPSABLE = 13

export const TYPE_MENU = 'title'

export const HEIGHT_HEADER_NAV = spacing.sixAndAHalf
export const HEIGHT_FOOTER_NAV = spacing.five

export const IMG_PROFILE_SIZE = spacing.five

export const FILE_NOT_ACCEPTED = 'fileNoAccepted'

export const CONTENT_TYPES = {
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  WORD: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF: 'application/pdf',
  TXT: 'text/plain'
}

export const RESOURCE_TYPE = {
  DOCUMENT: { name: 'document', icon: 'description' },
  IMAGE: { name: 'image', icon: 'image' },
  AUDIO: { name: 'audio', icon: 'audio_file' },
  VIDEO: { name: 'video', icon: 'videocam' },
  FOLDER: { name: 'folder', icon: 'folder' }
}

export const BREAKPOINTS = {
  MOBILE: 578,
  TABLET_PORTRAIT: 768,
  TABLET: 1024,
  DESKTOP: 1366,
  BIG_SCREEN: 1680
}

export const DEVICE_NAME = {
  BIG_SCREEN: 'bigScreen',
  DESKTOP: 'desktop',
  TABLET_PORTRAIT: 'tabletPortrait',
  TABLET: 'tablet',
  MOBILE: 'mobile'
}

export const APP_NAME = 'Media Wizard'

export const TOAST_PROPERTIES = {
  INFO: {
    title: 'Information',
    color: 'info',
    icon: 'info'
  },
  WARNING: {
    title: 'Warning',
    color: 'warning',
    icon: 'error'
  },
  ERROR: {
    title: 'Error',
    color: 'error',
    icon: 'cancel'
  },
  SUCCESS: {
    title: 'Success',
    color: 'success',
    icon: 'check_circle'
  }
}

export const TOAST_POSITION = {
  rightTop: 'right-top',
  rightBottom: 'right-bottom',
  leftTop: 'left-top',
  leftBottom: 'left-bottom'
}

export const TYPE_CHARTS = {
  DOUGHNUT: 'doughnut',
  LINE: 'line',
  HORIZONTAL_BAR_CHART: 'horizontalBarChart'
}

export const COLUMN_ORDER = {
  ASCENDING: 'asc',
  DESCENDING: 'des'
}

export const FILTER_TYPES = {
  CHECKBOX: 'checkbox',
  DATE_PICKER: 'datePicker',
  TEXT_INPUT: 'textInput',
  NUMBER_INPUT: 'numberInput'
}

export const ROWS_PER_PAGE = [
  { id: 1, label: 'Rows per page: 15', value: 15 },
  { id: 2, label: 'Rows per page: 30', value: 30 },
  { id: 3, label: 'Rows per page: 45', value: 45 }
]

export const MONTHS = [
  { id: 0, name: 'january', label: 'January' },
  { id: 1, name: 'february', label: 'February' },
  { id: 2, name: 'march', label: 'March' },
  { id: 3, name: 'april', label: 'April' },
  { id: 4, name: 'may', label: 'May' },
  { id: 5, name: 'june', label: 'June' },
  { id: 6, name: 'july', label: 'July' },
  { id: 7, name: 'august', label: 'August' },
  { id: 8, name: 'september', label: 'September' },
  { id: 9, name: 'october', label: 'October' },
  { id: 10, name: 'november', label: 'November' },
  { id: 11, name: 'december', label: 'December' }
]

export const TIMEOUT_PENDING_PROCESS = 10000
// MEDIA WIZARD

export const TITLES = {
  PROFILE: 'Profile',
  DASHBOARD: 'WORKSPACE\'s',
  PROJECTS: 'Projects',
  FILE_MANAGER: 'File manager',
  EXAMPLES: 'Samples',
  SIGN_IN: 'Sign In',
  REGISTER: 'Register',
  PASSWORD_RECOVERY: 'Password recovery',
  CONFIRM_PASSWORD: 'Confirm password',
  CHANGE_PASSWORD: 'Change password',
  SETTINGS: 'Settings',
  BILLINGS: 'Billings',
  NEW_WORKSPACE: 'New workspace',
  WORKSPACE: 'Workspace'
}

export const SUBTITLES = {
  CONTACT_DETAILS: 'Contact detail',
  EDIT_PROFILE: 'Edit profile',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  NAME: 'Name',
  SURNAME: 'Surname',
  FULL_NAME: 'Fullname',
  ENTER_NEW_PASSWORD: 'Please enter the new password',
  REPEAT_PASSWORD: 'Repeat password',
  DESCRIPTION: 'Description',
  SEARCH: 'Search',
  API_KEY: 'Api Key',
  SUMMARY: 'Summary',
  COMMAND: 'Command',
  SELECT_FOLDER: 'Select folder',
  OPERATIONS: 'Operations',
  ADD_OPERATIONS: 'Add type of operations',
  VIEWERS: 'Viewers',
  EDITORS: 'Editors'
}

export const TEXT_CONTENT = {
  ENTER_EMAIL: 'Enter email',
  ENTER_PASSWORD: 'Enter password',
  FORGOT_PASSWORD: 'Forgot your password?',
  SIGN_UP: 'Sign up',
  LOGIN: 'Login',
  RECOVERY: 'Recovery',
  RECOVERY_MESSAGE: 'Please enter your email to recover your password',
  REMEMBERED_PASSWORD: 'Remembered your password?',
  BACK_TO_LOGIN: 'Back to login',
  ENTER_NAME: 'Enter name',
  ENTER_SURNAME: 'Enter surname',
  ENTER_NEW_PASSWORD: 'Enter the new password',
  MUST_PASSWORD: 'The password must have at least 8 characters, one uppercase letter, one lowercase letter,'
  + 'one number and one special character.',
  CONFIRM: 'Confirm',
  REMOVE_ACCOUNT: 'Remove account',
  BASIC_DETAILS: 'Basic details',
  SELECT: 'Select',
  LOG_OUT: 'Log out',
  ENTER_DESCRIPTION: 'Enter description',
  ENTER_FILENAME: 'Enter filename',
  UPLOAD: 'Upload',
  SUMMARY_DATA: 'Summary Data',
  REGENERATE_API_KEY: 'Regenerate API key',
  DELETE_WORKSPACE: 'Delete workspace',
  FILES_NOT_FOUND: 'No files found.',
  ENTER_FOLDERNAME: 'Enter folder name',
  CREATE_PROFILE: 'Create profile',
  PROFILES: 'Profiles',
  PROFILES_NOT_FOUND: 'No profiles found.',
  CREATE_OPERATION: 'Create operation',
  OPERATIONS_NOT_FOUND: 'No operations found.',
  UPLOAD_FILE: 'Upload file',
  CREATE_FOLDER: 'Create folder',
  LOADING: 'Loading',
  NO_OPTIONS: 'No options',
  SELECT_USER_SHARE_WITH: 'Select users that you want to share with',
  EDIT_PROFILE: 'Edit media file',
  ADD_OPERATION: 'Add operation',
  REMOVE_OPERATION: 'Remove operation',
  OPERATION_CONFIG: 'Operation configuration',
  DELETE_MEDIA_FILE: 'Delete media file',
  EDIT_MEDIA_FILE: 'Edit media file'
}

export const INITIAL_STATES = {
  INPUT: {
    value: '',
    name: '',
    error: ''
  },
  TOAST: {
    title: '',
    message: '',
    icon: '',
    color: 'primary'
  }
}

export const INPUT_VALUES = {
  FULLNAME: { NAME: 'fullName', LABEL: 'Fullname', PLACEHOLDER: 'Fullname' },
  PROFESSION: { NAME: 'profession', LABEL: 'Profession', PLACEHOLDER: 'Web developer, UX Designer, etc.' },
  EMAIL: { NAME: 'email', LABEL: 'Email', PLACEHOLDER: 'name@mail.com' }
}

export const FUNCTION_TYPE = {
  CREATE: { name: 'create', label: 'Create' },
  ADD: { name: 'add', label: 'Add' },
  EDIT: { name: 'edit', label: 'Edit' },
  DELETE: { name: 'delete', label: 'Delete' },
  SAVE: { name: 'save', label: 'Save' },
  CANCEL: { name: 'cancel', label: 'Cancel' },
  COPY: { name: 'copy', label: 'Copy' },
  DOWNLOAD: { name: 'download', label: 'Download' }
}

export const STATUS_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  PENDING: 'pending'
}

export const THEME_NAME = {
  LIGHT: 'light',
  DARK: 'dark'
}

export const THEME_MODE = 'themeMode'

export const LINE_WIDTH = {
  SLIM: 'slim',
  NORMAL: 'normal',
  WIDE: 'wide'
}

export const COLUMN_TYPE = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
}

export const FLEX_PROPERTIES = {
  DIRECTION: {
    ROW: 'row',
    COLUMN: 'column',
    ROW_REVERSE: 'row-reverse',
    COLUMN_REVERSE: 'column-reverse'
  },
  FLEX_WRAP: {
    WRAP: 'wrap',
    NO_WRAP: 'nowrap'
  },
  ALIGN_CONTENT: {
    NORMAL: 'normal',
    CENTER: 'center',
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end',
    SPACE_AROUND: 'space-around',
    SPACE_BETWEEN: 'space-between',
    STRETCH: 'stretch'
  },
  JUSTIFY_CONTENT: {
    CENTER: 'center',
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end',
    SPACE_AROUND: 'space-around',
    SPACE_BETWEEN: 'space-between',
    SPACE_EVENLY: 'space-evenly'
  },
  ALIGN_ITEMS: {
    CENTER: 'center',
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end',
    STRETCH: 'stretch',
    BASELINE: 'baseline'
  }
}

export const COLOR_OPTIONS = {
  option1: 'option1',
  option2: 'option2'
}

export const NOT_APPLICABLE = 'N/A'

export const MEDIA_STATUS = {
  PUBLIC: { name: 'public', label: 'Public' },
  PRIVATE: { name: 'private', label: 'Private' }
}

export const RADIO_BUTTON_STATUS_OPTIONS = [
  { id: 1, text: MEDIA_STATUS.PUBLIC.label, name: MEDIA_STATUS.PUBLIC.name },
  { id: 2, text: MEDIA_STATUS.PRIVATE.label, name: MEDIA_STATUS.PRIVATE.name }
]

export const BUTTON_TYPES = {
  SOLID: 'solid',
  LINE: 'line',
  GHOST: 'ghost'
}

export const OPERATION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

import produce from 'immer'

import { USER } from './types'

const DATA_USER = 'DU'

export const initialState = {
  loading: false,
  error: false,
  user: null,
  responsePasswordConfirm: { status: 0 },
  errorRefresh: null,
  loadingDataUser: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case USER.GET:
      draft.loading = true
      break
    case USER.SUCCESS:
      draft.loading = false
      draft.user = payload
      break
    case USER.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case USER.ERROR_USER_REFRESH: {
      draft.errorRefresh = payload
      break
    }
    case USER.MESSAGE_PASSWORD_CONFIRM: {
      const { status } = payload
      draft.responsePasswordConfirm = { status }
      break
    }
    case USER.SEND_DATA_USER:
      draft.loadingDataUser = true
      break
    case USER.SEND_DATA_USER_SUCCESS:
      draft.loadingDataUser = false
      window.localStorage.setItem(DATA_USER, true)
      break
    case USER.SEND_DATA_USER_ERROR:
      draft.loadingDataUser = false
      window.localStorage.setItem(DATA_USER, false)
      break
    case USER.CLEAR:
      return initialState
    default:
      break
  }
})

export default reducer

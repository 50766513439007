import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from '@global/constants'
import { DropdownMenuItem } from '@global/styles'

import Icon from 'src/baseComponents/icon'
import { Text } from 'src/baseComponents/texts'
import { useWindowDimensions } from '@components/windowDimensions'

const DropdownItem = ({ id, iconName, label, onClick }) => {
  const { device } = useWindowDimensions()
  return (
    <DropdownMenuItem key={id} color='primary' onClick={onClick}>
      <Icon name={iconName} size='large' />
      <Text
        size={(device === BREAKPOINTS.MOBILE ? 'large' : 'medium')}
        weight='regular'
        align='left'
      >
        {label}
      </Text>
    </DropdownMenuItem>
  )
}

DropdownItem.propTypes = {
  id: PropTypes.number,
  iconName: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default DropdownItem

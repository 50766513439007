import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'

import { Text } from 'src/baseComponents/texts'
import { BASIC_INPUT_TYPES } from '@components/inputs/utils/constants'

import { Column } from '@global/styles'
import { Wrapper, Item, RadioButton, RadioButtonLabel } from './styles'

const RadioInputs = ({ name, title, options, onChange, type, color, justifyContent, alignItems }) => {
  const [select, setSelect] = useState(null)

  const handleSelectChange = ({ target }) => {
    const { value } = target
    setSelect(value)
    onChange(value)
  }

  return (
    <>
      {options?.length > 0
      && (
      <Column>
        {title && <Text size='large' weight='bold' align='center'>{title}</Text>}
        <Wrapper type={type} name={name} justifyContent={justifyContent} alignItems={alignItems}>
          {options.map(({ id, name: nameOp, text }) => (
            <Item key={id}>
              <RadioButton
                type={BASIC_INPUT_TYPES.RADIO}
                id={id}
                name={`radio_group_${nameOp}`}
                value={nameOp}
                checked={select === nameOp}
                onChange={handleSelectChange}
                color={color}
              />
              <RadioButtonLabel />
              <Text size='medium' weight='main'>{text}</Text>
            </Item>
          ))}
        </Wrapper>
      </Column>

      )}
    </>
  )
}

RadioInputs.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.string,
  color: MyPropTypes.allColors,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string
}

export default RadioInputs

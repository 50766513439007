import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonModalContainer, ModalDataContainer } from '@global/styles'
import {
  BREAKPOINTS,
  FUNCTION_TYPE,
  SUBTITLES,
  INITIAL_STATES,
  TOAST_PROPERTIES,
  TOAST_POSITION
} from '@global/constants'
import { spacing } from '@global/theme'

import { useNotification } from 'src/context/notification.context'
import { TextInput } from '@components/inputs/inputs'
import { SolidButton } from '@baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'

import useRedux from '../../redux'
import { WORKSPACE_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const CreateEditWorkspaceProfileModal = ({ oldData, edit, close, successResult, workspaceId }) => {
  useRedux()
  const { device } = useWindowDimensions()
  const { GetToken } = useUser()
  const dispatch = useDispatch()
  const { getNotification } = useNotification()

  const [workspaceProfileName, setWorkspaceProfileName] = useState(
    edit
      ? { ...INITIAL_STATES.INPUT, value: oldData?.name }
      : INITIAL_STATES.INPUT
  )

  const successMessage = useSelector(SELECTORS.SUCCESS_MESSAGE)
  const errorMessage = useSelector(SELECTORS.ERROR_MESSAGE)

  const handleInputChange = val => setWorkspaceProfileName(val)

  const handleClick = async () => {
    const data = {
      name: workspaceProfileName.value,
      workspace: workspaceId,
      oldProfiles: oldData.oldProfiles
    }
    const token = await GetToken()
    if (edit) {
      dispatch(WORKSPACE_PROFILE_ACTIONS.EDIT_WORKSPACE_PROFILE({ token, profileId: oldData.id, data }))
    } else {
      dispatch(WORKSPACE_PROFILE_ACTIONS.CREATE_WORKSPACE_PROFILE({ token, data }))
    }
  }

  useEffect(async () => {
    if (successMessage) {
      const { message, status } = successMessage
      successResult(message || status)
      close()
    }
  }, [successMessage])

  useEffect(async () => {
    if (errorMessage) {
      const { message, status } = errorMessage
      getNotification({
        title: TOAST_PROPERTIES.ERROR.title,
        message: message || status,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color,
        position: TOAST_POSITION.leftTop,
        open: true
      })
      setTimeout(() => {
        dispatch(WORKSPACE_PROFILE_ACTIONS.CLEAR_ERROR_MESSAGE())
      }, 4000)
    }
  }, [errorMessage])

  return (
    <ModalDataContainer flexDirection='column'>
      <TextInput
        name={SUBTITLES.NAME}
        label={SUBTITLES.NAME}
        onChange={handleInputChange}
        color='primary'
        value={workspaceProfileName.value}
      />
      <ButtonModalContainer oneButton marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'large' : 'medium'}
          color='primary'
          text={edit ? FUNCTION_TYPE.EDIT.label : FUNCTION_TYPE.CREATE.label}
          icon='upload'
          onClick={handleClick}
          disabled={edit ? !edit : !workspaceProfileName}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonModalContainer>
    </ModalDataContainer>
  )
}

CreateEditWorkspaceProfileModal.propTypes = {
  edit: PropTypes.bool,
  oldData: PropTypes.object,
  close: PropTypes.func,
  successResult: PropTypes.func,
  workspaceId: PropTypes.string
}

export default CreateEditWorkspaceProfileModal

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, RESOURCE_TYPE } from '@global/constants'
import { Divider } from '@global/styles'
import { spacing } from '@global/theme'

import { CheckboxInput } from '@components/inputs/inputs'
import Icon from 'src/baseComponents/icon'
import { Text } from 'src/baseComponents/texts'

import { WrapperFileCard, HeaderFileCard, BodyFileCard, FooterFileCard } from './styles'

const FileCard = props => {
  const { id, name, size, fileType, items, dateCreated, onChange, device, moreIconClick, onClick, extension } = props
  const date = new Date(dateCreated).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
  const fileIcon = RESOURCE_TYPE[fileType.toUpperCase()]?.icon

  const [checked, setChecked] = useState({
    name: 'checkbox',
    value: false
  })

  const handleChange = val => {
    setChecked(val)
    onChange(id, val.value)
  }

  const optimizedFileName = (fileName, maxLetters) => {
    if (!fileName) return ''
    if (fileName?.length <= maxLetters) return fileName
    const splitName = fileName?.split('.')
    const shortName = splitName[0]?.substring(0, maxLetters - 5 - splitName[1]?.length)
    return `${shortName}... .${splitName[1]}`
  }

  const handleClick = () => {
    onClick(id, fileType)
  }

  return (
    <>
      <WrapperFileCard device={device} onClick={handleClick}>
        <HeaderFileCard>
          <CheckboxInput
            name={id}
            key={id}
            value={checked.value}
            color='primary'
            onChange={handleChange}
            noText
          />
          <Icon id={id} name='more_vert' size='medium' onClick={moreIconClick} />
        </HeaderFileCard>
        <BodyFileCard>
          <Icon
            name={fileIcon}
            size='hmedium'
            color={fileType === RESOURCE_TYPE.FOLDER.name ? 'warning' : 'primary'}
          />
          <Text
            size={(device === BREAKPOINTS.MOBILE ? 'large' : 'medium')}
            weight='bold'
            align='left'
          >
            {name ? optimizedFileName(name, 16) : ''}
          </Text>
          <Divider margin={`${spacing.one} 0`} />
        </BodyFileCard>
        <FooterFileCard>
          {fileType !== RESOURCE_TYPE.FOLDER.name
          && (
          <Text
            size={(device === BREAKPOINTS.MOBILE ? 'medium' : 'medium')}
            weight='main'
            align='left'
          >
            {extension}
          </Text>
          )}
          <Text
            size={(device === BREAKPOINTS.MOBILE ? 'medium' : 'medium')}
            weight='main'
            align='left'
          >
            {`${size} MB`}
            {items > 0 && fileType === RESOURCE_TYPE.FOLDER.name ? ` • ${items} ${items === 1 ? 'Item' : 'Items'}` : ''}
          </Text>
          <Text
            size={(device === BREAKPOINTS.MOBILE ? 'medium' : 'medium')}
            weight='main'
            align='left'
          >
            {date}
          </Text>
        </FooterFileCard>
      </WrapperFileCard>
    </>
  )
}

FileCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  size: PropTypes.number,
  onChange: PropTypes.func,
  moreIconClick: PropTypes.func,
  device: PropTypes.number,
  fileType: PropTypes.string,
  items: PropTypes.number,
  dateCreated: PropTypes.string,
  onClick: PropTypes.func,
  extension: PropTypes.string
}

export default FileCard

import React from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'
import { LINE_WIDTH } from '@global/constants'

import { Text } from 'src/baseComponents/texts'

import { LoaderContainer, Wrapper, CircularProgress, ValueContainer } from './styles'

const CircularProgressBar = props => {
  const { progress, width, height, textColor, fullLoadcolor, emptyLoadColor, background, lineWidth } = props
  return (
    <LoaderContainer name='loaderContainer' height={height}>
      <Wrapper width={width} height={height}>
        <CircularProgress
          fullLoadcolor={fullLoadcolor}
          backgroundColor={background}
          emptyLoadColor={emptyLoadColor}
          progressValue={progress}
          lineWidth={lineWidth}
        >
          <ValueContainer>
            <Text size='big' weight='bold' align='left' color={textColor}>{progress}%</Text>
          </ValueContainer>
        </CircularProgress>
      </Wrapper>
    </LoaderContainer>
  )
}

CircularProgressBar.defaultProps = {
  progress: 0
}

CircularProgressBar.propTypes = {
  progress: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  textColor: MyPropTypes.allColors,
  fullLoadcolor: MyPropTypes.allColors,
  emptyLoadColor: MyPropTypes.allColors,
  background: MyPropTypes.allColors,
  lineWidth: PropTypes.oneOf([
    LINE_WIDTH.SLIM,
    LINE_WIDTH.NORMAL,
    LINE_WIDTH.WIDE
  ])
}

export default CircularProgressBar

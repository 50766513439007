import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'

import { TabsWrapper, Tab } from './styles'

const Tabs = props => {
  const { tapList, columnStart, columnEnd, rowStart, rowEnd, onChange, color } = props
  const [active, setActive] = useState(1)

  const handleChange = id => {
    setActive(id)
    onChange(id)
  }

  return (
    <TabsWrapper
      columnStart={columnStart}
      columnEnd={columnEnd}
      rowStart={rowStart}
      rowEnd={rowEnd}
    >
      {tapList.map(({ id, text }) => (
        <Tab
          name={`tab-${id}`}
          key={id}
          color={color}
          isActive={id === active}
          onClick={() => handleChange(id)}
        >
          {text}
        </Tab>
      ))}
    </TabsWrapper>
  )
}

Tabs.propTypes = {
  tapList: PropTypes.array,
  columnStart: PropTypes.number,
  columnEnd: PropTypes.number,
  rowStart: PropTypes.number,
  rowEnd: PropTypes.number,
  onChange: PropTypes.func,
  color: MyPropTypes.allColors
}

export default Tabs

import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectDashboardManager = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectDashboardManager, state => get(state, 'loading', null)
)

const selectWorkspaces = createSelector(
  selectDashboardManager, state => get(state, 'workspaceList', null)
)

const selectErrorGetMenuData = createSelector(
  selectDashboardManager, state => get(state, 'error', null)
)

const selectLoadingWorkspaces = createSelector(
  selectDashboardManager, state => get(state, 'loadingWorkspace', null)
)

const selectWorkspace = createSelector(
  selectDashboardManager, state => get(state, 'workspace', null)
)

const selectErrorMessage = createSelector(
  selectDashboardManager, state => get(state, 'errorMessage', null)
)

const selectLoadingWorkspaceList = createSelector(
  selectDashboardManager, state => get(state, 'loadingWorkspaceList', null)
)

const selectOperationList = createSelector(
  selectDashboardManager, state => get(state, 'operationList', null)
)

const selectLoadingOperationList = createSelector(
  selectDashboardManager, state => get(state, 'loadingOperationList', null)
)

export default {
  LOADING: selectLoading,
  WORKSPACES_SUCCESS: selectWorkspaces,
  LOADING_WORKSPACES: selectLoadingWorkspaces,
  ERROR: selectErrorGetMenuData,
  WORKSPACE_SUCCESS: selectWorkspace,
  ERROR_MESSAGE: selectErrorMessage,
  LOADING_WORKSPACE_LIST: selectLoadingWorkspaceList,
  OPERATION_LIST: selectOperationList,
  LOADING_OPERATION_LIST: selectLoadingOperationList
}

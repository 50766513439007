import styled from 'styled-components'

import { SourceCodePro } from '@global/fonts'
import { colors, spacing } from '@global/theme'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'
import { boxShadow, borderDark } from '@global/styles'

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: ${spacing.one};
  border-radius: ${spacing.one};
  margin: ${spacing.half} 0;
  background-color: ${({ isActive }) => (isActive ? colors.primary.main : null)};
  ${SourceCodePro};
  position: relative;

  & > :first-child {
    margin-right: ${spacing.two};
  }

  & > :nth-child(3) {
    position: absolute;
    top: ${spacing.one};
    right: ${spacing.one};
  }

  &:hover {
    background-color: ${colors.primary.dark};
    cursor: pointer;
    & > p, span {
       color: ${colors.background.main}; 
    }
  }

  & > p, span {
       color:  ${({ isActive }) => (isActive ? colors.background.main : null)};
    }
`

export const MenuContainer = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.main};
  ${({ theme }) => (theme.name === THEME_NAME.LIGHT && boxShadow)};
  ${({ theme }) => (theme.name === THEME_NAME.DARK && borderDark)};
  border-radius: ${spacing.one};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.TABLET ? 'row' : 'column')};
  flex-wrap: ${({ device }) => (device === BREAKPOINTS.TABLET ? 'nowrap' : 'wrap')};
  padding: ${spacing.oneAndAHalf} ${spacing.oneAndAHalf} ${spacing.four}  ${spacing.oneAndAHalf};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  overflow: auto;
  grid-column-start: 1;
  grid-column-end: ${({ device }) => (device >= BREAKPOINTS.DESKTOP ? '4' : '13')};
  grid-row-start: 3;
  grid-row-end: 3;
`

export const FileManagerContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${spacing.four};

  grid-column-start: ${({ columnStart }) => columnStart || 1};
  grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  grid-row-start: ${({ rowStart }) => rowStart || 1};
  grid-row-end: ${({ rowEnd }) => rowEnd || 1};
`

export const FileManagerFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'column' : 'row')};
  width: 100%;

  & > :first-child {
    margin-right: ${({ device }) => (device < BREAKPOINTS.TABLET ? 0 : spacing.two)};
    flex: 2;
  }

  & > :last-child {
    flex: 1;
  }

`

export const FilesCardContainer = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  gap: 12px;
  width: 100%;
`

export const TagIcon = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color ?? colors.text.main};
  outline:  ${({ isActive }) => (isActive ? `2px solid ${colors.background.main}` : null)};
`

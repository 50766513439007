import React from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'

import { Text } from 'src/baseComponents/texts'
import FileItem from '../fileItem'
import { FileItemsContainer } from './styles'

const AcceptedFilesComponent = ({ acceptedFiles, text, loading, removeFile, device, color }) => acceptedFiles.length > 0
      && (
      <>
        <Text size='medium' weight='regular' align='left'>
          {`${text} ${acceptedFiles.length}`}
        </Text>
        <FileItemsContainer name='fileItemsContainer' color={color}>
          {acceptedFiles.map(f => (
            <FileItem
              key={f.id}
              name={f.name}
              logo={f.logo}
              loading={loading}
              cancelFileaUpload={() => removeFile(f.id)}
              device={device}
              color={color}
            />
          ))}
        </FileItemsContainer>
      </>
      )

AcceptedFilesComponent.propTypes = {
  acceptedFiles: PropTypes.array,
  text: PropTypes.string,
  loading: PropTypes.bool,
  removeFile: PropTypes.func,
  device: PropTypes.number,
  color: MyPropTypes.allColors
}

export default AcceptedFilesComponent

import { useState, useEffect } from 'react'
import { PADDING_HORIZONTAL, PADDING_HORIZONTAL_DESKTOP, PADDING_HORIZONTAL_BIG_SCREEN } from '@routes/constants'

import { BREAKPOINTS } from '@global/constants'

const usePageWidth = () => {
  const size = document.getElementById('page')?.clientWidth || 1200
  const [width, setWidth] = useState(size)
  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById('container')
      const page = document.getElementById('page')
      let padding = PADDING_HORIZONTAL
      if (container.clientWidth === BREAKPOINTS.DESKTOP) padding = PADDING_HORIZONTAL_DESKTOP
      if (container.clientWidth === BREAKPOINTS.BIG_SCREEN) padding = PADDING_HORIZONTAL_BIG_SCREEN

      setWidth(page.clientWidth - padding.slice(0, padding.length - 2))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return width
}

export default usePageWidth

import styled from 'styled-components'
import { spacing } from '@global/theme'
import { SourceCodePro, bold } from '@global/fonts'
import { Wrapper } from '@global/styles'
import { THEME_NAME } from '@global/constants'

export const TabsWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  border-bottom: ${({ theme }) => {
    const currentColor = theme.name === THEME_NAME.DARK
      ? theme.colors.grays.main
      : theme.colors.grays.light
    return `1px solid ${currentColor}`
  }};
  padding-bottom: ${spacing.quarter};
`

export const Tab = styled.div`
  background: ${({ theme, isActive }) => (isActive ? theme.colors.background.variant : 'transparent')};
  text-align: center;
  color: ${({ theme }) => theme.colors.text.content};
  border-bottom: ${({ theme, isActive }) => {
    const currentColor = isActive
      ? theme.colors.text.content
      : theme.colors.background.main
    return `3px solid ${currentColor}`
  }};
  ${SourceCodePro};
  padding: ${spacing.two};
  cursor: pointer;
  ${({ isActive }) => isActive && bold};
  &:active {
    background: ${({ color, theme }) => (theme.colors.background.variant || theme.colors[color]?.hover)}!important;
  }
  &:hover {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.dark
    return theme.colors.grays.light
  }};
  }
`

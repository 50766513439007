import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useClickOutside } from '@utils/hooks'

import { DrowdownMenuStyled } from './styles'

const DropdownMenuFloat = props => {
  const {
    clientX,
    clientY,
    visible,
    close,
    widthSize,
    heightSize,
    padding,
    children
  } = props

  const dropdownMenuRef = useRef()

  useClickOutside(dropdownMenuRef, () => close({ clientX, clientY, widthSize }))

  return (
    <DrowdownMenuStyled
      ref={dropdownMenuRef}
      visible={visible}
      clientX={clientX}
      clientY={clientY}
      widthSize={widthSize}
      heightSize={heightSize}
      padding={padding}
    >
      {children}
    </DrowdownMenuStyled>
  )
}

DropdownMenuFloat.defaultProps = {
  visible: false
}

DropdownMenuFloat.propTypes = {
  clientX: PropTypes.number,
  clientY: PropTypes.number,
  visible: PropTypes.bool,
  close: PropTypes.func,
  widthSize: PropTypes.number,
  heightSize: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node
}

export default DropdownMenuFloat

import React from 'react'
import PropTypes from 'prop-types'

import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'

import { Text } from '@baseComponents/texts'
import Icon from '@baseComponents/icon'

import { FormMenuItemStyle, ListItem, ListItemWrapper } from '../styles'
import { PROFILE_MENU_ITEMS } from '../../data'

const BodyMenuForm = ({ userId, close }) => (
  <FormMenuItemStyle padding={spacing.one}>
    {
      PROFILE_MENU_ITEMS.map(({ id, label, icon, url }) => (
        <ListItemWrapper onClick={() => close()}>
          <ListItem key={id} to={url === SITE.PROFILE ? `${url}/${userId}` : url}>
            <Icon name={icon} size='large' />
            <Text text={label} size='medium' />
          </ListItem>
        </ListItemWrapper>
      ))
    }
  </FormMenuItemStyle>
)

BodyMenuForm.propTypes = {
  userId: PropTypes.string,
  close: PropTypes.func
}

export default BodyMenuForm

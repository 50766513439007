import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@baseComponents/texts'

import { FormMenuItemStyle } from '../styles'

const HeaderMenuForm = ({ userName, userEmail }) => (
  <FormMenuItemStyle>
    <Text text={userName || 'user'} align='left' size='large' weight='bold' />
    <Text text={userEmail || 'email'} align='left' size='medium' />
  </FormMenuItemStyle>
)

HeaderMenuForm.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string
}

export default HeaderMenuForm

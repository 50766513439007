import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'

import { COLUMN_ORDER } from '@global/constants'
import { MESSAGES } from '@global/message'
import { formatDateBasic } from '@utils/helpers'
import { useSize } from '@utils/hooks'

import { Text } from 'src/baseComponents/texts'

import Icon from 'src/baseComponents/icon'
import Loading from '@components/loading'

import { useWindowDimensions } from '@components/windowDimensions'
import usePageWidth from './utils/hook'

import {
  TableStyled,
  Head,
  Body,
  Tr,
  Th,
  Td,
  ColumnTitle,
  LoadingContainer,
  TextContainer,
  TableChildrenContainer
} from './styles'

const Table = ({ list, columns, loading, handleOrder, rowClick, error, children, showRowPointer, color }) => {
  const width = usePageWidth()
  const { device } = useWindowDimensions()

  const nav = document.querySelector('nav')
  const [target, currentSize] = useSize()
  const { width: widthElement } = currentSize

  const [tableList, setTableList] = useState([])
  const [widthTable, setWidthTable] = useState()
  const [selectedColumn, setSelectedColumn] = useState({
    order: '',
    icon: '',
    columnId: null
  })
  const [flat, setFlat] = useState(false)

  const handleColunmClick = (columnName, colId) => {
    if (!handleOrder) return
    let newSelCol = {}
    if (!selectedColumn.columnId || selectedColumn.columnId !== colId) {
      newSelCol = {
        columnId: colId,
        order: COLUMN_ORDER.DESCENDING,
        icon: 'expand_more'
      }
    } else {
      newSelCol = {
        ...selectedColumn,
        order: selectedColumn.order === COLUMN_ORDER.DESCENDING ? COLUMN_ORDER.ASCENDING : COLUMN_ORDER.DESCENDING,
        icon: selectedColumn.icon === 'expand_more' ? 'expand_less' : 'expand_more'
      }
    }
    setSelectedColumn(newSelCol)
    handleOrder(columnName, newSelCol.order === COLUMN_ORDER.DESCENDING)
  }

  const handleRowClick = rowData => {
    if (rowClick) rowClick(rowData)
  }

  useEffect(() => {
    setTableList(list)
  }, [list])

  useEffect(() => {
    setSelectedColumn({
      order: '',
      icon: 'expand_more',
      columnId: null
    })
  }, [error])

  useEffect(() => {
    target(nav)
    setWidthTable(width)
  }, [])

  useEffect(() => {
    if (widthElement) {
      if (!flat) {
        setFlat(true)
      }
      setWidthTable(document.getElementById('page').clientWidth)
    }
  }, [widthElement])

  useEffect(() => {
    setWidthTable(width)
  }, [width])

  return (
    error || list.length < 1
      ? (
        <TextContainer width={widthTable}>
          <Text size='large' weight='bold' align='center'>{MESSAGES.NO_DATA}</Text>
        </TextContainer>
      )
      : (
        <TableStyled width={widthTable} device={device}>
          <Head>
            <Tr type='header'>
              {columns.length > 0
            && columns.map(({ name, widthCol }, index) => (
              <Th
                key={name}
                id={index}
                name={name}
                onClick={() => handleColunmClick(name, index + 1)}
                widthTh={widthCol}
                tableWidth={width}
              >
                {typeof name === 'string'
                  ? (
                    <ColumnTitle key={name} color={color} selectedColumn={selectedColumn.columnId === index + 1}>
                      <Text
                        size='medium'
                        align='left'
                        weight='semibold'
                      >
                        {name}
                      </Text>
                      <Icon
                        name={(selectedColumn.columnId === index + 1 ? selectedColumn.icon : 'expand_more')}
                        size='medium'
                      />
                    </ColumnTitle>
                  )
                  : <ColumnTitle key={index}>{name}</ColumnTitle>}
              </Th>
            ))}
            </Tr>
          </Head>
          { loading
            ? (
              <LoadingContainer width={widthTable}>
                <Loading color={color} size='xsmall' weight='bold' />
              </LoadingContainer>
            )
            : (
              <Body>
                {list.length > 0
              && tableList.map((obj, index) => (
                <Tr
                  key={obj.id}
                  id={obj.id}
                  index={index}
                  onClick={() => handleRowClick(obj)}
                  boolOnClick={showRowPointer}
                >
                  {Object.entries(obj).map(person => {
                    const key = person[0]
                    let value = person[1]
                    if (key === 'id') return
                    if (key.toLowerCase().includes('date')) {
                      const isValidDate = Date.parse(value)
                      if (isValidDate) {
                        value = formatDateBasic(value, 'short', false, false, true)
                      }
                    }
                    if (!Number.isNaN(value) && (typeof value === 'number')) value = value.toString()
                    return (
                      <Td key={key} index={index} value={value}>
                        { typeof key === 'string'
                          ? (
                            <Text
                              size='medium'
                              align='left'
                              weight='regular'
                            >
                              {value || 'N/A'}
                            </Text>
                          )
                          : (
                            value
                          )}
                      </Td>
                    )
                  })}
                </Tr>
              ))}
              </Body>
            )}
          {children
           && (
           <TableChildrenContainer>
             { children }
           </TableChildrenContainer>
           )}
        </TableStyled>
      )
  )
}

Table.defaultProps = {
  rowClick: null
}

Table.propTypes = {
  list: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  handleOrder: PropTypes.func,
  rowClick: PropTypes.func,
  error: PropTypes.bool,
  showRowPointer: PropTypes.bool,
  children: PropTypes.element,
  color: MyPropTypes.allColors
}

export default Table

import styled from 'styled-components'
import { colors, spacing } from '@global/theme'
import { SIZE, WEIGHT, SourceCodePro } from '@global/fonts'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const StyledUploader = styled.div`
${SourceCodePro};
position: relative;
display: inline-block;
padding: ${spacing.two} 0;
box-sizing:border-box; 

height:auto;
width:100%;

color: ${colors.text.main};
text-align: center;

display:flex;
justify-content: center;
align-items: center;
flex-direction:column;

background-color: ${({ theme }) => theme.colors.background.main};
border-radius: 5px;

overflow: 'hidden';

> span {
    position: absolute;

    width:80%;
    height:auto;
    
    padding: 0;
    margin: 0;

    
    font-size:${SIZE.MEDIUM};
    font-weight:${WEIGHT.SEMIBOLD};
    opacity: ${({ opacity }) => (opacity ? 1 : 0)};
}
`
export const StyledInputFile = styled.input`
position: absolute;
opacity: 0;

height:100%;
width:100%;

top: 0;
left: 0;
right: 0;
bottom: 0;

cursor: pointer;

display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

export const StyledThumb = styled.div`

width:100%;
height:100%;
display:flex;
justify-content: center;
align-items: center;

/* border-radius: 5px;
overflow: hidden;
background-color: #cccccc;
background-size: cover;
position: relative;
opacity:${({ opacity }) => (!opacity ? 1 : 0)};

&::after {
    content: attr(name);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    color: ${colors.background.main};
    background: rgba(0,0,0,0.5);
    font-size:${SIZE.MEDIUM};
    text-align: center;
} */
`

export const TextContainer = styled.div`
 width: 100%;
 display:flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 & > span {
     margin-bottom: ${spacing.two};
 }
`

export const DropzoneContainer = styled.div`
 position: relative;
 width: 100%;
 display:flex;
 justify-content: center;
 align-items: center;
 border: 1px dashed ${colors.grays.light};
 padding: ${spacing.two};
 background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.variant
    return theme.colors.grays.main
  }};
 margin-bottom: ${spacing.one};
 padding: ${spacing.one};
`

export const FakeButton = styled.div`
 display:flex;
 justify-content: center;
 align-items: center;
 flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
 padding: ${spacing.one};
 margin-bottom: ${spacing.one};
 background-color: ${({ theme, color }) => (color ? theme.colors[color].main : theme.colors.primary.main)};

 & > p, span {
    color: ${({ theme }) => theme.colors.text.white};
 }
`

export const FileItemsContainer = styled.div`
 display: flex;
 justify-content: flex-start;
 align-items: center;
 flex-direction: column;
 width: 100%;
 max-height: 200px;
 margin: ${spacing.one} 0;
 overflow-y: auto;
`

export const ErrorTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: ${spacing.one} 0;
  background-color: ${colors.error.light};
  border: 2px solid ${colors.grays.light};
  padding: ${spacing.one};
`

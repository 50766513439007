import styled from 'styled-components'
import { BREAKPOINTS } from '@global/constants'
import { spacing } from '@global/theme'

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device >= BREAKPOINTS.TABLET ? 'row' : 'column')};
  align-items: ${({ device }) => (device >= BREAKPOINTS.TABLET ? 'center' : 'flex-end')};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  margin-top: ${({ marginTop }) => marginTop || 0};
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};

  & > :first-child {
    margin-right: ${({ device }) => (device >= BREAKPOINTS.TABLET ? spacing.two : 0)};
    margin-bottom: ${({ device }) => (device >= BREAKPOINTS.TABLET ? 0 : spacing.one)};
  }
`

export const AvatarsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

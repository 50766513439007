import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { SITE } from '@routes/paths'
import { USER_ACTIONS } from '../redux/action'
import SELECTORS from '../redux/selectors'
import useRedux from '../redux'

export const useUser = () => {
  useRedux()
  const dispatch = useDispatch()
  const history = useHistory()
  const { logout, getAccessTokenSilently } = useAuth0()
  const user = useSelector(SELECTORS.SUCCESS)

  const GetUser = () => {
    if (!user) {
      const userLocalStorage = JSON.parse(localStorage.getItem('user'))
      return userLocalStorage
    }
    return user
  }

  const GetToken = async () => {
    const auth0Token = await getAccessTokenSilently({
      authorizationParams: {
        scope: 'offline_access openid profile email'
      }
    })
    return auth0Token
  }

  const Logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('authTokens')
    logout()
    setTimeout(() => {
      dispatch(USER_ACTIONS.CLEAR())
      history.push(SITE.SIGN_IN)
    }, 1500)
  }

  return {
    GetUser,
    GetToken,
    Logout
  }
}

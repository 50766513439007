import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { APP_NAME, BREAKPOINTS, TITLES } from '@global/constants'
import logo from '@assets/mw310x310.png'
import { SITE } from '@routes/paths'

import { useUser } from '@containers/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import useRedux from '@containers/dashboard/redux'
import SELECTORS from '@containers/dashboard/redux/selectors'

import { MyNav } from './styles'
import { navItems } from './data'
import NavHeader from './components/header'
import NavBody from './components/body'

export const Nav = ({ user, color }, ref) => {
  useRedux()
  const { GetUser } = useUser()
  const userControl = GetUser()
  const { device } = useWindowDimensions()
  const { pathname } = useLocation()

  const [sidebar, setSidebar] = useState(false)
  const [menusItems, setMenusItems] = useState(navItems)
  const [workspaces, setWorkspaces] = useState([])

  const workspacesData = useSelector(SELECTORS.WORKSPACES_SUCCESS)

  useImperativeHandle(ref, () => ({
    showNavFromParent: () => setSidebar(true)
  }))

  const closeNav = () => {
    if (device === BREAKPOINTS.MOBILE) setSidebar(false)
  }

  useEffect(() => {
    if (device >= BREAKPOINTS.DESKTOP) setSidebar(true)
    else setSidebar(false)
  }, [device])

  useEffect(() => {
    if (workspacesData) {
      const workspacesMapped = workspacesData.results.map(ws => ({
        id: ws.id,
        label: ws.name,
        icon: 'topic',
        to: `${SITE.DASHBOARD}${SITE.WORKSPACE}/${ws?.id}`
      }))
      setWorkspaces(workspacesMapped)
    }
  }, [workspacesData])

  useEffect(() => {
    if (workspaces?.length > 0) {
      const menuItemsMapped = menusItems?.map(mi => {
        if (mi.label === TITLES.PROJECTS) return { ...mi, subMenus: workspaces }
        return mi
      })
      setMenusItems(menuItemsMapped)
    }
  }, [workspaces])

  if (!user || !userControl) return null

  return (
    <MyNav device={device} sidebar={sidebar} name='sidebar' color={color}>
      <NavHeader
        imgLogo={logo}
        title={APP_NAME}
        collapse={sidebar}
        close={setSidebar}
        device={device}
      />
      <NavBody
        navItemList={menusItems}
        collapse={sidebar}
        device={device}
        hideNav={closeNav}
        pathname={pathname}
        user={user}
      />
    </MyNav>
  )
}

Nav.propTypes = {
  user: PropTypes.object,
  color: MyPropTypes.allColors
}

export default forwardRef(Nav)

/* stylelint-disable no-descending-specificity */
import styled, { css } from 'styled-components'
import { spacing } from '@global/theme'
import { THEME_NAME } from '@global/constants'

const Small = css`
  height: ${spacing.threeAndAHalf};
  padding: ${spacing.one} ${spacing.two};

  min-width: ${({ fixWidth }) => (fixWidth ? spacing.eight : '')};
`

const Medium = css`
  height: ${spacing.five};
  padding: ${spacing.one} ${spacing.two};

  min-width: ${({ fixWidth }) => (fixWidth ? spacing.eleven : '')};
`

const Large = css`
  height: ${spacing.fiveAndAHalf};
  padding: ${spacing.oneAndAHalf} ${spacing.twoAndAHalf};

  min-width: ${({ fixWidth }) => (fixWidth ? spacing.fifteen : '')};
`

const Solid = css`
  background-color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);

  > * {
    color: ${({ theme }) => (theme.colors.text.white)}!important;
  }

  &:disabled {
    cursor: inherit;
    box-shadow: none;
    background-color: ${({ theme }) => (theme.colors.text.disabled ?? theme.colors.primary.disabled)};
    > * {
      color: ${({ theme }) => (theme.colors.grays.light)};
    }
  }

  &:hover:enabled {
    background-color: ${({ color, theme }) => (theme.colors[color]?.hover ?? theme.colors.primary.hover)};
  }

  &:focus {
    background-color: ${({ color, theme }) => (theme.colors[color]?.focus ?? theme.colors.primary.focus)};
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.12);
  }

  &:active:enabled {
    background-color: ${({ color, theme }) => (theme.colors[color]?.selected ?? theme.colors.primary.selected)};
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.12);
  }
`

const Line = css`
  background-color: transparent;
  border: 2px solid ${({ theme, color }) => (color ? theme.colors[color]?.main : theme.colors.primary.main)};

  > * {
    color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.text.main)}!important;
  }

  > span {
    color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)}!important;
  }

  &:disabled {
    cursor: inherit;
    box-shadow: none;
    background-color: ${({ theme }) => (theme.colors.grays.disabled)};
    border: none;
    > * {
      color: ${({ theme }) => (theme.colors.text.disabled)};
    }
  }

  &:hover:enabled {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.dark
    return theme.colors.grays.light
  }};
      
    border-color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    
    > * {
      color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    }
  }

  &:focus {
    background-color: ${({ theme }) => (theme.colors.background.main)};
    border-color: ${({ color, theme }) => (theme.colors[color]?.focus ?? theme.colors.primary.focus)};

    > * {
      color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    }
  }

  &:active:enabled {
    background-color: ${({ color, theme }) => (theme.colors[color]?.dark ?? theme.colors.primary.dark)};
    border: 2px solid ${({ theme, color }) => (color ? theme.colors[color]?.main : theme.colors.primary.main)};
    outline: none;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.12);
    > * {
      color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    }
  }
`

const Ghost = css`
  background-color:  transparent;
  > * {
    color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
  }

  > span {
    color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)}!important;
  }
  &:disabled {
    cursor: inherit;
    box-shadow: none;
    background-color: ${({ theme }) => (theme.colors.background.disabled)};
    border-color: ${({ theme }) => (theme.colors.grays.disabled)};
    > * {
      color: ${({ theme }) => (theme.colors.text.disabled)};
    }
  }

  &:hover:enabled {
  
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.dark
    return theme.colors.grays.light
  }};
    
    > * {
      color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    }
  }

  &:focus {
    border-color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    > * {
      color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    }
  }

  &:active:enabled {
    border: 1px solid ${({ color, theme }) => (theme.colors[color]?.selected ?? theme.colors.primary.selected)};
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.12);
    > * {
      color: ${({ color, theme }) => (theme.colors[color]?.main ?? theme.colors.primary.main)};
    }
  }
`

export const StyledButton = styled.button`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: center;
  border-radius: ${({ rounded }) => (rounded ? spacing.four : spacing.quarter)};
  border: none;

  cursor: pointer;
  width: ${({ block }) => (block ? '100%' : 'max-content')};

  ${({ size }) => {
    switch (size) {
      case 'small':
        return Small
      case 'large':
        return Large
      default:
        return Medium
    }
  }};

  ${({ type }) => {
    switch (type) {
      case 'line': return Line
      case 'ghost': return Ghost
      default: return Solid
    }
  }};

  > :not(:last-child) {
    ${({ reverse }) => (reverse ? `margin-left: ${spacing.one}` : `margin-right: ${spacing.one}`)};
  }

  transition: background-color 250ms;

  text-overflow: ellipsis;
  overflow: hidden;

  margin: ${({ margin }) => margin || 0};
`

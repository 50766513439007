export const BANNER_DATA = {
  TITLE: 'Welcome to Media Wizard',
  // eslint-disable-next-line max-len
  TEXT: 'Laborum aute laboris consectetur quis cupidatat eiusmod nulla. Anim enim Lorem enim veniam eu minim. Incididunt consequat velit officia commodo nostrud cillum ullamco sunt culpa non velit mollit. Magna anim ea nulla deserunt sint quis occaecat aliquip commodo aliquip. Nisi incididunt dolor veniam quis id adipisicing. Exercitation sit reprehenderit proident magna dolore nisi nisi.'
}

export const OPTIONS_FILES = [
  {
    id: 1,
    label: '.jpg'
  },
  {
    id: 2,
    label: '.png'
  },
  {
    id: 3,
    label: '.mp3'
  }
]

export const OPTIONS_USERS = [
  {
    id: 1,
    label: 'Bruno Diaz'
  },
  {
    id: 2,
    label: 'Peter Parker'
  },
  {
    id: 3,
    label: 'Clark Kent'
  }
]

export const MOCK_OPERATIONS = {
  status: 200,
  data: [
    {
      value: 1,
      label: '30x30.png'
    },
    {
      value: 2,
      label: '640x480.jpeg'
    },
    {
      value: 3,
      label: '1280x800.jpeg'
    },
    {
      value: 4,
      label: '50x50.png'
    },
    {
      value: 5,
      label: '1920x1080.jpeg'
    },
    {
      value: 6,
      label: '1366x940.jpeg'
    }
  ] }

import styled from 'styled-components'
import { spacing } from '@global/theme'

export const WorkSpacesContainer = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${spacing.four};
  grid-row-gap: ${spacing.four};
  gap: ${spacing.three};
  width: 100%;
`

export const imageAdapter = image => ({
  id: image.id,
  image: image.image,
  specs: image.specs,
  sha1: image.sha1,
  name: image.name,
  description: image.description,
  createdAt: image.created_at,
  updatedAt: image.updated_at,
  metadata: image.metadata,
  width: image.width,
  height: image.height,
  size: image.size,
  extension: image.extension,
  folder: image.folder,
  original: image.original,
  process: image.process,
  owner: image.owner,
  workspace: image.workspace
})

import React from 'react'
import PropTypes from 'prop-types'

import { Box, Column, MyButtonLink, Row } from '@global/styles'
import { FLEX_PROPERTIES, FUNCTION_TYPE } from '@global/constants'
import { spacing } from '@global/theme'

import { Text } from '@baseComponents/texts'

import { MediaFileCardContainer } from './styles'
import ImageZoomInOut from '../imageZoomInOut'

const MediaFileCard = ({ rowGrid, columnStart, columnEnd, size, extension, name, imageUrl, handleEdit, device }) => {
  const handleEditImage = () => {
    handleEdit()
  }

  return (
    <MediaFileCardContainer rowGrid={rowGrid} columnStart={columnStart} columnEnd={columnEnd} device={device}>
      <ImageZoomInOut
        imageUrl={imageUrl}
        name={name}
      />
      <Column width='100%' justifyContent={FLEX_PROPERTIES.JUSTIFY_CONTENT.SPACE_BETWEEN}>
        <Text text={`${size}MB ${extension?.toUpperCase()}`} size='large' />
        <Box heightBox={spacing.two} />
        <Row width='100%' justifyContent={FLEX_PROPERTIES.JUSTIFY_CONTENT.SPACE_BETWEEN}>
          <Text text='Bucket: Images' size='large' />
          <MyButtonLink onClick={handleEditImage} color='text' underline>
            {FUNCTION_TYPE.EDIT.label}
          </MyButtonLink>
        </Row>
      </Column>
    </MediaFileCardContainer>
  )
}

MediaFileCard.propTypes = {
  rowGrid: PropTypes.number,
  columnStart: PropTypes.number,
  columnEnd: PropTypes.number,
  size: PropTypes.number,
  extension: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  handleEdit: PropTypes.func,
  device: PropTypes.number
}
export default MediaFileCard

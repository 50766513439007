import { WORKSPACE_PROFILE } from './types'

export const loading = () => ({
  type: WORKSPACE_PROFILE.LOADING
})

export const loadingFalse = () => ({
  type: WORKSPACE_PROFILE.LOADING_FALSE
})

export const getWorkspaceProfiles = payload => ({
  type: WORKSPACE_PROFILE.GET_WORKSPACE_PROFILES,
  payload
})

export const workspaceProfilesSuccess = payload => ({
  type: WORKSPACE_PROFILE.WORKSPACE_PROFILES_SUCCESS,
  payload
})

export const createWorkspaceProfile = payload => ({
  type: WORKSPACE_PROFILE.CREATE_WORKSPACE_PROFILE,
  payload
})

export const editWorkspaceProfile = payload => ({
  type: WORKSPACE_PROFILE.EDIT_WORKSPACE_PROFILE,
  payload
})

export const removeWorkspaceProfile = payload => ({
  type: WORKSPACE_PROFILE.REMOVE_WORKSPACE_PROFILE,
  payload
})

// OPERATIONS
export const getProfileOperations = payload => ({
  type: WORKSPACE_PROFILE.GET_PROFILE_OPERATION,
  payload
})

export const profileOperationsSuccess = payload => ({
  type: WORKSPACE_PROFILE.PROFILE_OPERATION_SUCCESS,
  payload
})

export const removeProfileOperation = payload => ({
  type: WORKSPACE_PROFILE.REMOVE_PROFILE_OPERATION,
  payload
})

export const createProfileOperation = payload => ({
  type: WORKSPACE_PROFILE.CREATE_PROFILE_OPERATION,
  payload
})

export const addProfileOperation = payload => ({
  type: WORKSPACE_PROFILE.ADD_PROFILE_OPERATION,
  payload
})

export const getAllOperations = payload => ({
  type: WORKSPACE_PROFILE.GET_ALL_OPERATIONS,
  payload
})

export const allOperationsSuccess = payload => ({
  type: WORKSPACE_PROFILE.ALL_OPERATIONS_SUCCESS,
  payload
})
// MESSAGES
export const successMessage = payload => ({
  type: WORKSPACE_PROFILE.SUCCESS_MESSAGE,
  payload
})

export const clearSuccessMessage = () => ({
  type: WORKSPACE_PROFILE.CLEAR_SUCCESS_MESSAGE
})

export const errorMessage = payload => ({
  type: WORKSPACE_PROFILE.ERROR_MESSAGE,
  payload
})

export const clearErrorMessage = () => ({
  type: WORKSPACE_PROFILE.CLEAR_ERROR_MESSAGE
})

export const WORKSPACE_PROFILE_ACTIONS = {
  LOADING: loading,
  LOADING_FALSE: loadingFalse,

  GET_WORKSPACE_PROFILES: getWorkspaceProfiles,
  WORKSPACE_PROFILES_SUCCESS: workspaceProfilesSuccess,
  CREATE_WORKSPACE_PROFILE: createWorkspaceProfile,
  EDIT_WORKSPACE_PROFILE: editWorkspaceProfile,
  REMOVE_WORKSPACE_PROFILE: removeWorkspaceProfile,

  GET_PROFILE_OPERATION: getProfileOperations,
  PROFILE_OPERATION_SUCCESS: profileOperationsSuccess,
  REMOVE_PROFILE_OPERATION: removeProfileOperation,
  CREATE_PROFILE_OPERATION: createProfileOperation,
  ADD_PROFILE_OPERATION: addProfileOperation,
  GET_ALL_OPERATIONS: getAllOperations,
  ALL_OPERATIONS_SUCCESS: allOperationsSuccess,

  SUCCESS_MESSAGE: successMessage,
  CLEAR_SUCCESS_MESSAGE: clearSuccessMessage,
  ERROR_MESSAGE: errorMessage,
  CLEAR_ERROR_MESSAGE: clearErrorMessage
}

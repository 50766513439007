import styled from 'styled-components'
import { spacing } from '@global/theme'
import { THEME_NAME } from '@global/constants'

export const FileItemsNoAcceptedContainer = styled.div`
 display: flex;
 justify-content: flex-start;
 align-items: flex-start;
 flex-direction: column;
 width: 100%;
 max-height: 200px;
 margin: ${spacing.one} 0;
 overflow-y: auto;
 background-color: ${({ theme }) => (theme.name === THEME_NAME.LIGHT ? theme.colors.error.light : 'transparent')};
 border: 2px solid ${({ theme }) => theme.colors.error.main};
 padding: ${spacing.one};
 > p {
    margin-bottom: ${spacing.quarter};
 }
`

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from '@global/constants'

import Icon from '@baseComponents/icon'
import { Text } from '@baseComponents/texts'
import { LineButton } from '@baseComponents/button'

import { TYPE_MENU } from '@routes/constants'
import MenuItem from '../menuItem'
import { ButtonWrapper, MenuContainer, NavButton, SubMenu, SubMenuWrapper, Tooltip } from '../../styles'

const NavBody = ({ navItemList, collapse, device, hideNav, pathname, user }) => {
  const [openMenu, setOpenMenu] = useState(false)

  const handleSubMenu = navButton => {
    const existProp = Object.prototype.hasOwnProperty.call(openMenu, navButton)
    setOpenMenu({
      ...openMenu,
      [navButton]: existProp ? !openMenu[navButton] : true
    })
  }

  const createNewWorkspace = () => {}

  return (
    <>
      <MenuContainer name='navBody' device={device}>
        {navItemList.map(({ id, label, icon, to, subMenus }) => (
          <>
            {subMenus.length === 0
              ? (
                <MenuItem
                  key={`menuItem_${id}`}
                  pathname={pathname}
                  to={to}
                  label={label}
                  icon={icon}
                  onClick={hideNav}
                  collapse={collapse}
                  user={user}
                  device={device}
                />
              )
              : (
                <>
                  <NavButton
                    key={id}
                    name={`navButton_${id}`}
                    device={device}
                    open={openMenu[`navButton_${id}`]}
                    onClick={() => handleSubMenu(`navButton_${id}`)}
                  >
                    <Icon name={icon} size={device === BREAKPOINTS.DESKTOP ? 'xlarge' : 'large'} color='text' />
                    {collapse && <Text text={label} />}
                    <Tooltip name='tooltip'>{label}</Tooltip>
                    <Icon name='add' />
                  </NavButton>
                  <SubMenuWrapper key={id} name='subMenuWrapper' open={openMenu[`navButton_${id}`]}>
                    <SubMenu open={openMenu[`navButton_${id}`]}>
                      {
                        subMenus.map(sm => (
                          <MenuItem
                            key={sm.to}
                            pathname={pathname}
                            to={sm.to}
                            label={sm.label}
                            icon={sm.icon}
                            onClick={hideNav}
                            collapse={collapse}
                            user={user}
                            device={device}
                            type={TYPE_MENU}
                          />
                        ))
                      }
                    </SubMenu>
                    <ButtonWrapper>
                      <LineButton
                        text={device > BREAKPOINTS.TABLET || device === BREAKPOINTS.MOBILE ? 'New workspace' : ''}
                        icon='create_new_folder'
                        color='primary'
                        size='small'
                        block
                        onClick={createNewWorkspace}
                      />
                    </ButtonWrapper>
                  </SubMenuWrapper>
                </>
              )}
          </>
        ))}
      </MenuContainer>
    </>
  )
}

NavBody.propTypes = {
  navItemList: PropTypes.array,
  collapse: PropTypes.bool,
  hideNav: PropTypes.func,
  pathname: PropTypes.string,
  user: PropTypes.object,
  device: PropTypes.number
}

export default NavBody

import styled from 'styled-components'
import { spacing } from '@global/theme'

export const BannerContainer = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  min-height: 280px;
  height: auto;
  background-color: ${({ theme }) => theme.colors.background.variant || theme.colors.primary.main};
  padding: ${spacing.three}; 
  grid-column-start: ${({ columnStart }) => columnStart || 1};
  grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  grid-row-start: ${({ rowGrid }) => rowGrid || 1};
  grid-row-end: ${({ rowGrid }) => rowGrid || 1};

  & > h2 {
    margin-bottom: ${spacing.two};
  }
`

export const ImageBanner = styled.img`
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || '120px'};
    object-fit: scale-down;
    margin-bottom: ${spacing.three};
`

export const folderAdapter = folder => ({
  id: folder.id,
  name: folder.name,
  createdAt: folder.createdAt,
  updatedAt: folder.updatedAt,
  marketForDeletation: folder.market_for_deletation,
  parent: folder.parent,
  owner: folder.owner,
  contents: folder.contents,
  linkedFolders: folder.linked_folders,
  viewers: folder.viewers,
  editors: folder.editors
})

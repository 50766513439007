import styled, { keyframes, css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { SourceCodePro, large, xlarge, semibold, regular } from '@global/fonts'
import { spacing } from '@global/theme'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'
import { NAV_WIDTH, TYPE_MENU, HEIGHT_HEADER_NAV, HEIGHT_FOOTER_NAV, HEADER_HEIGHT } from '@routes/constants'

const fadeIn = keyframes`
    from {
      opacity: 0;
      width: 0;
    }
  
    to {
      opacity: 1;
      width: ${({ device }) => (device >= BREAKPOINTS.DESKTOP ? NAV_WIDTH.DESKTOP : NAV_WIDTH.MOBILE)};
    }
`

export const navMobileValues = css`
  position: fixed;
  left: 0;
  box-shadow: 2px 2px 2px 330px rgba(108, 108, 108, 0.6);
  height: 100vh;
  width: ${NAV_WIDTH.DESKTOP};
  display: flex;
`

export const MyNav = styled.nav`
  position: relative;
  display: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'none' : 'flex')};
  flex-direction: column;
  width: ${({ device }) => (device >= BREAKPOINTS.DESKTOP ? NAV_WIDTH.DESKTOP : NAV_WIDTH.MOBILE)};
  grid-area: nav;
  background-color: ${({ theme }) => theme.colors.background.variant || theme.colors.primary.main}};
  z-index: 5;

  animation:${fadeIn} 0.3s;
  transition: all 200ms ease;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ device, sidebar }) => ((device === BREAKPOINTS.MOBILE && sidebar) && navMobileValues)};
`

export const MenuContainer = styled.div`
  width: 100%;
  max-height: ${() => {
    const marginHeader = spacing.seven
    const heightHeader = `${HEIGHT_HEADER_NAV} + ${marginHeader}`
    const marginFooter = spacing.one
    const footerHeader = `${HEIGHT_FOOTER_NAV} + ${marginFooter}`
    const marginMenuContainer = spacing.four
    return `calc(100% - (${heightHeader} + ${footerHeader} + ${marginMenuContainer} + 10px))`
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > p {
    color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.text.light
    return theme.colors.text.dark
  }}!important;
  }

  &::-webkit-scrollbar {
    width: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return spacing.half
    return spacing.threeQuarters
  }};
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.main
    return theme.colors.primary.main
  }};
    border-radius: ${spacing.one};
  }
 

`

export const MenuItemStyle = styled.div`
    width: 100%;

    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    align-items: center;
    justify-content: flex-start;
    
    border: ${({ isActive, theme }) => {
    if (isActive) {
      const currentColor = theme.name === THEME_NAME.LIGHT
        ? theme.colors.primary.main
        : theme.colors.primary.main
      return `${spacing.quarter} solid ${currentColor}`
    }
  }};
    ${SourceCodePro};

    margin: 0 0 ${spacing.half} 0;
    padding: ${spacing.one} ${spacing.three};
    
    ${large};
    ${regular};
    cursor: pointer;

    background-color: ${({ isActive, theme, color, type }) => {
    if (isActive) {
      if (type === TYPE_MENU) {
        if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.white
        return theme.colors.background.variant
      }
      if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.white || theme.colors[color]?.selected
      return theme.colors.background.content
    }
    if (type === TYPE_MENU) {
      if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.white
      return theme.colors.background.content
    }
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.white
    return theme.colors.background.variant
  }};

    &:hover {
      border-radius: ${({ isActive }) => (isActive ? 0 : spacing.one)}; 
      background-color: ${({ theme, type }) => {
    if (type === TYPE_MENU) {
      if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.variant
      return theme.colors.background.main
    }
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.primary.light
    return theme.colors.background.content
  }}!important;
      & > div {
        display: ${({ device }) => (device > BREAKPOINTS.MOBILE && device < BREAKPOINTS.DESKTOP ? 'block' : 'none')};
        animation:${fadeIn} 0.5s ease;
        }
      }
    }

    & > span {
      margin-right: ${({ iconMarginRight }) => (iconMarginRight ? spacing.two : 0)};
      color: ${({ theme }) => {
    const currentColor = theme.name === THEME_NAME.LIGHT
      ? theme.colors.primary.main
      : theme.colors.text.title
    return currentColor
  }}!important;
    }
  `

export const MyLink = styled(NavLink)`
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE || device >= BREAKPOINTS.DESKTOP) return 'flex-start'
    return 'center'
  }};

  color: ${({ active, theme }) => {
    if (active) {
      if (theme.name === THEME_NAME.LIGHT) return theme.colors.primary.main
      return theme.colors.primary.main
    }
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.text.content
    return theme.colors.text.title
  }}!important;

  & > span {
    color: ${({ active, theme }) => {
    if (active) {
      if (theme.name === THEME_NAME.LIGHT) return theme.colors.primary.main
      return theme.colors.primary.main
    }
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.text.content
    return theme.colors.text.title
  }}!important;
    margin-right: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE || device >= BREAKPOINTS.DESKTOP) return spacing.two
    return 0
  }}!important;
  }
`

export const LogoContainer = styled.div`
  height: ${HEADER_HEIGHT};
  width: 100%;
  padding: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : `${spacing.two} ${spacing.three}`)};
  display: flex;
  align-items: center;
  justify-content: ${({ device }) => (device >= BREAKPOINTS.DESKTOP ? 'flex-start' : 'space-around')};
  ${xlarge};
  color: ${({ theme }) => (theme.colors.text.main)};
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.dark
    return theme.colors.primary.main
  }};

  & > span {
    cursor: pointer;
    transition: none !important;
    color: ${({ theme }) => (theme.colors.text.white)}!important;
    &:hover {
      transition: none !important;
      border-radius: 50%;      
      background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.background.light
    return theme.colors.primary.hover
  }};
            
          outline: ${({ theme }) => {
    const currentColor = theme.name === THEME_NAME.DARK
      ? theme.colors.background.light
      : theme.colors.primary.hover
    return `${spacing.half} solid ${currentColor}`
  }};
      
};}
  }
  & > p {
    color: ${({ theme }) => (theme.colors.text.dark)}!important;
  }
`

export const Tooltip = styled.div`
  width: 7rem;
  height: auto;
  padding: ${spacing.half};
  position: fixed;
  color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.text.light
    return theme.colors.text.title
  }};
  left:  ${NAV_WIDTH.MOBILE};
  display: none;
  background-color: ${({ theme, color }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.background.variant
    if (color) return theme.colors[color]?.main
    return theme.colors.primary.main
  }};
  border-radius: ${spacing.threeQuarters}; 
  text-align: center;
  box-shadow: 0 ${spacing.threeQuarters} ${spacing.oneAndAQuarter} rgba(0, 0,0, 0.2);
  font-size: ${spacing.oneAndAHalf};
  ${semibold};
`

export const NavImg = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: ${({ device }) => (device === BREAKPOINTS.DESKTOP ? spacing.oneAndAQuarter : 0)};
  border-radius: ${({ borderRadius }) => (borderRadius && borderRadius)};
  object-fit: cover;
  outline: ${({ isActive, theme, color }) => {
    const currentColor = color ? theme.colors[color]?.main : theme.colors.primary.main
    if (isActive) return `2px solid ${currentColor}`
    return 'none'
  }};
`
export const NavFooterWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: ${spacing.one};
  width: 100%;
  height: ${HEIGHT_FOOTER_NAV};
  padding: ${spacing.three} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const NavButton = styled.div`
  position: relative;
  width: ${({ device }) => {
    if (device === BREAKPOINTS.DESKTOP || device === BREAKPOINTS.MOBILE) return '95%'
    return '80%'
  }};

  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: center;
  justify-content: flex-start;
  
  border-left: ${({ isActive, theme, device, type }) => {
    if (isActive && type === TYPE_MENU && device <= BREAKPOINTS.TABLET) return 'none'
    if (isActive) return `${spacing.half} solid ${theme.colors.primary.main}`
  }};
  ${SourceCodePro};

  margin: ${spacing.half} 0;
  padding: ${({ device, isActive, type }) => {
    if (type === TYPE_MENU && device === BREAKPOINTS.MOBILE) return `${spacing.one} 0`
    if (isActive) {
      if (device === BREAKPOINTS.DESKTOP) return `${spacing.one} ${spacing.one}`
      return `${spacing.one} ${spacing.half}`
    }
    if (device === BREAKPOINTS.DESKTOP) return `${spacing.one} ${spacing.oneAndAHalf}`
    return `${spacing.one} ${spacing.one}`
  }};
    
  position: relative;
  text-decoration: none;

  > p {
    ${SourceCodePro};
    ${large};
    ${regular};
    color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.text.content
    return theme.colors.text.title
  }}!important;
  margin-left: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE || device >= BREAKPOINTS.DESKTOP) return spacing.two
    return 0
  }}!important;
  }

  & > span {
    color: ${({ active, theme, color }) => {
    if (active) return theme.colors[color]?.main || theme.colors.primary.main
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.text.content
    return theme.colors.text.title
  }}!important;
  }

  & > :last-child {
    position: absolute;
    top: ${spacing.oneAndAHalf};
    right: ${spacing.one};
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0deg)')};
    transition: 0.3s ease;
  }

  &:hover {
    border-radius: ${({ active }) => (active ? 0 : spacing.one)}; 
    cursor: pointer;
    & > div {
        display: ${({ device }) => (device > BREAKPOINTS.MOBILE && device < BREAKPOINTS.DESKTOP ? 'block' : 'none')};
        animation:${fadeIn} 0.5s ease;
      }
  }
`
export const SubMenu = styled.div`
  width: 100%;
  max-height: 300px;
  margin-bottom: ${spacing.one};
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.background.content
    return theme.colors.background.white
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;

  &::-webkit-scrollbar {
    width: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return spacing.half
    return spacing.threeQuarters
  }};
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.main
    return theme.colors.background.variant
  }};
    border-radius: ${spacing.one};
  }

  transition: all .35s ease;
  opacity: ${({ open }) => (open ? 1 : 0)};
  height: ${({ open }) => (open ? '300px' : '0px')};
`

export const SubMenuWrapper = styled.div`
  width: 100%;
  margin-bottom: ${spacing.two};

  transition: all .45s ease;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  height: ${({ open }) => (open ? '224px' : '0px')};
  opacity: ${({ open }) => (open ? 1 : 0)};
`
export const ButtonWrapper = styled.div`
  padding: 0 ${spacing.two};
`

import React, { useState, useEffect, Suspense, lazy } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Box,
  Column,
  Divider,
  MyButtonLink,
  WrapperBreadcrumbs,
  WrapperHeading,
  WrapperLoading
} from '@global/styles'
import { spacing } from '@global/theme'
import {
  BREAKPOINTS,
  FLEX_PROPERTIES,
  FUNCTION_TYPE,
  INITIAL_STATES,
  SUBTITLES,
  TEXT_CONTENT,
  TOAST_POSITION,
  TOAST_PROPERTIES
} from '@global/constants'
import { MESSAGES } from '@global/message'
import { SITE } from '@routes/paths'

import { USERS_WORKSPACE } from '@containers/workspace/mock'

import { useNotification } from 'src/context/notification.context'
import Loading from '@components/loading'
import Breadcrumbs from '@components/breadcrums'
import { Heading, Text } from '@baseComponents/texts'
import { useUser } from '@containers/authentication/utils/hook'
import { TextInput } from '@components/inputs/inputs'
import SelectInput from '@components/select'
import { GhostButton, SolidButton } from '@baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'

import useRedux from '../../redux'
import { WORKSPACE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'
import { FormContainer, WorkspaceButtonContainer, TextContainer } from './styles'
import CreateEditWorkspaceProfileModal from './components/createEditWorkspaceProfile'
import ProfileList from './components/profileList'
import { WORKSPACE_PROFILE_ACTIONS } from './redux/actions'

const Modal = lazy(() => import('@components/modal'))

const MODAL_TITLE = {
  CREATE_PROFILE: 'Create profile',
  EDIT_PROFILE: 'Edit profile',
  ADD_OPERATION: 'Add operation',
  CREATE_OPERATION: 'Create operation'
}

const EditWorkspace = () => {
  useRedux()
  const { workspaceId } = useParams()
  const { state, key } = useLocation()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()
  const { getNotification } = useNotification()
  const { GetToken } = useUser()
  const history = useHistory()

  const [breadcrumbs, setBreadcrumbs] = useState(state?.breadcrumbs)
  const [workspace, setWorkspace] = useState(null)
  const [workspaceName, setWorkspaceName] = useState({
    ...INITIAL_STATES.INPUT,
    name: SUBTITLES.SUMMARY
  })
  const [workspaceDescription, setWorkspaceDescription] = useState({
    ...INITIAL_STATES.INPUT,
    name: SUBTITLES.DESCRIPTION
  })
  const [apiKey, setApiKey] = useState('')
  const [editors, setEditors] = useState([])
  const [viewers, setViewers] = useState([])
  const [selectedEditors, setSetselectedEditors] = useState([])
  const [selectedViewers, setSetselectedViewers] = useState([])
  const [open, setOpen] = useState(false)
  const [removeWorkspace, setRemoveWorkspace] = useState(null)
  const [modalTitle, setModalTitle] = useState(null)
  const [createAndEditProfileModal, setCreateAndEditProfileModal] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const workspaceData = useSelector(SELECTORS.WORKSPACE)
  const messageSuccess = useSelector(SELECTORS.SUCCESS_MESSAGE)
  const messageError = useSelector(SELECTORS.ERROR_MESSAGE)
  const summary = useSelector(SELECTORS.SUMMARY)
  const loadingSummary = useSelector(SELECTORS.LOADING_DATA_SUMMARY)
  const loadingWorkspace = useSelector(SELECTORS.LOADING_SUMMARY)

  const handleInputChange = val => {
    const { name } = val
    switch (name) {
      case SUBTITLES.SUMMARY:
        setWorkspaceName(val)
        break
      case SUBTITLES.DESCRIPTION:
        setWorkspaceDescription(val)
        break
      default:
        break
    }
  }

  const handleEditorsSelectChange = values => {
    setEditors(values)
    setSetselectedEditors(values.map(v => v.value))
  }

  const handleViewersSelectChange = values => {
    setViewers(values)
    setSetselectedViewers(values.map(v => v.value))
  }

  const handleRegenerateApiKey = async () => {
    const token = await GetToken()
    dispatch(WORKSPACE_ACTIONS.REGENERATE_API_KEY({ token, workspaceId }))
  }

  const handleEditSummaryData = async () => {
    const token = await GetToken()
    dispatch(WORKSPACE_ACTIONS.EDIT_WORKSPACE_SUMMARY({
      workspaceId,
      data: {
        name: workspaceName.value,
        description: workspaceDescription.value,
        editors: selectedEditors,
        viewers: selectedViewers
      },
      token
    }))
  }

  const handleCloseModal = () => {
    setRemoveWorkspace(null)
    setCreateAndEditProfileModal(null)
    setOpen(false)
  }

  const deleteWorkspace = async itemId => {
    const token = await GetToken()
    dispatch(WORKSPACE_ACTIONS.REMOVE_WORKSPACE({ token, workspaceId: itemId }))
  }

  const handleRemoveWorkspace = () => {
    setModalTitle(TEXT_CONTENT.DELETE_WORKSPACE.toUpperCase())
    setRemoveWorkspace({
      close: handleCloseModal,
      text: `${MESSAGES.REMOVE_WORKSPACE} Do you wish to continue?`,
      itemId: workspaceId,
      removeItem: deleteWorkspace
    })
    setOpen(true)
  }

  const handleWorkspaceProfileSuccess = message => {
    getNotification({
      title: TOAST_PROPERTIES.SUCCESS.title,
      message,
      icon: TOAST_PROPERTIES.SUCCESS.icon,
      color: TOAST_PROPERTIES.SUCCESS.color,
      position: TOAST_POSITION.rightTop,
      open: true
    })
    setTimeout(() => {
      dispatch(WORKSPACE_PROFILE_ACTIONS.CLEAR_SUCCESS_MESSAGE())
    }, 1000)
  }

  const handleWorkspaceProfile = (functionType, data) => {
    setModalTitle(FUNCTION_TYPE.CREATE.name === functionType ? MODAL_TITLE.CREATE_PROFILE : MODAL_TITLE.EDIT_PROFILE)
    setCreateAndEditProfileModal({
      oldData: data,
      close: handleCloseModal,
      successResult: handleWorkspaceProfileSuccess,
      workspaceId,
      edit: FUNCTION_TYPE.EDIT.name === functionType
    })
    setOpen(true)
  }

  useEffect(async () => {
    const token = await GetToken()
    dispatch(WORKSPACE_ACTIONS.GET_WORKSPACE_DATA({ token, workspaceId }))
    const lastCrumb = {
      text: 'Edit',
      url: null
    }
    setBreadcrumbs([...breadcrumbs, lastCrumb])
  }, [key])

  useEffect(() => {
    if (workspaceData) {
      setWorkspace(workspaceData)
      setWorkspaceName({ ...workspaceName, value: workspaceData.name })
      setWorkspaceDescription({ ...workspaceName, value: workspaceData.description })
      setApiKey(workspaceData.apiKey)
      const editorsFiltered = workspaceData.editors.map(e => ({ value: e.id, label: e.name }))
      const viewersFiltered = workspaceData.viewers.map(e => ({ value: e.id, label: e.name }))
      setEditors(editorsFiltered)
      setViewers(viewersFiltered)
      setSetselectedEditors(editorsFiltered.map(v => v.value))
      setSetselectedViewers(viewersFiltered.map(v => v.value))
    }
  }, [workspaceData])

  useEffect(() => {
    if (summary) setApiKey(summary.apiKey)
  }, [summary])

  useEffect(() => {
    if (messageSuccess) {
      getNotification({
        title: TOAST_PROPERTIES.SUCCESS.title,
        message: messageSuccess.message || messageSuccess.status,
        icon: TOAST_PROPERTIES.SUCCESS.icon,
        color: TOAST_PROPERTIES.SUCCESS.color,
        position: TOAST_POSITION.rightTop,
        open: true,
        timeOut: 4000
      })
      // edited successfuly
      if (messageSuccess.status === 200) {
        history.goBack()
        return
      }
      // removed
      if (messageSuccess.status === 204) {
        history.push(SITE.DASHBOARD)
        return
      }
      setTimeout(() => {
        dispatch(WORKSPACE_ACTIONS.CLEAR_SUCCESS_MESSAGE())
      }, 2000)
    }
  }, [messageSuccess])

  useEffect(() => {
    if (messageError) {
      getNotification({
        title: TOAST_PROPERTIES.ERROR.title,
        message: messageError.message || messageError.status,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color,
        position: TOAST_POSITION.rightTop,
        open: true,
        timeOut: 4000
      })
      setTimeout(() => {
        dispatch(WORKSPACE_ACTIONS.CLEAR_ERROR_MESSAGE())
      }, 2000)
    }
  }, [messageError])

  return (
    loading
      ? (
        <WrapperLoading>
          <Loading color='primary' size='xsmall' weight='bold' />
        </WrapperLoading>
      )
      : (
        <>
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart={1} columnEnd={13} rowStart={1} rowEnd={1}>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
          </>
          <WrapperHeading columnStart={1} columnEnd={13} rowStart={2} rowEnd={2} device={device}>
            <Heading text={`EDIT ${workspace?.name}`} type='h1' size='large' />
            <WorkspaceButtonContainer device={device}>
              <GhostButton
                size='medium'
                color='primary'
                icon='delete'
                onClick={handleRemoveWorkspace}
                block={device === BREAKPOINTS.MOBILE}
              />
              <GhostButton
                size='medium'
                color='primary'
                text={FUNCTION_TYPE.CANCEL.label}
                onClick={() => history.goBack()}
                block={device === BREAKPOINTS.MOBILE}
              />
              <SolidButton
                size='medium'
                color='primary'
                text={FUNCTION_TYPE.SAVE.label}
                onClick={handleEditSummaryData}
                block={device === BREAKPOINTS.MOBILE}
              />
            </WorkspaceButtonContainer>
          </WrapperHeading>
          <Divider rowGrid={3} columnStart={1} columnEnd={13} />
          {loadingWorkspace
            ? (
              <WrapperLoading>
                <Loading color='primary' size='xsmall' weight='bold' />
              </WrapperLoading>
            )
            : (
              <FormContainer columnStart={1} columnEnd={device <= BREAKPOINTS.TABLET ? 13 : 11} gridRow={4}>
                <TextContainer width='100%' device={device}>
                  <TextInput
                    name={SUBTITLES.SUMMARY}
                    label={SUBTITLES.NAME}
                    onChange={handleInputChange}
                    color='primary'
                    value={workspaceName.value}
                    error={workspaceName.error}
                    required
                  />
                  <Box widthBox={spacing.four} />
                  <TextInput
                    name={SUBTITLES.DESCRIPTION}
                    label={SUBTITLES.DESCRIPTION}
                    onChange={handleInputChange}
                    color='primary'
                    value={workspaceDescription.value}
                  />
                </TextContainer>
                <Column width='100%' alignItems={FLEX_PROPERTIES.ALIGN_ITEMS.FLEX_END}>
                  <TextInput
                    name={SUBTITLES.API_KEY}
                    label={SUBTITLES.API_KEY}
                    onChange={() => {}}
                    color='primary'
                    value={apiKey}
                    bits={loadingSummary
                      ? [{ icon: 'sync', position: 'right', colorBit: 'primary', spin: true }] : []}
                  />
                  <MyButtonLink onClick={handleRegenerateApiKey} color='primary' underline>
                    {TEXT_CONTENT.REGENERATE_API_KEY}
                  </MyButtonLink>
                </Column>
                <Column width='100%'>
                  <Text size='medium' marginBottom={spacing.one}>{TEXT_CONTENT.SELECT_USER_SHARE_WITH}</Text>
                  <SelectInput
                    label={SUBTITLES.EDITORS}
                    onChange={handleEditorsSelectChange}
                    options={USERS_WORKSPACE.map(u => ({ value: u.id, label: u.name }))}
                    value={editors}
                    size='large'
                    color='primary'
                    isMulti
                    loadingMessage={() => <Text weight='bold'>{TEXT_CONTENT.LOADING}...</Text>}
                    noOptionsMessage={() => <Text weight='bold' align='center'>{TEXT_CONTENT.NO_OPTIONS}</Text>}
                  />
                  <SelectInput
                    label={SUBTITLES.VIEWERS}
                    onChange={handleViewersSelectChange}
                    options={USERS_WORKSPACE.map(u => ({ value: u.id, label: u.name }))}
                    value={viewers}
                    size='large'
                    color='primary'
                    isMulti
                    loadingMessage={() => <Text weight='bold'>{TEXT_CONTENT.LOADING}...</Text>}
                    noOptionsMessage={() => <Text weight='bold' align='center'>{TEXT_CONTENT.NO_OPTIONS}</Text>}
                  />
                </Column>
              </FormContainer>
            )}
          <Divider rowGrid={5} columnStart={1} columnEnd={13} />
          <WrapperHeading columnStart={1} columnEnd={13} rowStart={6} rowEnd={6} device={device}>
            <Text
              size='large'
              weight='bold'
              marginBottom={spacing.one}
            >
              {TEXT_CONTENT.OPERATION_CONFIG.toUpperCase()}
            </Text>
            <SolidButton
              size='medium'
              color='primary'
              text={TEXT_CONTENT.CREATE_PROFILE}
              onClick={() => handleWorkspaceProfile(FUNCTION_TYPE.CREATE.name, { oldProfiles: workspace.profiles })}
              block={device === BREAKPOINTS.MOBILE}
            />
          </WrapperHeading>
          {!!workspace
           && (
           <ProfileList
             rowGrid={7}
             columnStart={1}
             columnEnd={13}
             profileIds={workspace.profiles}
             handleWorkspaceProfile={handleWorkspaceProfile}
           />
           )}
          <Suspense fallback={<Loading color='primary' size='xsmall' weight='bold' />}>
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle?.toUpperCase()}
              minHeight='auto'
            >
              {removeWorkspace
              && (
                <DeleteConfirmationModal
                  {...removeWorkspace}
                />
              )}
              {createAndEditProfileModal && (<CreateEditWorkspaceProfileModal {...createAndEditProfileModal} />)}
            </Modal>
          </Suspense>
        </>
      )
  )
}

export default EditWorkspace

import React from 'react'
import PropTypes from 'prop-types'

import MyPropTypes from '@global/propTypes'

import { StyledText } from './styles'

const Text = ({ text, align, weight, color, size, children, marginBottom, disabled, id, width }) => (
  <StyledText
    weight={weight}
    size={size}
    color={color}
    align={align}
    marginBottom={marginBottom}
    disabled={disabled}
    id={id}
    width={width}
  >
    {text || children}
  </StyledText>
)

Text.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  weight: MyPropTypes.weight,
  color: MyPropTypes.allColors,
  size: MyPropTypes.size,
  children: PropTypes.node,
  marginBottom: MyPropTypes.space,
  disabled: PropTypes.bool,
  id: PropTypes.number,
  width: PropTypes.string
}

export default Text

import styled from 'styled-components'

import { spacing } from '@global/theme'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const MediaFileCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: ${({ device }) => (device >= BREAKPOINTS.TABLET ? '412px' : 'none')};
  height: 372px;
  padding: ${spacing.two};
  border-radius: ${spacing.quarter};
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.ultralight
    return theme.colors.background.content
  }};
  grid-column-start: ${({ columnStart }) => columnStart || 1};
  grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  grid-row-start: ${({ rowGrid }) => rowGrid || 1};
  grid-row-end: ${({ rowGrid }) => rowGrid || 1};

  & > button {
    color: ${({ theme }) => theme.colors.text.content};
  }
`

import styled, { css } from 'styled-components'

import { spacing } from '@global/theme'
import { column, row } from '@global/styles'
import { THEME_NAME } from '@global/constants'

const Vertical = css`
  ${column};
  width: 100%;
`

const Horizontal = css`
  ${row};
  width: 100%;
`

export const MyCard = styled.div`
  position: relative;
  border-radius: ${({ borderRadius }) => borderRadius || spacing.three};
  ${({ type }) => (type === 'horizontal' ? Horizontal : Vertical)};
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || spacing.four};
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.content
    return theme.colors.background.main
  }};
  box-shadow: ${({ boxShadow }) => boxShadow && '2px 2px 2px 2px rgba(108, 108, 108, 0.17)'};
  margin: ${({ margin }) => (margin || 0)};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  grid-column-start: ${({ columnStart }) => (columnStart || 1)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 13)};
  grid-row-start: ${({ rowStart }) => (rowStart || 1)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 1)};
`

import React from 'react'
import PropTypes from 'prop-types'

import { spacing } from '@global/theme'
import { BREAKPOINTS } from '@global/constants'

import Icon from '@baseComponents/icon'
import { Text } from '@baseComponents/texts'
import { NavImg, LogoContainer } from '../../styles'

const NavHeader = ({ imgLogo, title, collapse, close, device }) => (
  <LogoContainer name='navHeader' device={device}>
    <NavImg
      src={imgLogo}
      alt='logo'
      width={spacing.sixAndAHalf}
      height={spacing.sixAndAHalf}
      borderRadius='50%'
      device={device}
    />
    {collapse
    && (
    <Text size='large' weight='bold' align='center' marginBottom='quarter'>
      {title.toUpperCase()}
    </Text>
    )}
    {collapse && device === BREAKPOINTS.MOBILE
     && (
     <Icon
       name='close'
       size='large'
       onClick={() => close(false)}
     />
     )}
  </LogoContainer>
)

NavHeader.propTypes = {
  imgLogo: PropTypes.string,
  title: PropTypes.string,
  collapse: PropTypes.bool,
  close: PropTypes.func,
  device: PropTypes.number
}

export default NavHeader

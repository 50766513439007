import styled from 'styled-components'

import { BREAKPOINTS, THEME_NAME } from '@global/constants'
import { spacing } from '@global/theme'
import { borderDark, boxShadow, borderLight } from '@global/styles'

export const WrapperFileCard = styled.div`
  display: flex;
  min-height: 248px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: ${spacing.twoAndAHalf};
  height: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '240px' : '200px')};
  padding: ${spacing.two};
  background-color: ${({ theme }) => theme.colors.background.main};
  ${({ theme }) => (theme.name === THEME_NAME.DARK ? borderDark : borderLight)};
  grid-column: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return `span ${6}`
    if (device === BREAKPOINTS.TABLET_PORTRAIT) return `span ${4}`
    if (device === BREAKPOINTS.BIG_SCREEN) return `span ${2}`
    return `span ${3}`
  }};
  transition: all 0.25s;
  &:hover {
      transform: scale(1.03, 1.03);
      ${({ theme }) => (theme.name === THEME_NAME.LIGHT ? boxShadow : null)};
      background-color: ${({ theme }) => (theme.name === THEME_NAME.DARK ? theme.colors.background.content : null)};
    }
  cursor: pointer;
`

export const HeaderFileCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const BodyFileCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  
  & > span {
    margin-left: -${spacing.one};
  }
`

export const FooterFileCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > P {
    width: inherit;
  }
`

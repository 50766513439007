import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import PrivateLayout from '@routes/components/layouts/privateLayout'
import { SITE } from '@routes/paths'
import { useUser } from '@containers/authentication/utils/hook'

const PrivateRouteWrapper = ({ component: Component, ...rest }) => {
  const { GetUser } = useUser()
  const user = GetUser()

  return (
    <Route
      {...rest}
      render={props => (
        <PrivateLayout>
          {user ? <Component {...props} /> : <Redirect to={SITE.SIGN_IN} />}
        </PrivateLayout>
      )}
    />
  )
}

PrivateRouteWrapper.propTypes = {
  component: PropTypes.any
}

export default PrivateRouteWrapper

import React from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'

import { Text } from 'src/baseComponents/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import { BreadcrumbsStyle, Crumb, MyLink } from './styles'

const Breadcrumbs = ({ crumbs, color }) => {
  const { deviceName } = useWindowDimensions()

  return (
    <BreadcrumbsStyle deviceName={deviceName}>
      {crumbs.map((crumb, i) => (
        <Crumb key={i + 1}>
          {
            i !== crumbs.length - 1
              ? <MyLink to={crumb.url || ''} color={color}>{crumb.text}</MyLink>
              : <Text size='medium' align='center' weight='regular' color={color}>{crumb.text}</Text>
          }
          {i < crumbs.length - 1
            && <Text size='large' align='center' weight='bold' text='•' id='symbol' color={color} />}
        </Crumb>
      ))}
    </BreadcrumbsStyle>
  )
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array,
  color: MyPropTypes.allColors
}
export default Breadcrumbs

import { MEDIA_FILE_SUMMARY } from './types'

export const getMediaFileData = payload => ({
  type: MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_DATA,
  payload
})

export const getMediaFileDataSuccess = payload => ({
  type: MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_DATA_SUCCESS,
  payload
})

export const getMediaFileVersions = payload => ({
  type: MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_VERSIONS,
  payload
})

export const getMediaFileVersionsSuccess = payload => ({
  type: MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_VERSIONS_SUCCESS,
  payload
})

export const getMediaFileUrlsSuccess = payload => ({
  type: MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_URLS_SUCCESS,
  payload
})

export const removeMediaFile = payload => ({
  type: MEDIA_FILE_SUMMARY.REMOVE_MEDIA_FILE,
  payload
})

// MESSAGES
export const successMessage = payload => ({
  type: MEDIA_FILE_SUMMARY.SUCCESS_MESSAGE,
  payload
})

export const clearSuccessMessage = () => ({
  type: MEDIA_FILE_SUMMARY.CLEAR_SUCCESS_MESSAGE
})

export const errorMessage = payload => ({
  type: MEDIA_FILE_SUMMARY.ERROR_MESSAGE,
  payload
})

export const clearErrorMessage = () => ({
  type: MEDIA_FILE_SUMMARY.CLEAR_ERROR_MESSAGE
})

export const MEDIA_FILE_SUMMARY_ACTIONS = {
  GET_MEDIA_FILE_DATA: getMediaFileData,
  GET_MEDIA_FILE_DATA_SUCCESS: getMediaFileDataSuccess,
  GET_MEDIA_FILE_VERSIONS: getMediaFileVersions,
  GET_MEDIA_FILE_VERSIONS_SUCCESS: getMediaFileVersionsSuccess,
  GET_MEDIA_FILE_URLS_SUCCESS: getMediaFileUrlsSuccess,
  REMOVE_MEDIA_FILE: removeMediaFile,
  SUCCESS_MESSAGE: successMessage,
  CLEAR_SUCCESS_MESSAGE: clearSuccessMessage,
  ERROR_MESSAGE: errorMessage,
  CLEAR_ERROR_MESSAGE: clearErrorMessage
}

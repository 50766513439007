import styled, { keyframes, css } from 'styled-components'
import { spacing } from '@global/theme'
import { THEME_NAME } from '@global/constants'
import { borderDark } from '@global/styles'

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(32px);
      transition: 250ms ease;
    }

    to {
      opacity: 1;
      transform: translateY(0);
      transition: 250ms ease;
    }
`

const fadeOut = keyframes`
    from {
      opacity: 1;
      visibility: hidden;
      transform: translateY(0);
      transition: 250ms ease;
    }

    to {
      opacity: 0;
      visibility: visible;
      transform: translateY(32px);
      transition: 250ms ease;
    }
`

const animationOut = css`
  ${fadeOut} 0.4s ease;
`

const animationIn = css`
  ${fadeIn} 0.4s ease;
`

export const DrowdownMenuStyled = styled.div`
  display: block;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  padding: ${({ padding }) => padding || spacing.one};
  border-radius: ${spacing.one};

  width: ${({ widthSize }) => (`${widthSize}px` || 'auto')};
  min-width: 160px;
  height: ${({ heightSize }) => (`${heightSize}px` || 'auto')};
  position: absolute;

  top: ${({ clientY }) => (`${clientY}px`)};
  left: ${({ clientX, widthSize }) => (`${clientX - widthSize}px`)};
  margin: 0px;
  background-color: ${({ theme }) => (theme.colors.background.content)};
  box-shadow: ${spacing.threeQuarters} ${spacing.threeQuarters} ${spacing.oneAndAQuarter} rgba(0, 0,0, 0.2);
  ${({ theme }) => (theme.name === THEME_NAME.DARK && borderDark)};
  z-index: 5;
  animation: ${({ visible }) => (visible ? animationIn : animationOut)};
`

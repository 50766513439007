import styled from 'styled-components'
import { spacing } from '@global/theme'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const DataContainer = styled.div`
 width: 100%;
 display:flex;
 justify-content: space-between;
 align-items: center;
 flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
 margin-bottom: ${spacing.one};
 background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.main
    return theme.colors.grays.light
  }};
 border-radius: ${spacing.one};
`

export const FileContainer = styled.div`
 width: 100%;
 display: flex;
 justify-content: flex-start;
 flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
 align-items: center;
 flex: 4;
 padding: ${spacing.half};
 border-radius: ${spacing.one};

 & > img, p {
    margin-left: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.two)}!important;
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)}!important;
 }
 background-color: ${({ theme, color }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors[color]?.light
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.main
    return theme.colors.grays.light
  }};
`

export const StyledImg = styled.img`
width: ${spacing.three};
height: ${spacing.three};
`

export const LoadingContainer = styled.div`
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 flex: 1;
 background-color: ${({ theme, color }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors[color]?.light
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.main
    return theme.colors.grays.light
  }};
  & > span {
   color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.text.white
    return theme.colors.grays.main
  }}!important;
   &:hover {
      color: ${({ theme, color }) => (color ? theme.colors[color]?.main : theme.colors.primary.main)} !important;
   }
  }
`

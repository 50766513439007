import React from 'react'
import PropTypes from 'prop-types'

import AuthContainer from '@containers/authentication'

import { useWindowDimensions } from '@components/windowDimensions'

import { PublicLayoutContainer } from '../../styles'

const PublicLayout = ({ children }) => {
  const { device } = useWindowDimensions()

  return (
    <PublicLayoutContainer device={device} name='container'>
      <AuthContainer>
        {children}
      </AuthContainer>
    </PublicLayoutContainer>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.any.isRequired
}

export default PublicLayout

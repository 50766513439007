import React from 'react'
import PropTypes from 'prop-types'

import MyPropTypes from '@global/propTypes'
import { Text } from 'src/baseComponents/texts'

import { StyledLabel } from './style'

const Label = ({ text, color, disabled, name, weight, size, required }) => (

  <StyledLabel htmlFor={name}>
    <Text
      text={text}
      weight={weight || 'semibold'}
      size={size || 'small'}
      color={color}
      disabled={disabled}
    />
    {required && (
      <Text
        text='*'
        weight='semibold'
        size='small'
        color='error'
        disabled={disabled}
      />
    )}
  </StyledLabel>
)

Label.propTypes = {
  text: PropTypes.string.isRequired,
  color: MyPropTypes.color,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  weight: PropTypes.string,
  size: PropTypes.string,
  required: PropTypes.bool
}

export default Label

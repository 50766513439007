import produce from 'immer'

import { MEDIA_FILE_SUMMARY } from './types'

export const initialState = {
  loading: true,
  mediaFileData: null,
  mediaFileVersions: null,
  messageSuccess: null,
  messageError: null,
  mediaFileUrls: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_DATA:
      draft.loading = true
      break
    case MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_DATA_SUCCESS:
      draft.loading = false
      draft.mediaFileData = payload
      break
    case MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_VERSIONS_SUCCESS:
      draft.loading = false
      draft.mediaFileVersions = payload
      break
    case MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_URLS_SUCCESS:
      draft.loading = false
      draft.mediaFileUrls = payload
      break
    // messages
    case MEDIA_FILE_SUMMARY.SUCCESS_MESSAGE:
      draft.messageSuccess = payload
      break
    case MEDIA_FILE_SUMMARY.CLEAR_SUCCESS_MESSAGE:
      draft.messageSuccess = null
      break
    case MEDIA_FILE_SUMMARY.ERROR_MESSAGE:
      draft.messageError = payload
      break
    case MEDIA_FILE_SUMMARY.CLEAR_ERROR_MESSAGE:
      draft.messageError = null
      break
    default:
      return initialState
  }
})

export default reducer

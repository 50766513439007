import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { BREAKPOINTS, BUTTON_TYPES, FLEX_PROPERTIES, TITLES, TOAST_POSITION, TOAST_PROPERTIES } from '@global/constants'
import { Box, WrapperLoading } from '@global/styles'
import imageBanner from '@assets/background-logo.png'

import { MESSAGES } from '@global/message'
import { useNotification } from 'src/context/notification.context'
import { spacing } from '@global/theme'
import { SITE } from '@routes/paths'

import { Heading, Text } from '@baseComponents/texts'
import Loading from '@components/loading'
import { SolidButton } from '@baseComponents/button'
import Card from '@components/cards'
import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'
import Banner from '@components/banner'
import BidirectionalPagination from '@components/bidirectionalPagination'

import useRedux from './redux'
import { DASHBOARD_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { WorkSpacesContainer } from './styles'
import WorkSpaceCard from './components/workspaceCard'
import { BANNER_DATA } from './mock'
import CreateWorkspaceModal from './components/createWorkspaceModal'

const Modal = lazy(() => import('@components/modal'))

const MODAL_TITLE = {
  CREATE_WORKSPACE: 'Create workspace',
  EDIT_WORKSPACE: 'Edit workspace'
}

const Dashboard = () => {
  useRedux()
  const { device } = useWindowDimensions()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { getNotification } = useNotification()
  const history = useHistory()

  const [workspaces, setWorkspaces] = useState([])
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [createWorkspace, setCreateWorkspace] = useState(null)
  const [prevLink, setPrevLink] = useState(null)
  const [nextLink, setNextLink] = useState(null)

  const loading = useSelector(SELECTORS.LOADING)
  const workspacesData = useSelector(SELECTORS.WORKSPACES_SUCCESS)
  const errorData = useSelector(SELECTORS.ERROR)
  const loadingWorkspaceList = useSelector(SELECTORS.LOADING_WORKSPACE_LIST)

  const getWorkspaces = async page => {
    const currentToken = await GetToken()
    if (currentToken) {
      dispatch(DASHBOARD_ACTIONS.GET({ token: currentToken, page }))
    } else {
      getNotification({
        title: 'Error',
        message: MESSAGES.EXPIRED_TOKEN,
        icon: 'cancel',
        color: 'error',
        position: TOAST_POSITION.rightTop,
        open: true,
        timeOut: 4000
      })
      Logout()
    }
  }

  const handleSuccess = async workspaceId => {
    getNotification({
      title: TOAST_PROPERTIES.SUCCESS.title,
      message: MESSAGES.SUCCESSFULLY_CREATED_WORKSPACE,
      icon: TOAST_PROPERTIES.SUCCESS.icon,
      color: TOAST_PROPERTIES.SUCCESS.color,
      position: TOAST_POSITION.rightTop,
      open: true
    })
    history.push(`${SITE.DASHBOARD}${SITE.WORKSPACE}/${workspaceId}`)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setCreateWorkspace(null)
  }

  const createNewWorkspace = () => {
    setModalTitle(MODAL_TITLE.CREATE_WORKSPACE.toUpperCase())
    setCreateWorkspace({
      close: handleCloseModal,
      successResult: handleSuccess
    })
    setOpen(true)
  }

  const handlePaginationPrev = () => {
    let pageNumber = 0
    const index = prevLink.indexOf('=')
    if (index !== -1) {
      pageNumber = prevLink.substring(index + 1)
    } else {
      pageNumber = 1
    }
    getWorkspaces(pageNumber)
  }

  const handlePaginationNext = () => {
    const pageNumber = nextLink.substring(nextLink.indexOf('=') + 1)
    getWorkspaces(pageNumber)
  }

  useEffect(async () => {
    getWorkspaces(1)
  }, [])

  useEffect(() => {
    if (workspacesData) {
      setWorkspaces(workspacesData.results)
      setPrevLink(workspacesData?.previous)
      setNextLink(workspacesData?.next)
    }
  }, [workspacesData])

  useEffect(() => {
    if (errorData) {
      getNotification({
        title: 'Error',
        message: errorData.message || MESSAGES.BASIC_ERROR,
        icon: 'cancel',
        color: 'error',
        position: TOAST_POSITION.rightTop,
        open: true,
        timeOut: 4000
      })
    }
  }, [errorData])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            <Banner
              columnStart={1}
              columnEnd={13}
              rowGrid={1}
              title={BANNER_DATA.TITLE}
              text={BANNER_DATA.TEXT}
              imagePath={imageBanner}
            />
            <Card
              type={device >= BREAKPOINTS.TABLET_PORTRAIT ? 'horizontal' : 'vertical'}
              rowStart={2}
              rowEnd={2}
              alignItems={FLEX_PROPERTIES.ALIGN_ITEMS.CENTER}
              justifyContent={FLEX_PROPERTIES.JUSTIFY_CONTENT.SPACE_BETWEEN}
              borderRadius={spacing.one}
              padding={spacing.one}
            >
              <Heading text={TITLES.DASHBOARD} type='h1' size='large' />
              {device === BREAKPOINTS.MOBILE
              && <Box width='100%' height={spacing.two} />}
              <SolidButton
                text='New Workspace'
                icon='create_new_folder'
                size='medium'
                color='primary'
                onClick={createNewWorkspace}
                rounded
                block={device === BREAKPOINTS.MOBILE}
              />
            </Card>
            <WorkSpacesContainer
              rowStart={3}
              rowEnd={3}
              columnStart={1}
              columnEnd={13}
            >
              <>
                {loadingWorkspaceList
                  ? (
                    <WrapperLoading>
                      <Loading color='primary' size='xsmall' weight='bold' />
                    </WrapperLoading>
                  )
                  : (
                    <>
                      {workspaces?.length > 0
                        ? workspaces?.map(ws => (
                          <WorkSpaceCard
                            key={ws.id}
                            id={ws.id}
                            title={ws.name}
                            size='2MB'
                            device={device}
                          />
                        ))
                        : (
                          <Text text='Empty' size='xlarge' />
                        )}
                    </>
                  )}
              </>
            </WorkSpacesContainer>
            <BidirectionalPagination
              columnStart={1}
              columnEnd={13}
              rowGrid={4}
              type={BUTTON_TYPES.SOLID}
              justifyContent={FLEX_PROPERTIES.JUSTIFY_CONTENT.CENTER}
              nextFunc={handlePaginationNext}
              previousFunc={handlePaginationPrev}
              disabledPrev={!prevLink}
              disabledNext={!nextLink}
              size='small'
            />
            <Suspense fallback={<Loading color='primary' size='xsmall' weight='bold' />}>
              <Modal
                isShowing={open}
                close={handleCloseModal}
                title={modalTitle}
                minHeight='380px'
                padding='16px'
              >
                {createWorkspace && (<CreateWorkspaceModal {...createWorkspace} />)}
              </Modal>
            </Suspense>
          </>
        )}
    </>
  )
}
export default Dashboard

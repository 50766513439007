export const key = 'MEDIA_FILES'

export const MEDIA_FILES = {
  REMOVE_MEDIA_FILE: 'MEDIAFILE/REMOVE',
  EDIT_MEDIA_FILE: 'MEDIAFILE/EDIT',
  CONVERT_MEDIA_FILE: 'MEDIAFILE/CONVERT',
  DOWNLOAD_MEDIA_FILE: 'MEDIAFILE/DOWNLOAD',
  VERSIONS_MEDIA_FILE: 'MEDIAFILE/VERSIONS',

  GET_RESOURCES: 'MEDIAFILE/GET/RESOURCES',
  RESOURCES_SUCCESS: 'MEDIAFILE/SUCCESS/RESOURCES',
  CREATE_FOLDER: 'MEDIAFILE/FOLDER/CREATE',
  EDIT_FOLDER: 'MEDIAFILE/FOLDER/EDIT',

  GET_MEDIA_FILE: 'MEDIAFILE/FILE/GET',
  GET_MEDIA_FILE_SUCCESS: 'MEDIAFILE/FILE/GET/SUCCESS',
  GET_MEDIA_FILE_ERROR: 'MEDIAFILE/FILE/GET/ERROR',

  GET_URL_MEDIA_FILE: 'MEDIAFILE/URLS/GET',
  GET_URL_MEDIA_FILE_SUCCESS: 'MEDIAFILE/URLS/GET/SUCCESS',

  SUCCESS_MESSAGE: 'MEDIAFILE/MESSAGE/SUCCESS',
  CLEAR_SUCCESS_MESSAGE: 'MEDIAFILE/MESSAGE/SUCCESS/CLEAR',
  ERROR_MESSAGE: 'MEDIAFILE/MESSAGE/ERROR',
  CLEAR_ERROR_MESSAGE: 'MEDIAFILE/MESSAGE/ERROR/CLEAR'
}

export default key

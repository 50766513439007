import React from 'react'
import PropTypes from 'prop-types'

import { COLUMN_TYPE, FLEX_PROPERTIES } from '@global/constants'

import { MyCard } from './styles'

const Card = props => {
  const {
    type,
    height,
    width,
    margin,
    justifyContent,
    alignItems,
    columnStart,
    columnEnd,
    rowStart,
    rowEnd,
    borderRadius,
    padding,
    boxShadow,
    device,
    children
  } = props

  return (
    <MyCard
      type={type}
      height={height}
      width={width}
      columnStart={columnStart}
      columnEnd={columnEnd}
      rowStart={rowStart}
      rowEnd={rowEnd}
      device={device}
      margin={margin}
      justifyContent={justifyContent}
      alignItems={alignItems}
      borderRadius={borderRadius}
      padding={padding}
      boxShadow={boxShadow}
    >
      {children}
    </MyCard>
  )
}

Card.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  justifyContent: PropTypes.oneOf([
    FLEX_PROPERTIES.JUSTIFY_CONTENT.CENTER,
    FLEX_PROPERTIES.JUSTIFY_CONTENT.FLEX_START,
    FLEX_PROPERTIES.JUSTIFY_CONTENT.FLEX_END,
    FLEX_PROPERTIES.JUSTIFY_CONTENT.SPACE_AROUND,
    FLEX_PROPERTIES.JUSTIFY_CONTENT.SPACE_BETWEEN,
    FLEX_PROPERTIES.JUSTIFY_CONTENT.SPACE_EVENLY
  ]),
  alignItems: PropTypes.oneOf([
    FLEX_PROPERTIES.ALIGN_ITEMS.BASELINE,
    FLEX_PROPERTIES.ALIGN_ITEMS.CENTER,
    FLEX_PROPERTIES.ALIGN_ITEMS.FLEX_END,
    FLEX_PROPERTIES.ALIGN_ITEMS.FLEX_START,
    FLEX_PROPERTIES.ALIGN_ITEMS.STRETCH
  ]),
  type: PropTypes.oneOf([COLUMN_TYPE.HORIZONTAL, COLUMN_TYPE.VERTICAL]),
  columnStart: PropTypes.number,
  columnEnd: PropTypes.number,
  rowStart: PropTypes.number,
  rowEnd: PropTypes.number,
  borderRadius: PropTypes.string,
  padding: PropTypes.string,
  boxShadow: PropTypes.bool,
  device: PropTypes.number,
  children: PropTypes.node
}

export default Card

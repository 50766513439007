import { spacing } from '@global/theme'
import styled from 'styled-components'

export const ProfileListContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  grid-column-start: ${({ columnStart }) => columnStart || 1};
  grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  grid-row-start: ${({ rowGrid }) => rowGrid || 1};
  grid-row-end: ${({ rowGrid }) => rowGrid || 1};
`

export const ProfileListHeader = styled.div`
  width: 100%;
  height: ${spacing.five};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${spacing.one};
  padding: ${spacing.one};

  & > span {
    color: ${({ theme }) => theme.colors.text.content}};
  }

  & > :nth-child(1) {
    grid-column-start: 2;
    grid-column-end: 5;
  }

  & > :nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 8;
  }

  & > :nth-child(3) {
    grid-column-start: 8;
    grid-column-end: 10;
  }

  & > :nth-child(4) {
    grid-column-start: 10;
    grid-column-end: 14;
  }
`

export const ProfileListDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  
  & > :first-child {
    margin-right: ${spacing.one};
  }

  & > span {
    color: ${({ theme }) => theme.colors.text.content}
  }
`

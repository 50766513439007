import React from 'react'
import PropTypes from 'prop-types'
import { Auth0Provider } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory()

  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  const onRedirectCallback = appState => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  if (!(domain && clientId && audience)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens='true'
      cacheLocation='localstorage'
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
        scope: 'offline_access openid profile email'
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node
}

export default Auth0ProviderWithHistory

import { THEME_NAME } from './constants'

// https://www.color-hex.com/color/8293ee
export const LIGHT_THEME = {
  name: THEME_NAME.LIGHT,
  colors: {
    primary: {
      main: '#3067D1',
      dark: '#274581',
      light: '#6db0ed',
      hover: '#4b92e6',
      focus: '#3576db',
      selected: '#3067d1'
    },
    secondary: {
      main: '#bf3aa9',
      dark: '#822670',
      light: '#eb87de',
      hover: '#dc59c9',
      focus: '#a72f91',
      selected: '#bf3aa9'
    },
    error: {
      main: '#CF2E2E',
      dark: '#771b1b',
      light: '#d44747',
      hover: '#cb7171',
      focus: '#CF2E2E',
      selected: '#CF2E2E'
    },
    success: {
      main: '#37A05B',
      dark: '#F5F4F3',
      light: '#E5FFEE',
      hover: '#1B7B3D',
      focus: '#37A05B',
      selected: '#1B7B3D'
    },
    warning: {
      main: '#F8C753',
      dark: '#fcb803',
      light: '#FDF1F5',
      hover: '#CEA441',
      focus: '#F8C753',
      selected: '#CEA441'
    },
    info: {
      main: '#007DBE',
      dark: '#F5F4F3',
      light: '#C6F1F1',
      hover: '#1F5D67',
      focus: '#007DBE',
      selected: '#1F5D67',
      disabled: '#DAD8D6'
    },
    text: {
      main: '#0E5E6F',
      content: '#4F4F4F', // content
      title: '#333333', // title
      light: '#FFFFFF',
      dark: '#FFFFFF',
      white: '#FFFFFF',
      disabled: '#DAD8D6'
    },
    background: {
      main: '#FFFFFF', // container
      content: '#FdFdFd',
      variant: '#F1F8FF',
      light: '#22314d',
      dark: '#1c2536',
      white: '#FFFFFF',
      disabled: '#eaeaea'
    },
    grays: {
      ultralight: '#f6f5f5',
      light: '#edebeb',
      main: '#333a45',
      dark: '#192230',
      disabled: '#DAD8D6'
    }
  }
}

export const DARK_THEME = {
  name: THEME_NAME.DARK,
  colors: {
    primary: {
      main: '#3067D1',
      dark: '#274581',
      light: '#6db0ed',
      hover: '#4b92e6',
      focus: '#3576db',
      selected: '#3067d1'
    },
    secondary: {
      main: '#bf3aa9',
      dark: '#822670',
      light: '#eb87de',
      hover: '#dc59c9',
      focus: '#a72f91',
      selected: '#bf3aa9'
    },
    error: {
      main: '#CF2E2E',
      dark: '#771b1b',
      light: '#d44747',
      hover: '#cb7171',
      focus: '#CF2E2E',
      selected: '#CF2E2E'
    },
    success: {
      main: '#37A05B',
      dark: '#F5F4F3',
      light: '#E5FFEE',
      hover: '#1B7B3D',
      focus: '#37A05B',
      selected: '#1B7B3D',
      disabled: '#DAD8D6'
    },
    warning: {
      main: '#F8C753',
      dark: '#fcb803',
      light: '#FDF1F5',
      hover: '#CEA441',
      focus: '#F8C753',
      selected: '#CEA441'
    },
    info: {
      main: '#007DBE',
      dark: '#F5F4F3',
      light: '#C6F1F1',
      hover: '#1F5D67',
      focus: '#007DBE',
      selected: '#1F5D67'
    },
    text: {
      main: '#0E5E6F',
      content: '#e6e6e6',
      title: '#C6cdd2',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      white: '#FFFFFF',
      disabled: '#333a45'
    },
    background: {
      main: '#0e1320', // container
      content: '#111927',
      variant: '#1c2536', // navbar
      dark: '#1c2536',
      light: '#22314d',
      white: '#FFFFFF',
      disabled: '#181f2a'
    },
    grays: {
      ultralight: '#f6f5f5',
      light: '#edebeb',
      main: '#333a45',
      dark: '#192230',
      disabled: '#DAD8D6'
    }
  }

}

export const colors = {
  primary: {
    main: '#0E5E6F',
    dark: '#10A19D',
    ligth: '#A7D2CB',
    hover: '#43727c',
    focus: '#0E5E6F',
    selected: '#10A19D',
    disabled: '#DAD8D6'
  },
  secondary: {
    main: '#FAC213',
    dark: '#FCBD8A',
    ligth: '#FFEDDE',
    hover: '#FFB100',
    focus: '#D61C4E',
    selected: '#FEF9A7',
    disabled: '#DAD8D6'
  },
  tertiary: {
    main: '#4F4F4F',
    dark: '#DAD8D6',
    ligth: '#F2F6FF',
    hover: '#F2F6FF',
    focus: '#333333',
    selected: '#F2F6FF',
    disabled: '#DAD8D6'
  },
  variant: {
    main: '#4158D0',
    dark: '#283EB3',
    ligth: '#17277A',
    hover: '#141C46',
    focus: '#4158D0',
    selected: '#1F5D67',
    disabled: '#DAD8D6'
  },
  error: {
    main: '#CF2E2E',
    dark: '#F5F4F3',
    ligth: '#F3CFD2',
    hover: '#FFE6EC',
    focus: '#CF2E2E',
    selected: '#FFE6EC',
    disabled: '#DAD8D6'
  },
  success: {
    main: '#37A05B',
    dark: '#F5F4F3',
    ligth: '#E5FFEE',
    hover: '#1B7B3D',
    focus: '#37A05B',
    selected: '#1B7B3D',
    disabled: '#DAD8D6'
  },
  warning: {
    main: '#F8C753',
    dark: '#F5F4F3',
    ligth: '#FDF1F5',
    hover: '#CEA441',
    focus: '#F8C753',
    selected: '#CEA441',
    disabled: '#DAD8D6'
  },
  info: {
    main: '#007DBE',
    dark: '#F5F4F3',
    ligth: '#C6F1F1',
    hover: '#1F5D67',
    focus: '#007DBE',
    selected: '#1F5D67',
    disabled: '#DAD8D6'
  },
  contrast: {
    main: '#3A3A3A',
    disabled: '#E4E4E4'
  },
  text: {
    main: '#4F4F4F',
    dark: '#F5F4F3',
    ligth: '#DAD8D6',
    hover: '#4F4F4F',
    focus: '#696969',
    selected: '#4F4F4F',
    disabled: '#DAD8D6'
  },
  background: {
    primary: '#F2F6F8',
    secondary: '#F6F5F5',
    main: '#FFFFFF',
    dark: '#565656',
    hover: '#F1F1F1',
    disabled: '#FAFAFA'
  },
  events: {
    inprogress: '#37A05B',
    next: '#00769D',
    finished: '#696969',
    suspended: '#F8C753',
    cancelled: '#C43D48',
    white: '#FFFFFF'
  },
  grays: {
    ultralight: '#FAFAFA',
    light: '#E8E8E8',
    main: '#828282',
    dark: '#7c2ecf'
  }
}

export const spacing = {
  quarter: '2px',
  half: '4px',
  threeQuarters: '6px',
  one: '8px',
  oneAndAQuarter: '10px',
  oneAndAHalf: '12px',
  two: '16px',
  twoAndAQuarter: '18px',
  twoAndAHalf: '20px',
  three: '24px',
  threeAndAHalf: '28px',
  four: '32px',
  fourAndAHalf: '36px',
  five: '40px',
  fiveAndAHalf: '44px',
  six: '48px',
  sixAndAHalf: '52px',
  seven: '56px',
  sevenAndAHalf: '60px',
  eight: '64px',
  eightAndAHalf: '68px',
  nine: '72px',
  nineAndAHalf: '76px',
  ten: '80px',
  tenAndAHalf: '84px',
  eleven: '88px',
  elevenAndAHalf: '92px',
  twelve: '96px',
  twelveAndAHalf: '100px',
  thirteen: '104px',
  fourteen: '112x',
  fifteen: '120px'
}

export const page = {
  width: '1200px'
}

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { SITE } from '@routes/paths'

import { Text } from '@baseComponents/texts'

import {
  CardWorkSpaceContainer,
  CardWorkSpaceHeader,
  CardWorkSpacColumn
} from './styles'

const WorkSpaceCard = ({ id, title, size, device }) => {
  const history = useHistory()
  const handleClic = () => {
    history.push(`${SITE.DASHBOARD}${SITE.WORKSPACE}/${id}`)
  }

  return (
    <CardWorkSpaceContainer device={device} onClick={handleClic}>
      <CardWorkSpaceHeader />
      <CardWorkSpacColumn>
        <Text text={title} size='xlarge' />
        <Text text={size} />
      </CardWorkSpacColumn>
    </CardWorkSpaceContainer>
  )
}

WorkSpaceCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  device: PropTypes.number
}

export default WorkSpaceCard

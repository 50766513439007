import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectWorkspace = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectWorkspace, state => get(state, 'loading', null)
)

const selectWorkspaceData = createSelector(
  selectWorkspace, state => get(state, 'workspace', null)
)

const selectLoadingSummary = createSelector(
  selectWorkspace, state => get(state, 'loadingSummary', null)
)

const selectSummary = createSelector(
  selectWorkspace, state => get(state, 'summary', null)
)

const selectErrorSummary = createSelector(
  selectWorkspace, state => get(state, 'errorSummary', null)
)

const selectLoadingDataSummary = createSelector(
  selectWorkspace, state => get(state, 'loadingDataSummary', null)
)

const selectMessageSuccess = createSelector(
  selectWorkspace, state => get(state, 'messageSuccess', null)
)

const selectMessageError = createSelector(
  selectWorkspace, state => get(state, 'messageError', null)
)

export default {
  LOADING: selectLoading,
  WORKSPACE: selectWorkspaceData,
  LOADING_SUMMARY: selectLoadingSummary,
  SUMMARY: selectSummary,
  ERROR_SUMMARY: selectErrorSummary,
  LOADING_DATA_SUMMARY: selectLoadingDataSummary,
  SUCCESS_MESSAGE: selectMessageSuccess,
  ERROR_MESSAGE: selectMessageError
}

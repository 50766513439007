import { BREAKPOINTS } from '@global/constants'
import { spacing } from '@global/theme'
import styled from 'styled-components'

export const FormContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${spacing.two};

  grid-column-start: ${({ columnStart }) => columnStart || 1};
  grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  grid-row-start: ${({ gridRow }) => gridRow || 1};
  grid-row-end: ${({ gridRow }) => gridRow || 1};
`

export const WorkspaceButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  margin-top: ${({ marginTop }) => marginTop || 0};
  flex: 1;
  & > button {
    margin-left: ${({ device }) => (device <= BREAKPOINTS.TABLET_PORTRAIT ? 0 : spacing.two)};
    margin-bottom: ${({ device }) => (device <= BREAKPOINTS.TABLET_PORTRAIT ? spacing.one : 0)};
  }
`

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  margin-top: ${({ marginTop }) => marginTop || 0};
`

import { USER } from './types'

export const getUser = payload => ({
  type: USER.GET,
  payload
})

export const getUserSuccess = payload => ({
  type: USER.SUCCESS,
  payload
})

export const getUserError = payload => ({
  type: USER.ERROR,
  payload
})

export const clearUser = () => ({
  type: USER.CLEAR
})

export const passwordRcovery = payload => ({
  type: USER.PASSWORD_RECOVERY,
  payload
})

export const passwordConfirm = payload => ({
  type: USER.PASSWORD_CONFIRM,
  payload
})

export const messagePasswordConfirm = payload => ({
  type: USER.MESSAGE_PASSWORD_CONFIRM,
  payload
})

export const refreshUser = payload => ({
  type: USER.REFRESH_USER,
  payload
})

export const errorUserRefresh = payload => ({
  type: USER.ERROR_USER_REFRESH,
  payload
})

export const sendDataUser = payload => ({
  type: USER.SEND_DATA_USER,
  payload
})

export const sendDataUserSuccess = () => ({
  type: USER.SEND_DATA_USER_SUCCESS
})

export const sendDataUserError = () => ({
  type: USER.SEND_DATA_USER_ERROR
})

export const USER_ACTIONS = {
  GET: getUser,
  SUCCESS: getUserSuccess,
  ERROR: getUserError,
  CLEAR: clearUser,
  PASWORD_RECOVERY: passwordRcovery,
  PASWORD_CONFIRM: passwordConfirm,
  RESPONSE_PASSWORD_CONFIRM: messagePasswordConfirm,
  REFRESH_USER: refreshUser,
  ERROR_USER_REFRESH: errorUserRefresh,
  SEND_DATA_USER: sendDataUser,
  SEND_DATA_USER_SUCCESS: sendDataUserSuccess,
  SEND_DATA_USER_ERROR: sendDataUserError
}

import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { MyButtonLink, WrapperLoading } from '@global/styles'
import { FUNCTION_TYPE, TEXT_CONTENT } from '@global/constants'

import Icon from '@baseComponents/icon'
import { Text } from '@baseComponents/texts'
import { useUser } from '@containers/authentication/utils/hook'
import Loading from '@components/loading'
import Modal from '@components/modal'

import useRedux from '../../redux'
import SELECTORS from '../../redux/selectors'
import { WORKSPACE_PROFILE_ACTIONS } from '../../redux/actions'

import { AccordionContainer, AccordionSummary, AccordionDetails } from './styles'
import OperationCard from '../operationCard'
import AddOperationModal from '../addOperationModal'

const AccordionProfile = ({ profileId, title, numOperation, handleWorkspaceProfile }) => {
  useRedux()
  const dispatch = useDispatch()
  const { GetToken } = useUser()

  const [open, setOpen] = useState(false)
  const [operations, setOperations] = useState(null)
  const [profilesOpen, setProfilesOpen] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [addOperationModal, setAddOperationModal] = useState(null)

  const loading = useSelector(SELECTORS.LOADING_PROFILE_OPERATIONS)
  const operationList = useSelector(SELECTORS.PROFILE_OPERATIONS)
  const workspaceProfiles = useSelector(SELECTORS.WORKSPACE_PROFILES)

  const handleOpenAccordion = async e => {
    e.stopPropagation()
    if (!profilesOpen.find(p => p === profileId)) {
      const token = await GetToken()
      dispatch(WORKSPACE_PROFILE_ACTIONS.GET_PROFILE_OPERATION({ token, profileId }))
      setProfilesOpen([...profilesOpen, profileId])
    }
    setOpen(!open)
  }

  const handleEditProfile = e => {
    e.stopPropagation()
    handleWorkspaceProfile(FUNCTION_TYPE.EDIT.name,
      { id: profileId, name: title, oldProfiles: workspaceProfiles })
  }

  const handleCloseModal = () => {
    setAddOperationModal(null)
    setOpenModal(false)
  }

  const handleAddOperation = e => {
    e.stopPropagation()
    setAddOperationModal({
      profileId,
      successResult: () => {},
      close: handleCloseModal,
      oldProfiles: workspaceProfiles,
      accordionOpen: open
    })
    setOpenModal(true)
  }

  useEffect(() => {
    if (operationList) {
      setOperations(operationList)
    }
  }, [operationList])

  return (
    <>
      <AccordionContainer onClick={handleOpenAccordion}>
        <AccordionSummary>
          <Icon name={open ? 'remove' : 'add'} color='primary' size='large' />
          <Text text={title} size='medium' />
          <Text text={`${numOperation}`} size='medium' />
          <MyButtonLink onClick={handleAddOperation} color='primary' underline>
            {`+ ${TEXT_CONTENT.ADD_OPERATION}`}
          </MyButtonLink>
          <Icon name='edit' color='primary' size='large' onClick={handleEditProfile} />
        </AccordionSummary>
        <AccordionDetails open={open} numberOperations={numOperation} onClick={e => e.stopPropagation()}>
          {loading
            ? (
              <WrapperLoading>
                <Loading color='primary' size='xsmall' weight='bold' />
              </WrapperLoading>
            )
            : (
              !!operations && operations[profileId]?.map(op => (
                <OperationCard
                  key={op.id}
                  operationId={op.id}
                  name={op.name}
                  description={op.description}
                  command={op.command}
                  status={op.status}
                  profileId={profileId}
                />
              ))
            )}
        </AccordionDetails>
      </AccordionContainer>
      <Suspense fallback={<Loading color='primary' size='xsmall' weight='bold' />}>
        <Modal
          isShowing={openModal}
          close={handleCloseModal}
          title={TEXT_CONTENT.ADD_OPERATION.toUpperCase()}
          minHeight='auto'
        >
          {addOperationModal && (<AddOperationModal {...addOperationModal} />)}
        </Modal>
      </Suspense>
    </>
  )
}

AccordionProfile.propTypes = {
  profileId: PropTypes.string,
  title: PropTypes.string,
  numOperation: PropTypes.number,
  handleWorkspaceProfile: PropTypes.func
}

export default AccordionProfile

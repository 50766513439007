import { spacing } from '@global/theme'
import styled from 'styled-components'

export const MediaFileCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${spacing.one};
  grid-row-gap: ${spacing.two};
  align-items: start;

  grid-column-start: ${({ columnStart }) => columnStart || 1};
  grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  grid-row-start: ${({ rowGrid }) => rowGrid || 1};
  grid-row-end: ${({ rowGrid }) => rowGrid || 1};
`

import styled from 'styled-components'

import { spacing } from '@global/theme'
import { THEME_NAME } from '@global/constants'

export const ImageZoomInOutContainer = styled.div`
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.light
    return theme.colors.grays.dark
  }};
  position: relative;
  overflow: hidden;
  margin-bottom: ${spacing.two};
  width: 100%;
  height: 100%;
`

export const ButtonZoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.one};
  padding: ${spacing.quarter};
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 10;
  & > span {
    color:${({ theme }) => theme.colors.background.white};
    background: #80808078;
    &:active {
      color:${({ theme }) => theme.colors.primary.main};
    }
  }
`

export const PreviewImg = styled.img`
  height: auto;
  width: 100%;
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.light
    return theme.colors.grays.dark
  }};
  cursor: move;
  transform: ${({ scale, position }) => `scale(${scale}) translate(${position.x}px,${position.y}px)`};
`

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { SITE } from '@routes/paths'
import { useUser } from '@containers/authentication/utils/hook'
import PublicLayout from '@routes/components/layouts/publicLayout'

const PublicRouteWrapper = ({ component: Component, ...rest }) => {
  const { GetUser } = useUser()
  const user = GetUser()
  return (
    <Route
      {...rest}
      render={props => (
        <PublicLayout>
          {!user ? <Component {...props} /> : <Redirect to={SITE.DASHBOARD} />}
        </PublicLayout>
      )}
    />
  )
}

PublicRouteWrapper.propTypes = {
  component: PropTypes.any
}

export default PublicRouteWrapper

// https://www.htmlquick.com/es/reference/mime-types.html
// it can't use @assets because tests does not work

import logoPdf from '../../assets/pdf-logo.png'
import logoExcel from '../../assets/excel-logo.png'
import logoWord from '../../assets/word-logo.png'
import logoTxt from '../../assets/txt-file-logo.png'
import logoZip from '../../assets/zip-logo.png'
import logoVideoMp4 from '../../assets/video-mp4-logo.png'
import logoImage from '../../assets/logo-image.png'

export const ACCEPTED_FILE_TYPES = [
  { name: '.xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', logo: logoExcel },
  { name: '.xlsx', type: 'application/vnd.ms-excel', logo: logoExcel },
  { name: '.docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', logo: logoWord },
  { name: '.pdf', type: 'application/pdf', logo: logoPdf },
  { name: 'video/mp4', type: 'video/mp4', logo: logoVideoMp4 },
  { name: '.7zip', type: 'application/x-7z-compressed', logo: logoZip },
  { name: '.zip', type: 'application/zip', logo: logoZip },
  { name: '.txt', type: 'text/plain', logo: logoTxt },
  { name: '.jpeg', type: 'image/jpeg', logo: logoImage },
  { name: '.png', type: 'image/png', logo: logoImage },
  { name: '.tif', type: 'image/tif', logo: logoImage },
  { name: '.bmp', type: 'image/bmp', logo: logoImage }
]

export const ERROR_MESSAGE_FILES = {
  EXEEDED_SIZE: 'El tamaño del archivo excede lo permitido.',
  INVALID_TYPE: 'Tipo de archivo inválido.',
  DUPLICATE_FILE: 'Archivo duplicado.'
}

export const FILE_UPLOADER_TEXTS = {
  TYPE_ACCEPTED: 'Allowed types:',
  UPLOAD_FILES: 'Upload or drag files.',
  FILES_ACCEPTED: 'Allowed files:',
  FILES_NO_ACCEPTED: 'Files not accepted:'
}

import styled from 'styled-components'

import { spacing } from '@global/theme'
import { SourceCodePro, WEIGHT } from '@global/fonts'
import { THEME_NAME } from '@global/constants'

export const Wrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};
  height: ${({ height }) => {
    let totalHeight = 60
    switch (height) {
      case 'small':
        totalHeight += +spacing.threeAndAHalf.slice(0, -2)
        break
      case 'large':
        totalHeight += +spacing.fiveAndAHalf.slice(0, -2)
        break
      default:
        totalHeight += +spacing.five.slice(0, -2)
    }
    return totalHeight
  }}px;
`

export const Label = styled.label`
  ${SourceCodePro}
  font-size: ${spacing.oneAndAHalf};
  line-height: ${spacing.twoAndAHalf};
  margin-left: ${spacing.one};
  margin-bottom: ${spacing.half};
  display: block;
  font-weight: ${WEIGHT.main};
  color: ${({ color, error, disabled, theme }) => {
    if (disabled) return theme.colors.text.disabled
    if (error) return theme.colors.error.main
    return theme.colors[color]?.main || theme.colors.text.content
  }
};
  > span {
    color: ${({ theme }) => theme.colors.error.main};
  }

`

export const Message = styled.small`
  ${SourceCodePro};
  font-size: ${spacing.oneAndAHalf};
  margin-left: ${spacing.one};
  padding-top: ${spacing.half};
  display: block;
  font-size: ${spacing.oneAndAHalf};
  line-height:  ${spacing.two};
  font-weight: ${WEIGHT.REGULAR};

  color: ${({ color, error, focus, disabled, theme }) => {
    if (disabled) return theme.colors.text.disabled || theme.colors[color]?.disabled
    if (error) return theme.colors.error.main
    if (focus) return theme.colors[color]?.main
    return theme.colors.text.content
  }
};
`

export const Container = styled.div`
  background-color: ${({ theme, disabled }) => {
    if (disabled) {
      if (theme.name === THEME_NAME.DARK) return theme.colors.background.disabled
      return 'transparent'
    }
    return theme.colors.background.content
  }}!important;
  }};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  outline: ${({ error }) => (error ? spacing.quarter : '1px')} solid ${({ error, theme }) => {
  if (error) return theme.colors.error.main
  if (theme.name === THEME_NAME.DARK) return theme.colors.grays.main
  return theme.colors.text.disabled
}};

  border-radius: ${spacing.quarter};
  box-shadow: 0 1px ${spacing.half} 0 rgb(0 0 0 / 0.05);
  
  &:focus-within {
    outline: 2px solid ${({ color, error, theme }) => {
    if (error) return theme.colors.error.main
    if (color) return theme.colors[color]?.main
    return theme.colors.text.main
  }};
    outline-offset: 1px;
    * > span {
      color: ${({ color, theme }) => (color ? theme.colors[color]?.main : theme.colors.primary.main)}!important;
    }
  }

  overflow: hidden;
  padding: 0 ${spacing.oneAndAHalf};
 
  > * {
    margin-left: ${spacing.oneAndAHalf};
  }

  cursor: ${({ disabled }) => disabled && 'default'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.background.content};
  ${SourceCodePro};
  appearance: none;
  display: block;
  width: 100%;
  height: ${({ height }) => {
    switch (height) {
      case 'small':
        return spacing.threeAndAHalf
      case 'large':
        return spacing.fiveAndAHalf
      default:
        return spacing.five
    }
  }};

  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};
  margin-left: ${({ paddingLeft }) => (paddingLeft ? spacing.one : 0)};

  border-style: none;

  user-select: ${({ type }) => type === 'number' && 'none'}; // does not work with chrome

  color: ${({ error, disabled, theme }) => {
    if (disabled) return theme.colors.text.disabled
    if (error) return theme.colors.error.main
    return theme.colors.text.content
  }
};
  text-align: ${({ type }) => type === 'number' && 'center'};

  &:focus {
    outline: ${spacing.quarter} solid transparent;
    outline-offset: ${spacing.quarter};  
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
  }

  &:disabled {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.background.disabled
    return 'transparent'
  }};
  }

  &::input:-webkit-autofill {
  -webkit-text-fill-color: #31b0dd;
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}

&:-webkit-autofill,
&:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
`

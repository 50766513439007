export const key = 'WORKSPACE_PROFILE'

export const WORKSPACE_PROFILE = {
  LOADING: 'WORKSPACE_PROFILE/LOADING',
  LOADING_FALSE: 'WORKSPACE_PROFILE/LOADING_FALSE',
  GET_WORKSPACE_PROFILES: 'WORKSPACE_PROFILE/GET/PROFILES',
  WORKSPACE_PROFILES_SUCCESS: 'WORKSPACE_PROFILE/GET/PROFILES/SUCCESS',
  CREATE_WORKSPACE_PROFILE: 'WORKSPACE_PROFILE/CREATE/PROFILE',
  EDIT_WORKSPACE_PROFILE: 'WORKSPACE_PROFILE/EDIT/PROFILE',
  REMOVE_WORKSPACE_PROFILE: 'WORKSPACE_PROFILE/REMOVE/PROFILE',

  GET_PROFILE_OPERATION: 'WORKSPACE_PROFILE/GET/OPERATION',
  PROFILE_OPERATION_SUCCESS: 'WORKSPACE_PROFILE/OPERATION/SUCCESS',
  REMOVE_PROFILE_OPERATION: 'WORKSPACE_PROFILE/OPERATION/REMOVE',
  CREATE_PROFILE_OPERATION: 'WORKSPACE_PROFILE/OPERATION/CREATE',
  ADD_PROFILE_OPERATION: 'WORKSPACE_PROFILE/OPERATION/ADD',
  GET_ALL_OPERATIONS: 'WORKSPACE_PROFILE/ALL_OPERATIONS/GET',
  ALL_OPERATIONS_SUCCESS: 'WORKSPACE_PROFILE/ALL_OPERATIONS/GET/SUCCESS',

  SUCCESS_MESSAGE: 'WORKSPACE_PROFILE/SUCCESS_MESSAGE',
  CLEAR_SUCCESS_MESSAGE: 'WORKSPACE_PROFILE/SUCCESS_MESSAGE/CLEAR',
  ERROR_MESSAGE: 'WORKSPACE_PROFILE/ERROR_MESSAGE',
  CLEAR_ERROR_MESSAGE: 'WORKSPACE_PROFILE/ERROR_MESSAGE/CLEAR'
}

export default key

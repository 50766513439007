import styled, { css } from 'styled-components'

import { spacing } from '@global/theme'
import { SourceCodePro, large, bold } from '@global/fonts'
import { LINE_WIDTH } from '@global/constants'

const getLineWidth = width => {
  const s = (() => {
    switch (width) {
      case LINE_WIDTH.NORMAL: return '60%'
      case LINE_WIDTH.WIDE: return '40%'
      default:
        return '80%'
    }
  })()

  return css`
    width: ${s};
    height: ${s};
  `
}

export const LoaderContainer = styled.div`
  width: 100%;
  height: ${({ height }) => `${height}px` || '200px'};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing.one};
`

export const Wrapper = styled.div`
 ${SourceCodePro};
  height: ${({ height }) => `${height}px` || '200px'};
  width: ${({ width }) => `${width}px` || '200px'};
  background-color: transparent;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: ${spacing.one};
  display: grid;
  place-items: center;
`

export const CircularProgress = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  ${({ theme, fullLoadcolor, emptyLoadColor, progressValue }) => (
    `background: conic-gradient(
        ${theme.colors[fullLoadcolor]?.main ?? theme.colors.primary.main} ${progressValue * 3.6}deg,
        ${theme.colors[emptyLoadColor]?.main ?? theme.colors.grays.main} ${progressValue * 3.6}deg)`
  )};
  border-radius: 50%;
  display: grid;
  place-items: center;

  &::before {
    content: "";
    position: absolute;
    ${({ lineWidth }) => getLineWidth(lineWidth)};
    background-color: ${({ theme, backgroundColor }) => {
    if (backgroundColor) return theme.colors[backgroundColor].main
    return theme.colors.background.main
  }};
    border-radius: 50%;
  }
`

export const ValueContainer = styled.div`
  position: relative;
  & > p {
    ${bold};
    ${large};
  }
`

import produce from 'immer'

import { DASHBOARD } from './types'

export const initialState = {
  loading: true,
  error: false,
  workspaceList: null,
  loadingWorkspaceList: false,
  workspace: null,
  loadingWorkspace: false,
  errorMessage: null,
  operationList: null,
  loadingOperationList: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case DASHBOARD.GET:
      draft.loadingWorkspaceList = true
      break
    case DASHBOARD.SUCCESS:
      draft.loading = false
      draft.loadingWorkspaceList = false
      draft.workspaceList = payload
      break
    case DASHBOARD.ERROR:
      draft.loading = false
      draft.loadingWorkspaceList = false
      draft.error = payload
      break
    case DASHBOARD.CLEAR:
      draft.workspaceList = null
      break
    case DASHBOARD.CREATE_WORKSPACE:
      draft.loadingWorkspace = true
      break
    case DASHBOARD.WORKSPACE_SUCCESS:
      draft.loadingWorkspace = false
      draft.workspace = payload
      break
    case DASHBOARD.ERROR_MESSAGE:
      draft.errorMessage = payload
      break
    case DASHBOARD.ERROR_MESSAGE_CLEAR:
      draft.errorMessage = null
      break
    case DASHBOARD.GET_OPERATIONS:
      draft.loadingOperationList = true
      break
    case DASHBOARD.OPERATIONS_SUCCESS:
      draft.loadingOperationList = false
      draft.operationList = payload
      break
    default:
      return initialState
  }
})

export default reducer

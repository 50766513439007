import React from 'react'
import PropTypes from 'prop-types'

import MyProptypes from '@global/propTypes'
import { SIZE } from '@global/fonts'

import Icon from 'src/baseComponents/icon'
import { Text } from 'src/baseComponents/texts'

import { Wrapper, Img } from './styles'

export const Bit = ({ icon, text, color, disabled, onClick, image, alt, inputType, spin }) => (
  <Wrapper onClick={onClick} color={color} tabIndex='-1' disabled={disabled} inputType={inputType}>
    {icon && <Icon name={icon} size='large' disabled={disabled} color={color} spin={spin} />}
    {text && <Text text={text} size='large' disabled={disabled} />}
    {image && <Img src={image} alt={alt} size={SIZE.XLARGE} disabled={disabled} />}
  </Wrapper>
)

Bit.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  color: MyProptypes.color,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  image: PropTypes.string,
  alt: PropTypes.string,
  inputType: PropTypes.string,
  spin: PropTypes.bool
}

export default Bit

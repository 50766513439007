import React from 'react'
import PropTypes from 'prop-types'

import { TEXT_CONTENT } from '@global/constants'
import { LineButton } from '@baseComponents/button'

import { FormMenuItemStyle } from '../styles'

const FooterMenuForm = ({ logout, close }) => {
  const handleLogout = () => {
    close()
    logout()
  }

  return (
    <FormMenuItemStyle>
      <LineButton
        text={TEXT_CONTENT.LOG_OUT}
        rounded
        color='primary'
        size='small'
        block
        onClick={handleLogout}
        icon='logout'
      />
    </FormMenuItemStyle>
  )
}

FooterMenuForm.propTypes = {
  logout: PropTypes.func,
  close: PropTypes.func
}
export default FooterMenuForm

import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectMediaFile = state => get(state, key, initialState)

const selectLoadingResources = createSelector(
  selectMediaFile, state => get(state, 'loadingResources', null)
)

const selectResources = createSelector(
  selectMediaFile, state => get(state, 'resources', null)
)

const selectMediaFileData = createSelector(
  selectMediaFile, state => get(state, 'mediaFile', null)
)

const selectUrlMediaFileData = createSelector(
  selectMediaFile, state => get(state, 'urlMediaFile', null)
)

const selectMessageSuccess = createSelector(
  selectMediaFile, state => get(state, 'messageSuccess', null)
)

const selectMessageError = createSelector(
  selectMediaFile, state => get(state, 'messageError', null)
)

export default {
  LOADING_RESOURCES: selectLoadingResources,
  RESOURCES: selectResources,
  MEDIA_FILE: selectMediaFileData,
  URL_MEDIA_FILE: selectUrlMediaFileData,
  SUCCESS_MESSAGE: selectMessageSuccess,
  ERROR_MESSAGE: selectMessageError
}

import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, FUNCTION_TYPE } from '@global/constants'

import { SolidButton, GhostButton } from 'src/baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'

import { Text } from 'src/baseComponents/texts'
import { ModalDataContainer, ButtonContainer } from './styles'

const DeleteConfirmationModal = ({ close, text, itemId, removeItem, dataType }) => {
  const { device } = useWindowDimensions()
  const handleCancelClick = () => {
    close(false)
  }
  const handleClick = () => {
    close(false)
    removeItem(itemId, dataType)
  }

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <Text size='large' align='left'>
          {text}
        </Text>
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' device={device}>
        <GhostButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text={FUNCTION_TYPE.CANCEL.label}
          onClick={handleCancelClick}
          block={device === BREAKPOINTS.MOBILE}
        />
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text={FUNCTION_TYPE.DELETE.label}
          onClick={handleClick}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
    </>

  )
}

export default DeleteConfirmationModal

DeleteConfirmationModal.propTypes = {
  close: PropTypes.func,
  text: PropTypes.string,
  itemId: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]),
  removeItem: PropTypes.func,
  dataType: PropTypes.string
}

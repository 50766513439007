import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { spacing } from '@global/theme'
import { ButtonModalContainer, ModalDataContainer } from '@global/styles'
import {
  BREAKPOINTS,
  COLUMN_TYPE,
  FLEX_PROPERTIES,
  FUNCTION_TYPE,
  INITIAL_STATES,
  RADIO_BUTTON_STATUS_OPTIONS,
  SUBTITLES,
  TEXT_CONTENT,
  TOAST_POSITION,
  TOAST_PROPERTIES
} from '@global/constants'

import { SolidButton } from '@baseComponents/button'
import { TextInput } from '@components/inputs/inputs'
import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'
import RadioInputs from '@components/inputs/inputs/components/radio'
import { useNotification } from 'src/context/notification.context'

import useRedux from '@containers/fileManager/redux'
import { DASHBOARD_ACTIONS } from '@containers/dashboard/redux/actions'
import SELECTORS from '@containers/dashboard/redux/selectors'
import SelectInput from '@components/select'
import { Text } from '@baseComponents/texts'
import Loading from '@components/loading'

const CreateWorkspaceModal = ({ successResult, close }) => {
  useRedux()
  const { device } = useWindowDimensions()
  const { GetToken, GetUser } = useUser()
  const dispatch = useDispatch()
  const { getNotification } = useNotification()
  const { username } = GetUser()

  const [folderName, setFolderName] = useState(INITIAL_STATES.INPUT)
  const [description, setDescription] = useState(INITIAL_STATES.INPUT)
  const [radioButtonSelected, setRadioButtonSelected] = useState(null)
  const [showButton, setShowButton] = useState(true)
  const [selectedValues, setSelectedValues] = useState([])
  const [operationList, setOperationList] = useState([])

  const createWorkspace = useSelector(SELECTORS.WORKSPACE_SUCCESS)
  const errorMesasage = useSelector(SELECTORS.ERROR_MESSAGE)
  const operationListData = useSelector(SELECTORS.OPERATION_LIST)
  const loadingOperationList = useSelector(SELECTORS.LOADING_OPERATION_LIST)

  const handleInputChange = val => {
    const { name } = val
    if (name === SUBTITLES.NAME) setFolderName(val)
    else setDescription(val)
  }

  const handleChangeRadioButtons = value => setRadioButtonSelected(value)

  const handleSelectChange = values => {
    const valuesMapped = values
    setSelectedValues(valuesMapped.map(v => v.value))
  }

  const handleClick = async () => {
    const data = {
      name: folderName.value,
      description: description.value,
      status: radioButtonSelected,
      owner: username,
      operations: selectedValues
    }
    const token = await GetToken()
    dispatch(DASHBOARD_ACTIONS.CREATE_WORKSPACE({ token, data }))
  }

  const errorControl = () => {
    if ((folderName.value && !folderName.error) && radioButtonSelected) setShowButton(false)
    else setShowButton(true)
  }

  useEffect(async () => {
    const token = await GetToken()
    dispatch(DASHBOARD_ACTIONS.GET_OPERATIONS(token))
  }, [])

  useEffect(() => {
    if (operationListData) {
      setOperationList(operationListData)
    }
  }, [operationListData])

  useEffect(() => {
    if (createWorkspace) {
      successResult(createWorkspace?.id)
      close()
    }
  }, [createWorkspace])

  useEffect(() => {
    errorControl()
  }, [folderName, radioButtonSelected])

  useEffect(() => {
    if (errorMesasage) {
      getNotification({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMesasage?.message || errorMesasage?.status,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color,
        position: TOAST_POSITION.leftTop,
        open: true
      })
    }
  }, [errorMesasage])

  return (
    <ModalDataContainer flexDirection='column'>
      <RadioInputs
        type={device === BREAKPOINTS.MOBILE ? COLUMN_TYPE.VERTICAL : COLUMN_TYPE.HORIZONTAL}
        name='statusFolder'
        options={RADIO_BUTTON_STATUS_OPTIONS}
        onChange={handleChangeRadioButtons}
        alignItems={device === BREAKPOINTS.MOBILE ? FLEX_PROPERTIES.ALIGN_ITEMS.FLEX_START : 'none'}
      />
      <TextInput
        name={SUBTITLES.NAME}
        label={SUBTITLES.NAME}
        onChange={handleInputChange}
        color='primary'
        value={folderName.value}
      />
      <TextInput
        name={SUBTITLES.DESCRIPTION}
        label={SUBTITLES.DESCRIPTION}
        onChange={handleInputChange}
        color='primary'
        value={description.value}
      />
      <Text size='large' weight='bold' marginBottom={spacing.one}>{SUBTITLES.ADD_OPERATIONS.toUpperCase()}</Text>
      {loadingOperationList
        ? (
          <Loading color='primary' size='xsmall' weight='bold' />
        )
        : (
          <SelectInput
            label={SUBTITLES.OPERATIONS}
            onChange={handleSelectChange}
            options={operationList}
            size='large'
            color='primary'
            isMulti
            loadingMessage={() => <Text weight='bold'>{TEXT_CONTENT.LOADING}...</Text>}
            noOptionsMessage={() => <Text weight='bold' align='center'>{TEXT_CONTENT.NO_OPTIONS}</Text>}
          />
        )}

      <ButtonModalContainer oneButton marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'large' : 'medium'}
          color='primary'
          text={FUNCTION_TYPE.SAVE.label}
          icon='upload'
          onClick={handleClick}
          block={device === BREAKPOINTS.MOBILE}
          disabled={showButton}
        />
      </ButtonModalContainer>
    </ModalDataContainer>
  )
}

CreateWorkspaceModal.propTypes = {
  successResult: PropTypes.func,
  close: PropTypes.func
}

export default CreateWorkspaceModal

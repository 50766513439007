import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { SourceCodePro, medium, bold } from '@global/fonts'
import { spacing } from '@global/theme'

export const MyLink = styled(NavLink)`
  ${SourceCodePro};
  ${medium};
  ${bold};
  color: ${({ color, theme }) => {
    if (color) return theme.colors[color].main
    return theme.colors.text.content
  }};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const BreadcrumbsStyle = styled.div`
  ${SourceCodePro};
  display: flex;
  flex-wrap: wrap;

  & p#symbol {
    margin: 0 ${spacing.one}
  };

  & > :first-child {
    & > a {
      margin-left: 0;
    } 
  }
`

export const Crumb = styled.div`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
`

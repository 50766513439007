import React from 'react'
import PropTypes from 'prop-types'

import backgroundLogo from '@assets/background-logo.png'

import { APP_NAME, BREAKPOINTS, THEME_MODE, THEME_NAME } from '@global/constants'
import { Heading } from 'src/baseComponents/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import {
  AuhtContainerStyle,
  ContainerImage,
  Logo
} from './styles'

const AuthContainer = ({ children }) => {
  const { device } = useWindowDimensions()
  const theme = localStorage.getItem(THEME_MODE)

  return (
    <>
      {(device > BREAKPOINTS.TABLET)
       && (
       <ContainerImage device={device}>
         <Heading
           size='xlarge'
           type='h1'
           weight='bold'
           color={theme === THEME_NAME.LIGHT ? 'background' : 'primary'}
           align='center'
         >
           {APP_NAME}
         </Heading>
         <Logo src={backgroundLogo} alt='logo' />
       </ContainerImage>
       )}
      <AuhtContainerStyle device={device} name='containerLogin' color={device >= BREAKPOINTS.DESKTOP && 'background'}>
        {children}
      </AuhtContainerStyle>
    </>
  )
}

AuthContainer.propTypes = {
  children: PropTypes.node
}

export default AuthContainer

import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { spacing } from '@global/theme'
import { semibold, SourceCodePro } from '@global/fonts'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const StyledNavLink = styled(NavLink)`
  width: auto;
  text-decoration: none;
  color: ${({ theme, color }) => (color ? theme[color]?.main : theme.colors.primary.main)};
  ${semibold};
  ${SourceCodePro};
  
  &:hover {
    color: ${({ theme, color }) => (color ? theme[color]?.hover : theme.colors.primary.hover)};
  }

  &:active {
    color: ${({ theme, color }) => (color ? theme.colors[color]?.selected : theme.colors.primary.selected)}!important;
  }
`

export const Flex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Child = css`
  > :first-child {
    margin-right: ${spacing.one};
  }
`

export const Wrapper = styled.div`
  height: ${({ device, mobileHeight, desktopHeight }) => {
    if (device === BREAKPOINTS.MOBILE) return mobileHeight
    return desktopHeight
  }};
  width: 100%;

  max-width: ${({ device, mobileWidth, desktopWidth }) => (device === BREAKPOINTS.MOBILE ? mobileWidth : desktopWidth)};
  box-shadow: ${({ theme, color }) => {
    if (theme.name === THEME_NAME.LIGHT) {
      const currentColor = color ? theme[color]?.main : theme.colors.text.dark
      return `0px 5px 33px -21px ${currentColor}`
    }
    return 'none'
  }};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background.content};

  padding: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return `${spacing.one} ${spacing.two}`
    return `${spacing.two} ${spacing.two}`
  }};
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 1;

  border-radius: ${spacing.one};
  box-shadow: ${({ device }) => device < BREAKPOINTS.DESKTOP && '8px 8px 12px -4px rgba(0,0,0, 0.5)'};
`

export const Header = styled.div`
  width: ${({ width }) => width ?? '100%'};
  height: auto;
  ${Flex}
  justify-content: center;
  flex: 1;

  > img {
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.half : spacing.two)};
  }
`
export const Body = styled.div`
  width: 100%;
  flex: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '2' : '3')};
  ${Flex}
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  padding: ${({ device, paddingLefRig, paddingTopBot }) => {
    if (device === BREAKPOINTS.MOBILE) return 0
    return `${paddingTopBot} ${paddingLefRig}`
  }};

  > button {
      margin-top: ${spacing.two};
      margin-bottom: ${spacing.oneAndAHalf};
      width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : '40%')};  
  }
`
export const Footer = styled.div`
  height: 100%;
  width: 100%;

  ${Flex}
  justify-content: space-evenly;

  flex: 1;
`

export const Img = styled.img`
    width: auto;
    height: auto;
`

export const WrapperRegister = styled.div`
  display: flex;
  align-items: center;
  ${Child}
`

export const LoadingContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1;
`

export const AuhtContainerStyle = styled.div`
  grid-column-start: ${({ device }) => (device < BREAKPOINTS.DESKTOP ? 1 : 8)};
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  
  background-color: ${({ theme, color }) => {
    if (color) return theme.colors[color].content
    return theme.colors.background.main
  }};
  height: 100%;
  min-width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '300px' : '500px')};
`

export const ContainerImage = styled.div`
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.primary.main
    return theme.colors.background.main
  }};
  height: 100%;
  border-right: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return `1px solid ${theme.colors.grays.main}`
    return 'none'
  }};
`

export const Logo = styled.img`
    width: 90%;
    height: 90%;
    object-fit: scale-down;
    padding: 10%;
`

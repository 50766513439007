import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/index'

import { RESOURCE_TYPE } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'

import { MEDIA_FILE_SUMMARY_ACTIONS } from './actions'
import { MEDIA_FILE_SUMMARY } from './types'
import { imageAdapter } from '../adapter/image.adapter'

const apiCalls = {
  getMediaFileDataApi: ({ token, imageId }) => Axios.get(`image/${imageId}/`,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getMediaFileVersionsApi: ({ token, imageId }) => Axios.get(`image/${imageId}/versions/`,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  // getMediaFileVersionsApi: async () => {
  //   await sleep(2000)
  //   return { status: 200, data: IMAGE_VERSIONS }
  // },
  getMediaFileUrlsApi: ({ token, imageId }) => Axios.get(`image/${imageId}/urls/`,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  removeMediaFileImageApi: ({ token, imageId }) => Axios.delete(`image/${imageId}/`,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    })
}

export function* getMediaFileData ({ payload }) {
  try {
    const { token, fileId, mediaType } = payload
    let mediaFile = null
    let mediaFileVersions = null
    let mediaFileUrls = null
    switch (mediaType) {
      case RESOURCE_TYPE.IMAGE: {
        const { data: mediaFileData } = yield call(apiCalls.getMediaFileDataApi, { token, imageId: fileId })
        const { data: mediaFileVersionsData } = yield call(apiCalls.getMediaFileVersionsApi, { token, imageId: fileId })
        const { data: mediaFileUrlsData } = yield call(apiCalls.getMediaFileUrlsApi, { token, imageId: fileId })
        mediaFile = imageAdapter(mediaFileData)
        mediaFileVersions = mediaFileVersionsData?.map(imgV => imageAdapter(imgV))
        mediaFileUrls = mediaFileUrlsData
        break
      }
      default:
        break
    }
    yield put(MEDIA_FILE_SUMMARY_ACTIONS.GET_MEDIA_FILE_DATA_SUCCESS(mediaFile))
    yield put(MEDIA_FILE_SUMMARY_ACTIONS.GET_MEDIA_FILE_VERSIONS_SUCCESS(mediaFileVersions))
    yield put(MEDIA_FILE_SUMMARY_ACTIONS.GET_MEDIA_FILE_URLS_SUCCESS(mediaFileUrls))
  } catch (error) {
    yield put(MEDIA_FILE_SUMMARY_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* removeMediaFileData ({ payload }) {
  try {
    const { token, fileId, mediaType } = payload
    let status = null
    switch (mediaType) {
      case RESOURCE_TYPE.IMAGE: {
        const res = yield call(apiCalls.removeMediaFileImageApi, { token, imageId: fileId })
        status = res.status
        break
      }
      default:
        break
    }
    yield put(MEDIA_FILE_SUMMARY_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.MEDIA_FILE_REMOVED }))
  } catch (error) {
    yield put(MEDIA_FILE_SUMMARY_ACTIONS.ERROR_MESSAGE(error))
  }
}

export default function* saga () {
  yield takeLatest(MEDIA_FILE_SUMMARY.GET_MEDIA_FILE_DATA, getMediaFileData)
  yield takeLatest(MEDIA_FILE_SUMMARY.REMOVE_MEDIA_FILE, removeMediaFileData)
}

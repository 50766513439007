import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'

import { ButtonsContainer, TableWrapper, WrapperBreadcrumbs, WrapperHeading, WrapperLoading } from '@global/styles'
import { BREAKPOINTS, RESOURCE_TYPE, TEXT_CONTENT, TOAST_POSITION, TOAST_PROPERTIES } from '@global/constants'
import { bytesToMegabytes } from '@utils/helpers'
import { MESSAGES } from '@global/message'
import { SITE } from '@routes/paths'

import { useNotification } from 'src/context/notification.context'
import { Heading } from '@baseComponents/texts'
import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import { useUser } from '@containers/authentication/utils/hook'
import { SolidButton } from '@baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'
import Table from '@components/table'
import Modal from '@components/modal'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'
import UploadFileModal from '@containers/fileManager/components/uploadFileModal'

import useRedux from './redux'
import { MEDIA_FILE_SUMMARY_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import MediaFileCard from './components/mediaFileCard'

import { MediaFileCardContainer } from './styles'
import { COLUMS_IMAGE } from './data'

const FilePage = () => {
  useRedux()
  const { state } = useLocation()
  const { fileId, workspaceId } = useParams()
  const { GetToken } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()
  const { getNotification } = useNotification()
  const history = useHistory()

  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [mediaFile, setMediaFile] = useState(null)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [mediaFileUrls, setMediaFileUrls] = useState('')
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [removeMediaFile, setRemoveMediaFile] = useState(null)
  const [uploadFileModal, setUploadFileModal] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const mediaFileData = useSelector(SELECTORS.MEDIA_FILE_DATA)
  const mediaFileVersionsData = useSelector(SELECTORS.MEDIA_FILE_VERSIONS)
  const mediaFileUrlsData = useSelector(SELECTORS.MEDIA_FILE_URLS)
  const successMessage = useSelector(SELECTORS.SUCCESS_MESSAGE)

  const customBodyDataTable = bodyData => bodyData.map(item => {
    const newObj = {
      id: item.id,
      type: item.specs,
      size: bytesToMegabytes(item.size),
      consumed: '4 GB'
    }
    return newObj
  })

  const handleOrderChange = () => {}

  const handleCloseModal = () => {
    setRemoveMediaFile(null)
    setUploadFileModal(null)
    setOpen(false)
  }

  const deleteMediaFile = async itemId => {
    const token = await GetToken()
    dispatch(MEDIA_FILE_SUMMARY_ACTIONS.REMOVE_MEDIA_FILE({ token, fileId: itemId, mediaType: RESOURCE_TYPE.IMAGE }))
  }

  const handleRemoveMediaFile = () => {
    setModalTitle(TEXT_CONTENT.DELETE_MEDIA_FILE.toUpperCase())
    setRemoveMediaFile({
      close: handleCloseModal,
      text: `${MESSAGES.REMOVE_MEDIA_FILE} Do you wish to continue?`,
      itemId: fileId,
      removeItem: deleteMediaFile
    })
    setOpen(true)
  }

  const handleSuccess = async message => {
    getNotification({
      title: TOAST_PROPERTIES.SUCCESS.title,
      message,
      icon: TOAST_PROPERTIES.SUCCESS.icon,
      color: TOAST_PROPERTIES.SUCCESS.color,
      position: TOAST_POSITION.rightTop,
      open: true
    })
    const token = await GetToken()
    dispatch(MEDIA_FILE_SUMMARY_ACTIONS.GET_MEDIA_FILE_DATA({ token, fileId, mediaType: RESOURCE_TYPE.IMAGE }))
  }

  const handleEditMediaFile = () => {
    const { rootFolderId, linkedFolders } = state
    setModalTitle(TEXT_CONTENT.EDIT_MEDIA_FILE.toUpperCase())
    setUploadFileModal({
      close: handleCloseModal,
      successResult: handleSuccess,
      rootFolderId,
      edit: true,
      oldData: mediaFile,
      linkedFolders
    })
    setOpen(true)
  }

  useEffect(async () => {
    const token = await GetToken()
    dispatch(MEDIA_FILE_SUMMARY_ACTIONS.GET_MEDIA_FILE_DATA({ token, fileId, mediaType: RESOURCE_TYPE.IMAGE }))
    setBreadcrumbs(state?.breadcrumbs)
  }, [])

  useEffect(() => {
    if (mediaFileData && mediaFileVersionsData && mediaFileUrlsData) {
      setMediaFile(mediaFileData)
      setColumns(COLUMS_IMAGE)
      setTablelist(customBodyDataTable(mediaFileVersionsData))
      setMediaFileUrls(Object.values(mediaFileUrlsData)[0].toString())

      const lastCrumb = {
        text: `${mediaFileData.name}`,
        url: null
      }
      if (breadcrumbs.length === state.breadcrumbs.length) {
        setBreadcrumbs([...breadcrumbs, lastCrumb])
      }
    }
  }, [mediaFileData, mediaFileVersionsData, mediaFileUrlsData])

  useEffect(async () => {
    if (successMessage) {
      const { status, message } = successMessage
      getNotification({
        title: TOAST_PROPERTIES.SUCCESS.title,
        message: message || status,
        icon: TOAST_PROPERTIES.SUCCESS.icon,
        color: TOAST_PROPERTIES.SUCCESS.color,
        position: TOAST_POSITION.leftTop,
        open: true
      })
      // const token = await GetToken()
      // after removed
      if (status === 204) {
        history.replace(`${SITE.DASHBOARD}${SITE.WORKSPACE}/${workspaceId}`)
        dispatch(MEDIA_FILE_SUMMARY_ACTIONS.CLEAR_SUCCESS_MESSAGE())
      }
      // setTimeout(() => {
      //   dispatch(MEDIA_FILE_SUMMARY_ACTIONS.CLEAR_SUCCESS_MESSAGE())
      // }, 2000)
    }
  }, [successMessage])

  return (
    loading
      ? (
        <WrapperLoading>
          <Loading color='primary' size='xsmall' weight='bold' />
        </WrapperLoading>
      )
      : (
        <>
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart={1} columnEnd={13} rowStart={1} rowEnd={1}>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
          </>
          <WrapperHeading columnStart={1} columnEnd={13} rowStart={2} rowEnd={2} device={device}>
            <Heading type='h1' size='large'>{mediaFileData?.name}</Heading>
            <ButtonsContainer device={device}>
              <SolidButton
                size='medium'
                color='primary'
                icon='delete'
                onClick={handleRemoveMediaFile}
              />
            </ButtonsContainer>
          </WrapperHeading>
          <MediaFileCardContainer columnStart={1} columnEnd={13} rowGrid={3}>
            <MediaFileCard
              columnStart={1}
              columnEnd={device >= BREAKPOINTS.TABLET ? 5 : 13}
              rowGrid={1}
              size={bytesToMegabytes(56192)}
              extension='.jpg'
              name={mediaFile?.name}
              imageUrl={mediaFileUrls}
              handleEdit={handleEditMediaFile}
              device={device}
            />
            <TableWrapper
              columnStart={device >= BREAKPOINTS.TABLET ? 5 : 1}
              columnEnd={13}
              rowStart={device >= BREAKPOINTS.TABLET ? 1 : 2}
              rowEnd={device >= BREAKPOINTS.TABLET ? 1 : 2}
            >
              <Table
                list={tablelist}
                columns={columns}
                loading={false}
                handleOrder={handleOrderChange}
                error={false}
              />
            </TableWrapper>
          </MediaFileCardContainer>
          <Suspense fallback={<Loading color='primary' size='xsmall' weight='bold' />}>
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle?.toUpperCase()}
              minHeight='auto'
            >
              {removeMediaFile
              && (
                <DeleteConfirmationModal
                  {...removeMediaFile}
                />
              )}
              {uploadFileModal && (<UploadFileModal {...uploadFileModal} />)}
            </Modal>
          </Suspense>
        </>
      )
  )
}

export default FilePage

import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { INITIAL_STATES, TOAST_POSITION } from '@global/constants'
import Toast from '@components/toast'

export const NotificationContext = createContext(null)

const NotificationProvider = ({ children }) => {
  const [titleNot, setTitleNot] = useState(INITIAL_STATES.TOAST.title)
  const [messageNot, setMessageNot] = useState(INITIAL_STATES.TOAST.message)
  const [iconNot, setIconNot] = useState(INITIAL_STATES.TOAST.icon)
  const [colorNot, setColorNot] = useState(INITIAL_STATES.TOAST.color)
  const [positionNot, setPositionNot] = useState(TOAST_POSITION.rightTop)
  const [openNot, setOpenNot] = useState(false)
  const [timeOutNot, setTimeOutNot] = useState(5000)

  const getNotification = ({ title, message, icon, color, position, open, timeOut }) => {
    setTitleNot(title)
    setMessageNot(message)
    setIconNot(icon)
    setColorNot(color)
    setPositionNot(position)
    setOpenNot(open)
    setTimeOutNot(timeOut)
  }

  const value = {
    getNotification
  }

  return (
    <NotificationContext.Provider value={value}>
      <Toast
        title={titleNot}
        message={messageNot}
        icon={iconNot}
        color={colorNot}
        isShowing={openNot}
        position={positionNot}
        onClick={() => setOpenNot(false)}
        timeOut={timeOutNot}
      />
      {children}
    </NotificationContext.Provider>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.any
}

export default NotificationProvider

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) throw new Error('Does not exist context.')
  return context
}

import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { SITE } from '@routes/paths'
// theme
import { LIGHT_THEME, DARK_THEME } from '@global/theme'
import { ThemeProvider } from 'styled-components'
import { THEME_NAME } from '@global/constants'
import FilePage from '@containers/filePage'
// private pages
import { ThemeContext } from 'src/context/themeContext'
import Dashboard from '@containers/dashboard'
import Profile from '@containers/profile'
import FileManager from '@containers/fileManager'
import Workspace from '@containers/workspace'
import EditWorkspace from '@containers/workspace/components/editWorkspace'
// public pages
import Login from '@containers/authentication/components/login'

import NotificationProvider from 'src/context/notification.context'

import PrivateRouteWrapper from './utils/privateRouteWrapper'
import PublicRouteWrapper from './utils/publicRouteWrapper'

export const App = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <ThemeProvider theme={theme === THEME_NAME.LIGHT ? LIGHT_THEME : DARK_THEME}>
      <NotificationProvider>
        <Switch>
          <PublicRouteWrapper exact path={SITE.SIGN_IN} component={Login} />
          <Route exact path='/'>
            <Redirect to={SITE.SIGN_IN} />
          </Route>
          <PrivateRouteWrapper path={SITE.FILE_MANAGER} component={FileManager} />
          <PrivateRouteWrapper path={SITE.PROFILE} component={Profile} />
          <PrivateRouteWrapper exact path={SITE.DASHBOARD} component={Dashboard} />
          <PrivateRouteWrapper exact path={`${SITE.DASHBOARD}${SITE.WORKSPACE}/:workspaceId`} component={Workspace} />
          <PrivateRouteWrapper
            exact
            path={`${SITE.DASHBOARD}${SITE.WORKSPACE}/:workspaceId${SITE.FILE_PAGE}/:fileId`}
            component={FilePage}
          />
          <PrivateRouteWrapper
            exact
            path={`${SITE.DASHBOARD}${SITE.WORKSPACE}/:workspaceId/edit`}
            component={EditWorkspace}
          />
        </Switch>
      </NotificationProvider>
    </ThemeProvider>
  )
}

export default App

import styled from 'styled-components'
import { spacing } from '@global/theme'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'
import { Column } from '@global/styles'

export const CardWorkSpaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${spacing.one};
  height: 247px;
  background-color: ${({ theme }) => theme.colors.background.content};
  grid-column: ${({ device }) => {
    if (device === BREAKPOINTS.BIG_SCREEN) return `span ${3}`
    if (device === BREAKPOINTS.DESKTOP) return `span ${4}`
    if (device === BREAKPOINTS.TABLET_PORTRAIT) return `span ${6}`
    if (device === BREAKPOINTS.MOBILE) return '2/12'
    return `span ${4}`
  }};
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.25s;
  padding: ${spacing.two};
  border: ${({ theme }) => {
    const currentColor = theme.name === THEME_NAME.LIGHT
      ? theme.colors.primary.main
      : theme.colors.grays.dark
    return `${spacing.quarter} solid ${currentColor}`
  }};

  &:hover {
      transform: scale(1.01, 1.01);
      box-shadow: ${({ theme }) => {
    const color = theme.name === THEME_NAME.LIGHT
      ? theme.colors.primary.main
      : theme.colors.grays.main
    return `${spacing.threeQuarters} ${spacing.threeQuarters} ${spacing.oneAndAHalf}  ${spacing.quarter} ${color}`
  }};
    }
`

export const CardWorkSpaceHeader = styled.div`
  width: 100%;
  height: 150px;
  background: ${({ theme }) => theme.colors.primary.main};
  background: ${({ theme }) => {
    const firstColor = theme.colors.primary.main
    const secondColor = theme.colors.secondary.main
    return `-webkit-linear-gradient(to right, ${firstColor}, ${secondColor})`
  }};
  background: ${({ theme }) => {
    const firstColor = theme.colors.primary.main
    const secondColor = theme.colors.secondary.main
    return `linear-gradient(to right, ${firstColor}, ${secondColor})`
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.two};
  margin-bottom: ${spacing.two};
  flex: 2;
  & > h3 {
    color: ${({ theme }) => theme.colors.text.white}!important;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${spacing.one} 0;
  & > div {
    margin-right: ${spacing.one};
  }
`

export const CardWorkSpacColumn = styled(Column)`
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > :first-child {
    margin-bottom: ${spacing.one};
    text-transform: capitalize;
  }
`

export const CardWorkSpaceFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    flex: 2;
  }
`

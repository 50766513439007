export const key = 'MEDIA_FILE_SUMMARY'

export const MEDIA_FILE_SUMMARY = {
  GET_MEDIA_FILE_DATA: 'MEDIA_FILE_SUMMARY/MEDIA_FILE/GET/DATA',
  GET_MEDIA_FILE_DATA_SUCCESS: 'MEDIA_FILE_SUMMARY/MEDIA_FILE/GET/DATA/SUCCESS',
  GET_MEDIA_FILE_VERSIONS: 'MEDIA_FILE_SUMMARY/MEDIA_FILE/GET/VERSIONS',
  GET_MEDIA_FILE_VERSIONS_SUCCESS: 'MEDIA_FILE_SUMMARY/MEDIA_FILE/GET/VERSIONS/SUCCESS',
  GET_MEDIA_FILE_URLS_SUCCESS: 'MEDIA_FILE_SUMMARY/MEDIA_FILE/GET/URLS/SUCCESS',
  REMOVE_MEDIA_FILE: 'MEDIA_FILE_SUMMARY/MEDIA_FILE/REMOVE',

  SUCCESS_MESSAGE: 'MEDIA_FILE_SUMMARY/MESSAGE/SUCCESS',
  CLEAR_SUCCESS_MESSAGE: 'MEDIA_FILE_SUMMARY/MESSAGE/SUCCESS/CLEAR',
  ERROR_MESSAGE: 'MEDIA_FILE_SUMMARY/MESSAGE/ERROR',
  CLEAR_ERROR_MESSAGE: 'MEDIA_FILE_SUMMARY/MESSAGE/ERROR/CLEAR'
}

export default key

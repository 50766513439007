import { put, call, takeLatest, all } from 'redux-saga/effects'

import Axios from '@axios/index'

import { MESSAGES } from '@global/message'
import { TOKEN_HELPERS, sleep } from '@utils/helpers'

import { OPERATION_STATUS } from '@global/constants'
import { OPERATIONS_MOCK_1, OPERATIONS_MOCK_2 } from '@containers/workspace/mock'

import { workspaceProfileAdapter } from '../adapter/workspaceProfileAdapter'
import { profileOperationAdapter } from '../adapter/profileOperationAdapter'

import { WORKSPACE_PROFILE_ACTIONS } from './actions'
import { WORKSPACE_PROFILE } from './types'

const apiCalls = {
  profileCalls: {
    getWorkspaceProfilesApi: ({ token, profileId }) => Axios.get(`profile/${profileId}/`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    createWorkspaceProfileApi: ({ token, data }) => Axios.post('profile/', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    editWorkspaceProfileApi: ({ token, profileId, data }) => Axios.put(`profile/${profileId}/`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    removeWorkspaceProfileApi: ({ token, profileId }) => Axios.delete(`profile/${profileId}/`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })
  },
  operationsCalls: {
    removeProfileOperationApi: ({ token, operationId }) => Axios.delete(`operation/${operationId}/`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    createProfileOperationApi: ({ token, data }) => Axios.post('operation/', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    addProfileOperationApi: ({ token, profileId, data }) => Axios.post(`profile/${profileId}/add_operation/`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    getOperationsApi: async () => {
      await sleep(2000)
      return { status: 200, data: [...OPERATIONS_MOCK_1, ...OPERATIONS_MOCK_2] }
    }
  }
}

export function* getWorkspaceData ({ payload }) {
  try {
    const { profileCalls } = apiCalls
    const { token, arrProfileId } = payload

    let workspaceProfiles = yield all(
      arrProfileId.map(pi => call(profileCalls.getWorkspaceProfilesApi, { token, profileId: pi }))
    )
    workspaceProfiles = workspaceProfiles.map(p => ({
      ...workspaceProfileAdapter(p.data)
    }))

    yield put(WORKSPACE_PROFILE_ACTIONS.WORKSPACE_PROFILES_SUCCESS(workspaceProfiles))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* createWorkspaceProfile ({ payload }) {
  try {
    const { profileCalls } = apiCalls
    const { token, data } = payload
    const { name, workspace, oldProfiles } = data

    const { status, data: profileCreated } = yield call(
      profileCalls.createWorkspaceProfileApi, { token, data: { name, workspace } }
    )
    const newProfile = workspaceProfileAdapter(profileCreated)
    const profilesUpdated = [...oldProfiles, newProfile]

    yield put(WORKSPACE_PROFILE_ACTIONS.WORKSPACE_PROFILES_SUCCESS(profilesUpdated))
    yield put(WORKSPACE_PROFILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.WORKSPACE_PROFILE_CREATED }))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* editWorkspaceProfile ({ payload }) {
  try {
    const { profileCalls } = apiCalls
    const { status, data: profileEdited } = yield call(profileCalls.editWorkspaceProfileApi, payload)

    const { data: { oldProfiles } } = payload

    if (oldProfiles) {
      const profileListUpdated = oldProfiles
        ?.map(p => (p.id !== profileEdited.id ? p : workspaceProfileAdapter(profileEdited)))

      yield put(WORKSPACE_PROFILE_ACTIONS.WORKSPACE_PROFILES_SUCCESS(profileListUpdated))
    }

    yield put(WORKSPACE_PROFILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.WORKSPACE_PROFILE_EDITED }))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* removeWorkspaceProfile ({ payload }) {
  try {
    const { profileCalls } = apiCalls
    const { status } = yield call(profileCalls.removeWorkspaceProfileApi, payload)
    yield put(WORKSPACE_PROFILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.WORKSPACE_PROFILE_REMOVED }))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* getProfileOperations ({ payload }) {
  try {
    const { profileCalls } = apiCalls
    let { data: { operations } } = yield call(profileCalls.getWorkspaceProfilesApi, payload)
    // remove status when BE return this prop
    operations = operations.map((op, i) => ({
      ...profileOperationAdapter(op),
      status: i % 2 === 0 ? OPERATION_STATUS.ACTIVE : OPERATION_STATUS.INACTIVE
    }))
    const { profileId } = payload
    yield put(WORKSPACE_PROFILE_ACTIONS.PROFILE_OPERATION_SUCCESS({ profileId, operations }))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* removeProfileOperation ({ payload }) {
  try {
    const { operationsCalls, profileCalls } = apiCalls
    const { token, arrProfileId, profileId } = payload

    const { status } = yield call(operationsCalls.removeProfileOperationApi, payload)

    let workspaceProfiles = yield all(
      arrProfileId.map(pi => call(profileCalls.getWorkspaceProfilesApi, { token, profileId: pi }))
    )

    workspaceProfiles = workspaceProfiles.map(p => ({
      ...workspaceProfileAdapter(p.data)
    }))

    const profileUpdated = workspaceProfiles.find(p => p.id === profileId)

    yield put(WORKSPACE_PROFILE_ACTIONS.WORKSPACE_PROFILES_SUCCESS(workspaceProfiles))
    yield put(WORKSPACE_PROFILE_ACTIONS.PROFILE_OPERATION_SUCCESS({ profileId, operations: profileUpdated.operations }))
    yield put(WORKSPACE_PROFILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.PROFILE_OPERATION_REMOVED }))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* createProfileOperation ({ payload }) {
  try {
    const { operationsCalls } = apiCalls
    const { status, data } = yield call(operationsCalls.createProfileOperationApi, payload)
    const workspaceOperation = profileOperationAdapter(data)
    yield put(WORKSPACE_PROFILE_ACTIONS.PROFILE_OPERATION_SUCCESS(workspaceOperation))
    yield put(WORKSPACE_PROFILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.PROFILE_OPERATION_CREATED }))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* addProfileOperation ({ payload }) {
  try {
    const { operationsCalls } = apiCalls
    const { token, profileId, data, oldProfiles, accordionOpen } = payload
    const { status, data: profileUpdated } = yield call(
      operationsCalls.addProfileOperationApi, { token, profileId, data }
    )

    if (oldProfiles) {
      const profileListUpdated = oldProfiles
        ?.map(p => (p.id !== profileUpdated.id ? p : workspaceProfileAdapter(profileUpdated)))

      yield put(WORKSPACE_PROFILE_ACTIONS.WORKSPACE_PROFILES_SUCCESS(profileListUpdated))
    }

    if (accordionOpen) {
      yield put(WORKSPACE_PROFILE_ACTIONS
        .PROFILE_OPERATION_SUCCESS({ profileId, operations: profileUpdated.operations }))
    }

    yield put(WORKSPACE_PROFILE_ACTIONS.SUCCESS_MESSAGE({ status, message: MESSAGES.PROFILE_OPERATION_CREATED }))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* getAllOperations ({ payload }) {
  try {
    const { operationsCalls } = apiCalls
    const { data: operations } = yield call(operationsCalls.getOperationsApi, payload)
    const operationsAdapted = operations.map(op => profileOperationAdapter(op))

    yield put(WORKSPACE_PROFILE_ACTIONS.ALL_OPERATIONS_SUCCESS(operationsAdapted))
  } catch (error) {
    yield put(WORKSPACE_PROFILE_ACTIONS.ERROR_MESSAGE(error))
  }
}
export default function* saga () {
  yield takeLatest(WORKSPACE_PROFILE.GET_WORKSPACE_PROFILES, getWorkspaceData)
  yield takeLatest(WORKSPACE_PROFILE.CREATE_WORKSPACE_PROFILE, createWorkspaceProfile)
  yield takeLatest(WORKSPACE_PROFILE.EDIT_WORKSPACE_PROFILE, editWorkspaceProfile)
  yield takeLatest(WORKSPACE_PROFILE.REMOVE_WORKSPACE_PROFILE, removeWorkspaceProfile)

  yield takeLatest(WORKSPACE_PROFILE.GET_PROFILE_OPERATION, getProfileOperations)
  yield takeLatest(WORKSPACE_PROFILE.REMOVE_PROFILE_OPERATION, removeProfileOperation)
  yield takeLatest(WORKSPACE_PROFILE.CREATE_PROFILE_OPERATION, createProfileOperation)
  yield takeLatest(WORKSPACE_PROFILE.ADD_PROFILE_OPERATION, addProfileOperation)
  yield takeLatest(WORKSPACE_PROFILE.GET_ALL_OPERATIONS, getAllOperations)
}

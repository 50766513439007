import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, FUNCTION_TYPE, TOAST_POSITION, TOAST_PROPERTIES } from '@global/constants'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import { SolidButton } from 'src/baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'
import { useNotification } from 'src/context/notification.context'

import { Text } from 'src/baseComponents/texts'
import { ModalDataContainer, ButtonContainer } from './styles'

const UrlModal = ({ url }) => {
  const { device } = useWindowDimensions()
  const { getNotification } = useNotification()

  const handleDownload = () => {
    const blob = new Blob([url], { type: 'text/html' })
    const urlBlob = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = urlBlob
    link.setAttribute('download', 'urlMediaFile')
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(urlBlob)
    link.remove()
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(url)
    getNotification({
      title: TOAST_PROPERTIES.SUCCESS.title,
      message: MESSAGES.COPY_LINK,
      icon: TOAST_PROPERTIES.SUCCESS.icon,
      color: TOAST_PROPERTIES.SUCCESS.color,
      position: TOAST_POSITION.leftTop,
      open: true
    })
  }

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <ButtonContainer justifyContent='flex-end' device={device} margin={`0 0 ${spacing.three} 0`}>
          <SolidButton
            size='small'
            color='primary'
            text={FUNCTION_TYPE.DOWNLOAD.label}
            onClick={handleDownload}
            block={device === BREAKPOINTS.MOBILE}
            icon='download'
          />
          <SolidButton
            size='small'
            color='primary'
            text={FUNCTION_TYPE.COPY.label}
            onClick={handleCopy}
            block={device === BREAKPOINTS.MOBILE}
            icon='content_copy'
          />
        </ButtonContainer>
        <Text size='large' weight='bold' align='left'>
          {url}
        </Text>
      </ModalDataContainer>

    </>

  )
}

UrlModal.propTypes = {
  url: PropTypes.string
}

export default UrlModal

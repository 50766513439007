import styled, { css } from 'styled-components'
import { spacing } from '@global/theme'
import { regular, medium, SourceCodePro, bold } from '@global/fonts'
import { NavLink } from 'react-router-dom'
import { BREAKPOINTS, DEVICE_NAME, THEME_NAME } from '@global/constants'

export const column = css`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  margin: ${({ margin }) => margin || 0};
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || 0};
`
export const Column = styled.div`${column}`

export const row = css`
  display: flex;
  width: ${({ width }) => (width || 'auto')};
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
`
export const Row = styled.div`${row}`

export const layout = css`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'fit-content'};
  display: ${({ none }) => (none ? 'block' : 'grid')};
  grid-gap: ${({ gap }) => gap || '20px'};
  justify-items: ${({ justifyItems }) => justifyItems || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};

  ${({ columns, rows }) => css`
      grid-template-rows:  ${row ? rows.reduce((previous, current) => `${previous} ${current}`) : '1fr'};
      grid-template-columns: ${columns ? columns.reduce((previous, current) => `${previous} ${current}`) : '1fr'};
    `
};
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};

  margin: ${({ margin }) => margin || 0};
`
export const boxShadow = css`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 16px;
`
export const borderDark = css`
  border: 1px solid ${({ theme }) => (theme.colors.grays.main)};
`
export const borderLight = css`
  border: 1px solid ${({ theme }) => (theme.colors.grays.disabled)};
`

export const Layout = styled.div`${layout}`

export const FilterContainer = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  width: 100%;
  display: flex;

  flex-direction: ${({ deviceName }) => {
    if (deviceName === DEVICE_NAME.MOBILE || deviceName === DEVICE_NAME.TABLET_PORTRAIT) return 'column'
    return 'row'
  }};
  align-items: flex-start;
  justify-content: space-between;
  margin: ${({ margin }) => margin || `${spacing.three} 0`} ;

  & > :first-child {
    flex: 1;
  }

  & > :last-child {
    flex: 1;
  }
`
export const FilterContainerLeftSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? 'wrap' : 'nowrap')};
  flex: 1;

  & > :first-child {
    width: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? '100%' : 'auto')};
    margin-bottom: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE
    || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? spacing.one : 0)};
  }

  & > div {
    flex: 3;
    & > :nth-child(odd) {
      margin-left: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? 0 : spacing.one)};
    }
    & > :nth-child(even) {
      margin-left: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? 0 : spacing.one)};
    }
  }
`

export const FilterContainerRightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ deviceName }) => {
    if (deviceName === DEVICE_NAME.MOBILE) return 'column'
    return 'row'
  }};
    align-items: ${({ deviceName }) => {
    if (deviceName === DEVICE_NAME.MOBILE) return 'stretch'
    return 'flex-end'
  }};

  justify-content: ${({ searchBarNoExists }) => (searchBarNoExists ? 'flex-end' : 'flex-start')} ;
  flex: ${({ searchBarNoExists }) => (searchBarNoExists ? 0 : 1)};

  & > :first-child {
    width: auto;
    flex: 2;
    margin-top: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? spacing.one : 0)};
  }

  & > :last-child {
    width: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? '100%' : 'min-content')};
    margin-top: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? spacing.one : 0)};
    flex: ${({ searchBarNoExists }) => (searchBarNoExists ? 0.5 : 1)};
    margin-left: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE ? 0 : spacing.one)};
  }
`

export const ModalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
  margin-bottom: ${spacing.two};
  padding: ${({ padding }) => (padding || 0)};
  ${({ overflow }) => (`overflow: ${overflow}`)};

  * > p, label {
    ${regular};
    ${medium};
    margin-left: 0;
    letter-spacing: 0;
  }

  > div {
    width: 100%;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  margin-top: ${({ marginTop }) => marginTop || 0};
  flex: 1;
  & > :nth-child(2) {
    margin-left: ${spacing.two};
  }
`

export const ButtonModalContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: flex-end;
margin-top: ${({ marginTop }) => marginTop || 0};
margin-bottom: ${({ marginBottom }) => marginBottom || 0};

& > :last-child {
  margin-left: ${({ oneButton }) => (oneButton ? 0 : spacing.three)};
}
`

export const MyButtonLink = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  ${SourceCodePro};
  font-size: 14px;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  cursor: pointer;
  ${bold};
  letter-spacing: 0.5px;
  color: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.text.main)};

  &:hover {
    color: ${({ theme }) => (theme.colors.primary.hover)};
  }
`
export const WrapperBreadcrumbs = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  margin-bottom: ${spacing.three};
`

export const WrapperLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 13;
`

export const MyLink = styled(NavLink)`
  ${SourceCodePro};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  font-size: 14px;
  ${bold};
  letter-spacing: 0.5px;
  color: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.text.content)};
  margin-bottom: ${({ marginBottom }) => marginBottom || null};
`
export const DataContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => (theme.colors.background.main)};
  padding: ${spacing.two};
  border-radius: ${spacing.one};

  display: flex;
  flex-direction: ${({ device }) => (device <= BREAKPOINTS.TABLET_PORTRAIT ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  ${({ theme }) => (theme.name === THEME_NAME.LIGHT && boxShadow)};
  ${({ theme }) => (theme.name === THEME_NAME.DARK && borderDark)};
  
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  overflow: auto;
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop || 0)};
  margin-right: ${({ marginRight }) => (marginRight || 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom || 0)};
  margin-left: ${({ marginLeft }) => (marginLeft || 0)};

  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};

  & > :last-child {
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};
  }
`

export const WrapperHeading = styled.div`
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop || 0)};
  margin-right: ${({ marginRight }) => (marginRight || 0)};
  margin-left: ${({ marginLeft }) => (marginLeft || 0)};

  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: baseline;
  flex: ${({ flex }) => flex || 0};
`

export const LinkContainer = styled.div`
  height: 100%;
  width: 100%;  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ alignItems }) => alignItems || 'flex-end'};

  align-self: end;
  flex: ${({ flex }) => (flex || 1)};
`

export const ContainerShortcuts = styled.div`
  grid-column-start: 1;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 1;

  width: 100%:
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const ShortcutItem = styled(NavLink)`
  grid-column-start: 1;
  grid-column-end: 12;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};

  height: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'auto' : spacing.sevenAndAHalf)};
  padding: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : spacing.three)};
  border-radius: ${spacing.one};
  background-color: ${({ theme }) => (theme.colors.background.main)};
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
  margin-bottom: ${spacing.three};
  margin-left: ${spacing.half};

  > span {
      width: ${spacing.six};
      height: ${spacing.six};
      font-size: ${spacing.six};
  }

  &:hover {
    box-shadow: ${({ theme }) => (`2px 2px 2px 2px ${theme.colors.primary.hover}`)};
  }
`
export const ShortcutLeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  
  & > :first-child {
      margin-right: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.three)};
      margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.one : 0)};
  }
  & > :last-child {
      text-align: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'center' : 'left')};
  }
`

export const ShortcutImg = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: ${({ borderRadius }) => (borderRadius && borderRadius)};
    object-fit: scale-down;
`

export const TableWrapper = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  margin-bottom: ${spacing.three};
`

export const PaginationWrapper = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  margin-bottom: ${spacing.three};
`

export const DataFileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;

  padding: ${spacing.three};
  border: ${({ theme }) => (`2px solid ${theme.colors.text.disabled}`)};
  margin-top: ${spacing.three};
`

export const FileUploaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 3;
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${spacing.two};
`

export const TextContainer = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  margin: ${({ margin }) => margin || `${spacing.one} 0`};
`

export const TextContainerModal = styled.div`
  width: 100%; 
  margin: ${({ margin }) => margin || `${spacing.one} 0`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ItemLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  text-align: left;
  margin-right: ${spacing.half};
`

export const BadgeContainer = styled.div`
  position: absolute;
  top: ${spacing.one};
  right: -${spacing.two};
`
export const Wrapper = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  
  margin-top: ${({ marginTop }) => marginTop || spacing.one};
  margin-bottom: ${({ marginBottom }) => marginBottom || spacing.one};
  margin-right: ${({ marginRight }) => marginRight || 0};
  margin-left: ${({ marginLeft }) => marginLeft || 0};
`

export const ProcessingContainer = styled.div`
  margin-left: ${({ marginLeft }) => (marginLeft || 0)};
  margin-top: ${({ marginTop }) => (marginTop || 0)};
`

export const DropdownMenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: ${spacing.half};
  padding: ${spacing.half} ${spacing.one};
  border-radius: ${spacing.one};
  background-color: ${({ theme }) => theme.colors.background.content};

  & > :first-child {
    margin-right: ${spacing.one};
  }

  & > p, span  {
    color: ${({ theme }) => theme.colors.text.content}}!important;

  &:hover {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.dark
    return theme.colors.grays.light
  }};

  & > p, span  {
    color: ${({ color, theme }) => {
    if (color) return theme.colors[color].main
    return theme.colors.text.content
  }}!important;
  }
`

export const Box = styled.div`
  width: ${({ widthBox }) => widthBox || '100%'};
  height: ${({ heightBox }) => heightBox || spacing.two};
`

export const Divider = styled.hr`
  width: 100%;
  margin: ${({ margin }) => margin || '0px'};
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: ${({ theme, color }) => {
    if (color) return theme.colors[color]?.main
    if (theme.name === THEME_NAME.DARK) return theme.colors.grays.main
    return theme.colors.grays.light
  }
};
  grid-column-start: ${({ columnStart }) => (columnStart || 'none')};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 'none')};
  grid-row-start: ${({ rowGrid }) => (rowGrid || 'none')};
  grid-row-end: ${({ rowGrid }) => (rowGrid || 'none')};
`
export const DividerVertical = styled.div`
  border-left: ${({ theme, color }) => (`2px solid ${color ? theme.colors[color].main : theme.colors.text.white}`)};
  height: ${({ height }) => (height ?? spacing.three)};
  margin: ${({ margin }) => (margin ?? `0 ${spacing.one}`)};
`
export const Avatar = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  
  object-fit: cover;
`

export const TitleContainerBasicCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || 0};
  flex: 1;
  margin-bottom: ${({ device }) => (device <= BREAKPOINTS.TABLET ? '32px' : 0)};
`

export const BodyBasicCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  height: 100%;
`

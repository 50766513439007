import { TITLES } from '@global/constants'
import { SITE } from '@routes/paths'

export const PROFILE_MENU_ITEMS = [
  {
    id: 1,
    label: TITLES.PROFILE,
    icon: 'account_circle',
    url: SITE.PROFILE
  },
  {
    id: 2,
    label: 'Dashboard',
    icon: 'dashboard',
    url: SITE.DASHBOARD
  },
  {
    id: 3,
    label: TITLES.BILLINGS,
    icon: 'credit_card',
    url: SITE.PROFILE
  }
]

import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

import MyPropTypes from '@global/propTypes'
import { LoaderContainer } from '@global/styles'
import { STATUS_TYPES, BREAKPOINTS, FUNCTION_TYPE } from '@global/constants'

import Icon from 'src/baseComponents/icon'
import { Text } from 'src/baseComponents/texts'
import CircularProgressBar from '@components/circularProgressBar'
import { SolidButton } from 'src/baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'

import logo404 from '../../assets/logo-404.png'

import {
  StyledUploader,
  StyledInputFile,
  TextContainer,
  DropzoneContainer,
  FakeButton,
  ErrorTextContainer
} from './style'

import { converterMbInBytes, getTextTypeAccepted } from './helpers'
import { ACCEPTED_FILE_TYPES, ERROR_MESSAGE_FILES, FILE_UPLOADER_TEXTS } from './data'

import AcceptedFilesComponent from './components/acceptedFiles'
import NoAcceptedFilesComponent from './components/noAcceptedFiles'

const defaultLogo = logo404

export const customFileData = (arrExtensions, arrFileTypes) => {
  let strAccept = ''
  let arrExtAccepted = []
  if (!arrExtensions) return strAccept
  for (let i = 0; i < arrExtensions.length; i++) {
    const fileType = arrFileTypes.find(t => t.name === arrExtensions[i])
    arrExtAccepted = [
      ...arrExtAccepted,
      fileType
    ]
    if (i === arrExtensions.length - 1) {
      strAccept += fileType.type
    } else {
      strAccept += `${fileType.type},`
    }
  }
  return { strAccept, arrExtAccepted }
}

export const FileUploader = forwardRef((props, ref) => {
  const {
    fileAccept,
    maxSizeInMB,
    sendFiles,
    showLoader,
    disabled,
    multiple,
    uploaded,
    cancelUpload,
    errorFileUpload,
    color
  } = props

  const { device } = useWindowDimensions()
  const [acceptedFiles, setAcceptedFiles] = useState([])
  const [noAcceptedFiles, setNoAcceptedFiles] = useState([])
  const [filesAcceptedToSend, setFilesAcceptedToSend] = useState([])
  const [loading, setLoading] = useState(false)
  const [fileData] = useState(customFileData(fileAccept, ACCEPTED_FILE_TYPES))
  const [droped, setDroped] = useState(false)

  const cancelFileaUpload = () => {
    setAcceptedFiles([])
    setNoAcceptedFiles([])
    sendFiles(null)
  }

  useImperativeHandle(ref, () => ({ cancelFileaUpload }))

  const fileHandler = currentFiles => {
    const formData = new FormData()
    for (let i = 0; i < currentFiles.length; i++) {
      const reader = new FileReader()
      reader.readAsArrayBuffer(currentFiles[i])

      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setLoading(false)
          if (reader.result) {
            const blob = new Blob([reader.result])
            formData.append('image', blob, currentFiles[i].name)
          }
        }
      }
    }
    sendFiles(formData)
  }

  const dataControl = (currentFile, arrFileNames) => {
    if (arrFileNames.includes(currentFile.name)) {
      return { status: STATUS_TYPES.ERROR, value: ERROR_MESSAGE_FILES.DUPLICATE_FILE }
    }

    if (currentFile.size > converterMbInBytes(maxSizeInMB)) {
      return { status: STATUS_TYPES.ERROR, value: ERROR_MESSAGE_FILES.EXEEDED_SIZE }
    }

    const wanted = fileData.arrExtAccepted.find(fa => fa.type === currentFile.type)
    if (wanted) return { status: STATUS_TYPES.SUCCESS, value: '' }
    return { status: STATUS_TYPES.ERROR, value: ERROR_MESSAGE_FILES.INVALID_TYPE }
  }

  const handleOrderFiles = files => {
    setLoading(true)
    const filesAcceptedNames = acceptedFiles.map(f => f.name)
    let filesToSend = []
    for (let i = 0; i < files.length; i++) {
      const UID = i + Math.floor(Math.random() * 999999)
      const { status, value } = dataControl(files[i], filesAcceptedNames)
      if (status === 'success') {
        setAcceptedFiles(prevState => ([
          ...prevState,
          {
            id: UID,
            logo: fileData.arrExtAccepted.find(item => item.type === files[i].type)?.logo || defaultLogo,
            name: files[i]?.name
          }
        ]))
        files[i].id = UID
        filesToSend = [...filesToSend, files[i]]
        setFilesAcceptedToSend(prevState => ([
          ...prevState,
          files[i]
        ]))
        fileHandler(filesToSend)
      } else {
        setNoAcceptedFiles(prevState => ([
          ...prevState,
          {
            id: UID,
            name: files[i]?.name,
            errorType: value
          }
        ]))
        setLoading(false)
      }
    }
  }

  const removeFile = fileId => {
    const filesFiltered = acceptedFiles.filter(f => f.id !== fileId)
    setAcceptedFiles(filesFiltered)
    if (filesFiltered.length === 0) sendFiles(null)
    const filesFilteredToSend = filesAcceptedToSend.filter(f => f.id !== fileId)
    setFilesAcceptedToSend(filesFilteredToSend)
  }

  const handleChange = e => {
    const { files } = e.target
    if ((files.length > 0) && !droped) {
      handleOrderFiles(files)
    }
  }

  const handleDrop = e => {
    const { files } = e.dataTransfer
    if (files.length > 0 && e.dataTransfer) {
      handleOrderFiles(files)
      setDroped(true)
      setTimeout(() => {
        setDroped(false)
      }, 100)
    }
  }

  return (
    <StyledUploader
      onDrop={handleDrop}
      name='dropZone'
      device={device}
    >
      {showLoader
        ? (
          <DropzoneContainer>
            <TextContainer>
              <FakeButton device={device} color={color}>
                <Icon name='upload' size='medium' />
                <Text size='medium' weight='main' align='center'>
                  {FILE_UPLOADER_TEXTS.UPLOAD_FILES}
                </Text>
              </FakeButton>
              <Text size='medium' weight='main' align='center'>
                {FILE_UPLOADER_TEXTS.TYPE_ACCEPTED}
              </Text>
              <Text size='medium' weight='main' align='center'>
                {getTextTypeAccepted(fileAccept)}
              </Text>
            </TextContainer>
            <StyledInputFile
              id='fileControl'
              type='file'
              onChange={handleChange}
              accept={fileData?.strAccept}
              disabled={disabled}
              multiple={multiple}
            />
          </DropzoneContainer>
        )
        : (
          <LoaderContainer name='loaderContainer'>
            <CircularProgressBar progress={uploaded} width='70' height='70' fullLoadcolor={color} />
            <SolidButton
              size={device === BREAKPOINTS.MOBILE ? 'medium' : 'small'}
              color={color}
              text={FUNCTION_TYPE.CANCEL.label}
              icon='cancel'
              onClick={cancelUpload}
              block={device === BREAKPOINTS.MOBILE}
            />
          </LoaderContainer>
        )}
      {errorFileUpload
        ? (
          <ErrorTextContainer>
            <Text color='error' size='large' align='left' weight='bold'>{errorFileUpload}</Text>
          </ErrorTextContainer>
        ) : (
          <>
            <AcceptedFilesComponent
              name='acceptedFiles'
              acceptedFiles={acceptedFiles}
              text={FILE_UPLOADER_TEXTS.FILES_ACCEPTED}
              loading={loading}
              removeFile={removeFile}
              device={device}
              color={color}
            />
            <NoAcceptedFilesComponent
              noAcceptedFiles={noAcceptedFiles}
              text={FILE_UPLOADER_TEXTS.FILES_NO_ACCEPTED}
            />
          </>
        )}
    </StyledUploader>
  )
})

export default FileUploader

FileUploader.propTypes = {
  fileAccept: PropTypes.array,
  maxSizeInMB: PropTypes.number,
  sendFiles: PropTypes.func,
  showLoader: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  uploaded: PropTypes.number,
  cancelUpload: PropTypes.func,
  errorFileUpload: PropTypes.bool,
  color: MyPropTypes.allColors
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'

import { THEME_NAME } from '@global/constants'
import { Text } from 'src/baseComponents/texts'

import { ToggleContainer, CustomCheckbox, CustomLabel, MoonIcon, SunIcon, ToggleSpan } from './styles'

export const ToggleButtonTheme = ({ onChange, theme, showText, color }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (theme === THEME_NAME.DARK) setChecked(true)
    else setChecked(false)
  }, [theme])

  return (
    <ToggleContainer showText={showText}>
      {showText && <Text size='medium' weight='bold'>{theme}</Text>}
      <CustomLabel>
        <CustomCheckbox type='checkbox' onChange={() => onChange()} checked={checked} />
        <MoonIcon className='material-icons' name='dark_mode' color={color}>dark_mode</MoonIcon>
        <SunIcon className='material-icons' name='ligth_mode' color={color}>light_mode</SunIcon>
        <ToggleSpan className='toggle' checked={checked} />
      </CustomLabel>
    </ToggleContainer>

  )
}

ToggleButtonTheme.propTypes = {
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string,
  showText: PropTypes.bool,
  color: MyPropTypes.allColors
}

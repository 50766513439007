export const key = 'DASHBOARD'

export const DASHBOARD = {
  GET: 'DASHBOARD/WORKSPACES/GET',
  SUCCESS: 'DASHBOARD/WORKSPACES/SUCCESS',
  ERROR: 'DASHBOARD/WORKSPACES/ERROR',
  CLEAR: 'DASHBOARD/WORKSPACES/CLEAR',
  CREATE_WORKSPACE: 'DASHBOARD/WORKSPACES/CREATE',
  WORKSPACE_SUCCESS: 'DASHBOARD/WORKSPACES/CREATE/SUCCESS',
  ERROR_MESSAGE: 'DASHBOARD/MESSAGE/ERROR',
  CLEAR_MESSAGE: 'DASHBOARD/MESSAGE/CLEAR',
  SUCCESS_MESSAGE: 'DASHBOARD/MESSAGE/SUCCESS',
  SUCCESS_MESSAGE_CLEAR: 'DASHBOARD/MESSAGE/SUCCESS/CLEAR',
  ERROR_MESSAGE_CLEAR: 'DASHBOARD/MESSAGE/SUCCESS',
  GET_OPERATIONS: 'DASHBOARD/OPERATIONS/GET',
  OPERATIONS_SUCCESS: 'DASHBOARD/OPERATIONS/GET/SUCCESS'
}

export default key

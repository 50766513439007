import { THEME_NAME } from '@global/constants'
import { spacing } from '@global/theme'
import styled from 'styled-components'

export const OPERATION_HEIGHT = spacing.five

export const OperationContainer = styled.div`
  width: 100%;
  height: ${OPERATION_HEIGHT};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${spacing.one};
  align-items: center;
  padding: ${spacing.one};
  cursor: pointer;
  background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.content
    return theme.colors.background.light
  }};
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.grays.disabled
    return theme.colors.grays.main
  }};

  &:hover {
    background-color: ${({ theme }) => {
    if (theme.name === THEME_NAME.LIGHT) return theme.colors.background.variant
    return theme.colors.background.variant
  }};
  }

  & > :nth-child(1) {
    grid-column-start: ${({ columnStart }) => columnStart || 1};
    grid-column-end: ${({ columnEnd }) => columnEnd || 2};
  }

  & > :nth-child(2) {
    grid-column-start: ${({ columnStart }) => columnStart || 2};
    grid-column-end: ${({ columnEnd }) => columnEnd || 5};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > :nth-child(3) {
    grid-column-start: ${({ columnStart }) => columnStart || 5};
    grid-column-end: ${({ columnEnd }) => columnEnd || 8};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > :nth-child(4) {
    grid-column-start: ${({ columnStart }) => columnStart || 8};
    grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  }

  & > :nth-child(5){
    grid-column-start: ${({ columnStart }) => columnStart || 13};
    grid-column-end: ${({ columnEnd }) => columnEnd || 14};
    color: ${({ theme }) => theme.colors.text.content}};
  }
`

import styled from 'styled-components'
import { spacing } from '@global/theme'

export const FileItemsContainer = styled.div`
 display: flex;
 justify-content: flex-start;
 align-items: center;
 flex-direction: column;
 width: 100%;
 max-height: 200px;
 margin: ${spacing.one} 0;
 overflow-y: auto;
`

import styled from 'styled-components'
import { spacing } from '@global/theme'
import { THEME_NAME } from '@global/constants'
import { SourceCodePro } from '@global/fonts'

export const AvatarsWrapper = styled.div`
  height: ${spacing.four};
  width: ${spacing.four};
  border: ${spacing.quarter} solid ${({ theme }) => theme.colors.background.content};
  border-radius: 50%;
  box-sizing: content-box;
  margin-left: -${spacing.oneAndAHalf};
  background-color: 
  ${({ theme }) => (theme.name === THEME_NAME.LIGHT ? theme.colors.grays.light : theme.colors.grays.main)};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Wrapper = styled.div`
  position: relative;
  &:hover {
    & > :last-child {
      top: -${spacing.four};
      left: -${spacing.two};
      display: block;
      z-index: 5;
      position: absolute;
      width: max-content;
      padding: ${spacing.one} ${spacing.two};
      ${SourceCodePro};
      color: ${({ theme }) => theme.colors.text.content};
      background-color: 
      ${({ theme }) => (theme.name === THEME_NAME.LIGHT ? theme.colors.grays.light : theme.colors.grays.main)};
    }
  }
`

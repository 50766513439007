export const userAdapter = user => ({
  id: user.id,
  lastLogin: user.last_login,
  isSuperuser: user.is_superuser,
  username: user.username,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  isStaff: user.is_staff,
  isActive: user.is_active,
  dateJoined: user.date_joined,
  groups: user.groups,
  userPermissions: user.user_permissions
})

import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectMediaFileSummary = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectMediaFileSummary, state => get(state, 'loading', null)
)

const selectMediaFileData = createSelector(
  selectMediaFileSummary, state => get(state, 'mediaFileData', null)
)

const selectMediaFileVersions = createSelector(
  selectMediaFileSummary, state => get(state, 'mediaFileVersions', null)
)

const selectMediaFileUrls = createSelector(
  selectMediaFileSummary, state => get(state, 'mediaFileUrls', null)
)

const selectMessageSuccess = createSelector(
  selectMediaFileSummary, state => get(state, 'messageSuccess', null)
)

const selectMessageError = createSelector(
  selectMediaFileSummary, state => get(state, 'messageError', null)
)

export default {
  LOADING: selectLoading,
  MEDIA_FILE_DATA: selectMediaFileData,
  MEDIA_FILE_VERSIONS: selectMediaFileVersions,
  MEDIA_FILE_URLS: selectMediaFileUrls,
  SUCCESS_MESSAGE: selectMessageSuccess,
  ERROR_MESSAGE: selectMessageError
}

import React from 'react'
import PropTypes from 'prop-types'

import MyPropTypes from '@global/propTypes'

import { StyledIcon } from './styles'

const Icon = ({ id, name, circle, size, color, spin, disabled, onClick }) => (
  <StyledIcon
    id={id}
    className='material-icons'
    circle={circle}
    size={size}
    color={color}
    spin={spin}
    disabled={disabled}
    onClick={disabled ? null : onClick}
  >
    {name}
  </StyledIcon>
)

Icon.defaultProps = {
  onClick: null
}

Icon.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  circle: PropTypes.bool,
  size: MyPropTypes.size,
  color: MyPropTypes.color,
  disabled: PropTypes.bool,
  spin: PropTypes.bool,
  onClick: PropTypes.func
}

export default Icon

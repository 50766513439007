import styled from 'styled-components'

import { spacing } from '@global/theme'
import { BREAKPOINTS } from '@global/constants'

export const ModalDataContainer = styled.div`
width: 100%;
height: auto;
padding: ${spacing.three};
margin-bottom: ${spacing.three};

border-radius: ${spacing.one};
background: ${({ theme }) => theme.colors.background.main};
`

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};

  ${({ theme, buttonColor }) => buttonColor && (`> button { background-color: ${theme.colors.background.main}}`)}

  & > :first-child {
    margin-right: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.two)};
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};
  }
`

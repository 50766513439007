import React from 'react'
import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'

import { BREAKPOINTS } from '@global/constants'
import Icon from 'src/baseComponents/icon'
import { Text } from 'src/baseComponents/texts'

import { DataContainer, FileContainer, StyledImg, LoadingContainer } from './styles'

const FileItem = ({ name, logo, loading, cancelFileaUpload, device, color }) => (
  <DataContainer name={name} device={device} color={color}>
    <FileContainer name='FileContainer' device={device} color={color}>
      {loading && <Icon name='close' backgroundColor='background' size='medium' color='grays' />}
      <StyledImg src={logo} />
      <Text
        size='medium'
        weight='main'
        align={device === BREAKPOINTS.MOBILE ? 'center' : 'left'}
      >
        {name}
      </Text>
    </FileContainer>
    <LoadingContainer name='LoadingContainer' color={color}>
      <Icon
        name={loading ? 'sync' : 'delete'}
        backgroundColor='background'
        size='medium'
        spin={loading}
        onClick={!loading ? cancelFileaUpload : () => {}}
      />
    </LoadingContainer>
  </DataContainer>

)

FileItem.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
  loading: PropTypes.bool,
  cancelFileaUpload: PropTypes.func,
  device: PropTypes.number,
  color: MyPropTypes.allColors
}

export default FileItem

import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectWorkspaceProfiles = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectWorkspaceProfiles, state => get(state, 'loading', null)
)

const selectWorkspaceProfilesSuccess = createSelector(
  selectWorkspaceProfiles, state => get(state, 'workspaceProfiles', null)
)

const selectLoadingWorkspaceProfiles = createSelector(
  selectWorkspaceProfiles, state => get(state, 'loadingWorkspaceProfiles', null)
)

const selectProfileOperationsSuccess = createSelector(
  selectWorkspaceProfiles, state => get(state, 'profileOperations', {})
)

const selectLoadingProfileOperations = createSelector(
  selectWorkspaceProfiles, state => get(state, 'loadingProfileOperations', null)
)

const selectAllOperations = createSelector(
  selectWorkspaceProfiles, state => get(state, 'allOperations', null)
)

const selectLoadingAllOperations = createSelector(
  selectWorkspaceProfiles, state => get(state, 'loadingAllOperations', false)
)

const selectMessageSuccess = createSelector(
  selectWorkspaceProfiles, state => get(state, 'messageSuccess', null)
)

const selectMessageError = createSelector(
  selectWorkspaceProfiles, state => get(state, 'messageError', null)
)

export default {
  LOADING: selectLoading,
  WORKSPACE_PROFILES: selectWorkspaceProfilesSuccess,
  LOADING_WORKSPACE_PROFILES: selectLoadingWorkspaceProfiles,
  PROFILE_OPERATIONS: selectProfileOperationsSuccess,
  LOADING_PROFILE_OPERATIONS: selectLoadingProfileOperations,
  ALL_OPERATIONS: selectAllOperations,
  LOADING_ALL_OPERATIONS: selectLoadingAllOperations,
  SUCCESS_MESSAGE: selectMessageSuccess,
  ERROR_MESSAGE: selectMessageError
}

export const M_PROFILE = {
  status: 200,
  data: {
    fullname: 'Bruno Diaz',
    email: 'bruno.diaz@gmail.com',
    profession: 'Empresario'
  }
}

export const M_PROFILE_UPDATED = {
  status: 200,
  data: {
    fullname: 'Batman',
    email: 'batman@ciudadgotica.gov',
    profession: 'Superheroe'
  }
}

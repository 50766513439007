import { put, call, takeLatest } from 'redux-saga/effects'
import { jwtDecode } from 'jwt-decode'

import Axios from '@axios/'
import { TOKEN_HELPERS } from '@utils/helpers'

import { userAdapter } from '../adapter/user.adapter'
import { USER_ACTIONS } from './action'
import { USER } from './types'

const apiCalls = {
  signIn: credentials => Axios.post('api/token/', credentials),
  getUserDetail: ({ userId, token }) => Axios.get(`users/${userId}`, {
    headers: {
      Authorization: TOKEN_HELPERS.SET_BEARER(token)
    }
  }),
  sendPasswordRecovery: email => Axios.post(`Account/passwordrecovery?email=${email}`),
  sendPasswordConfirm: data => Axios.post('Account/passwordconfirm', data),
  refreshUser: token => Axios('Account', {
    headers: {
      Authorization: TOKEN_HELPERS.SET_BEARER(token)
    }
  }),
  getImageProfile: ({ fileId, token }) => (
    Axios(`File/${fileId}/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  sendDataUserApi: ({ token, data }) => Axios.post('auth0/user/update', data, {
    headers: {
      Authorization: TOKEN_HELPERS.SET_BEARER(token)
    }
  })
}

export function* getUser ({ payload }) {
  try {
    const { data } = yield call(apiCalls.signIn, payload)
    const { access, refresh } = data
    const decoded = jwtDecode(access)
    const { data: userApi } = yield call(apiCalls.getUserDetail, { userId: decoded.user_id, token: access })

    let user = userAdapter(userApi)
    user = {
      username: user?.username,
      email: user?.email
    }
    localStorage.setItem('user', JSON.stringify(user))
    const authTokens = {
      access,
      refresh
    }
    localStorage.setItem('authTokens', JSON.stringify(authTokens))
    yield put(USER_ACTIONS.SUCCESS(user))
  } catch (error) {
    // interim solution until the error sending text is changed
    yield put(USER_ACTIONS.ERROR(error || 'error'))
  }
}

export function* passwordRecovery ({ payload }) {
  try {
    const { email } = payload
    const res = yield call(apiCalls.sendPasswordRecovery, email)
    return res
  } catch (error) {
    const { message } = error
    return message
  }
}

export function* passwordConfirm ({ payload }) {
  try {
    const res = yield call(apiCalls.sendPasswordConfirm, payload)
    const { status } = res
    yield put(USER_ACTIONS.RESPONSE_PASSWORD_CONFIRM(status))
  } catch (error) {
    yield put(USER_ACTIONS.RESPONSE_PASSWORD_CONFIRM(error.response))
  }
}

export function* refreshUser ({ payload }) {
  try {
    const { data } = yield call(apiCalls.refreshUser, payload)
    const userLocalStorage = JSON.parse(localStorage.getItem('user'))
    const user = {
      email: data.email,
      fullName: data.fullName,
      userId: data.userId,
      token: payload,
      tokenMinutes: userLocalStorage.tokenMinutes,
      tokenCreationDate: new Date(userLocalStorage.tokenCreationDate)
    }

    yield put(USER_ACTIONS.SUCCESS(user))
  } catch (error) {
    yield put(USER_ACTIONS.ERROR_USER_REFRESH(error))
  }
}

export function* sendDataUser ({ payload }) {
  try {
    const { status } = yield call(apiCalls.sendDataUserApi, payload)

    yield put(USER_ACTIONS.SEND_DATA_USER_SUCCESS(status))
  } catch (error) {
    yield put(USER_ACTIONS.SEND_DATA_USER_ERROR(error.response))
  }
}

export default function* saga () {
  yield takeLatest(USER.GET, getUser)
  yield takeLatest(USER.PASSWORD_RECOVERY, passwordRecovery)
  yield takeLatest(USER.PASSWORD_CONFIRM, passwordConfirm)
  yield takeLatest(USER.REFRESH_USER, refreshUser)
  yield takeLatest(USER.SEND_DATA_USER, sendDataUser)
}

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  pointer-events: ${({ disabled }) => disabled && 'pointer-events-none'};
  cursor: ${({ onClick }) => onClick && 'cursor-pointer'};

  > * {
    color: ${({ color, disabled, theme, inputType }) => {
    if (disabled) return theme.colors.text.disabled
    if (inputType === INPUT_TYPES.NUMBER && color !== 'error') return theme.colors.text.content
    if (color) return theme.colors[color]?.main
    return theme.colors.text.content
  }}!important;
  }

`
export const Img = styled.img`
  width: ${({ size }) => (size || '20px')};
  height: ${({ size }) => (size || '20px')};
  ${({ disabled }) => disabled && 'filter:  grayscale(100%) opacity(25%)'};
`

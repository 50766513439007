import axios from 'axios'
import { MESSAGES } from '@global/message'

const baseURL = process.env.REACT_APP_BASE_URL

const Axios = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

Axios.interceptors.response.use(
  response => {
    const { data, status, headers } = response
    return { data, status, headers }
  },
  async error => {
    const { data, status } = error.response
    let message = ''
    switch (status) {
      case 500:
        message = MESSAGES.SERVER_ERROR
        break
      default: {
        message = data?.detail || data?.error
        break
      }
    }

    const errorObj = { status, message }
    throw errorObj
  }
)

export default Axios

import { DASHBOARD } from './types'

export const getWorkspaces = payload => ({
  type: DASHBOARD.GET,
  payload
})

export const getWorkspacesSuccess = payload => ({
  type: DASHBOARD.SUCCESS,
  payload
})

export const workspacesSuccess = payload => ({
  type: DASHBOARD.WORKSPACE_SUCCESS,
  payload
})

export const getWorkspacesError = payload => ({
  type: DASHBOARD.ERROR,
  payload
})

export const clearWorkspaces = () => ({
  type: DASHBOARD.CLEAR
})

export const createNewWorkspace = payload => ({
  type: DASHBOARD.CREATE_WORKSPACE,
  payload
})

// MESSAGES
export const successMessage = payload => ({
  type: DASHBOARD.SUCCESS_MESSAGE,
  payload
})

export const clearSuccessMessage = () => ({
  type: DASHBOARD.SUCCESS_MESSAGE_CLEAR
})

export const errorMessage = payload => ({
  type: DASHBOARD.ERROR_MESSAGE,
  payload
})

export const clearErrorMessage = () => ({
  type: DASHBOARD.ERROR_MESSAGE_CLEAR
})

export const getOperations = payload => ({
  type: DASHBOARD.GET_OPERATIONS,
  payload
})

export const operationsSuccess = payload => ({
  type: DASHBOARD.OPERATIONS_SUCCESS,
  payload
})

export const DASHBOARD_ACTIONS = {
  GET: getWorkspaces,
  SUCCESS: getWorkspacesSuccess,
  ERROR: getWorkspacesError,
  WORKSPACE_SUCCESS: workspacesSuccess,
  CLEAR: clearWorkspaces,
  CREATE_WORKSPACE: createNewWorkspace,
  SUCCESS_MESSAGE: successMessage,
  SUCCESS_MESSAGE_CLEAR: clearSuccessMessage,
  ERROR_MESSAGE: errorMessage,
  ERROR_MESSAGE_CLEAR: clearErrorMessage,
  GET_OPERATIONS: getOperations,
  OPERATIONS_SUCCESS: operationsSuccess
}

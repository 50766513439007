import React from 'react'
import PropTypes from 'prop-types'

import { spacing } from '@global/theme'
import defaultImage from '@assets/placeholder.png'
import { Heading, Text } from '@baseComponents/texts'

import { BannerContainer, ImageBanner } from './styles'

const Banner = ({ imagePath, imgWidth, imgHeight, title, text, rowGrid, columnStart, columnEnd }) => (
  <BannerContainer rowGrid={rowGrid} columnStart={columnStart} columnEnd={columnEnd}>
    <ImageBanner src={imagePath || defaultImage} alt='image banner' width={imgWidth} height={imgHeight} />
    <Heading size='small' type='h2' align='left' marginBottom={spacing.two}>{title}</Heading>
    <Text align='left' size='medium'>{text}</Text>
  </BannerContainer>
)

Banner.propTypes = {
  imagePath: PropTypes.string,
  imgWidth: PropTypes.string,
  imgHeight: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  rowGrid: PropTypes.number,
  columnStart: PropTypes.number,
  columnEnd: PropTypes.number
}

export default Banner

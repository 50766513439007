import styled from 'styled-components'

import { spacing } from '@global/theme'
import { SourceCodePro } from '@global/fonts'

export const StyledTextArea = styled.textarea`
  ${SourceCodePro};
  width: 100%;
  outline: 1px solid ${({ theme, error }) => (error ? theme.colors.error.main : theme.colors.text.disabled)};
  background-color: ${({ theme }) => theme.colors.background.content}!important;
  color: ${({ error, disabled, theme }) => {
    if (disabled) return theme.colors.text.disabled
    if (error) return theme.colors.error.main
    return theme.colors.text.content
  }
};
  
  border-radius: ${spacing.threeQuarters};
  border-style: none;
  box-shadow: 0 1px ${spacing.quarter} 0 rgb(0 0 0 / 0.05);
  padding: ${spacing.one};
  resize: ${({ resize }) => resize || 'vertical'};
  min-height: 100px;

  &:focus {
  outline: ${spacing.quarter} solid ${({ theme, color, error }) => {
  if (error) return theme.colors.error.main
  if (color) return theme.colors[color]?.main
  return theme.colors.text.main
}
};
    outline-offset: 1px;
  }
`

export const TextContainer = styled.div`
  margin-left: auto;
  padding-right: ${spacing.half};
  color: ${({ theme, color }) => (color && theme.colors[color]?.main)};
  & > p {
    color: ${({ error, theme }) => (error && theme.colors.error.main)}!important;
  }
`

export const filetypeStrConstructor = (types, acceptedTypes) => {
  if (types.length === 0) return ''

  let str = ''
  const separator = ', '

  for (let i = 0; i < types.length; i++) {
    const fileExtention = types[i]
    str = acceptedTypes.find(item => item.name === fileExtention).type
    str += separator
  }
  return str
}

export const converterMbInBytes = (megaBytes = 1) => megaBytes * 1048576

export const getTextTypeAccepted = arrTypesAccepted => {
  if (arrTypesAccepted.length === 1) return arrTypesAccepted[0]
  if (arrTypesAccepted.length === 2) return `${arrTypesAccepted[0]} y ${arrTypesAccepted[1]}`
  const initialValue = ''
  const text = arrTypesAccepted.reduce(
    (accumulator, currentValue, i = 0) => {
      let finalText = ''
      if (i === arrTypesAccepted.length - 2) finalText = ' y '
      if (i < (arrTypesAccepted.length - 2)) finalText = ', '
      i++
      return `${accumulator + currentValue}${finalText}`
    },
    initialValue
  )
  return text
}

import { PROFILE } from './types'

export const getProfile = payload => ({
  type: PROFILE.GET_PROFILE,
  payload
})

export const getProfileSuccess = payload => ({
  type: PROFILE.GET_PROFILE_SUCCESS,
  payload
})

export const getProfileError = payload => ({
  type: PROFILE.GET_PROFILE_ERROR,
  payload
})

export const editProfile = payload => ({
  type: PROFILE.EDIT_PROFILE,
  payload
})

export const profileUpdated = payload => ({
  type: PROFILE.PROFILE_UPDATED,
  payload
})

export const messageInformation = payload => ({
  type: PROFILE.MESSAGE_INFORMATION,
  payload
})

export const editImageProfile = payload => ({
  type: PROFILE.EDIT_IMAGE_PROFILE,
  payload
})

export const PROFILE_ACTIONS = {
  GET_PROFILE: getProfile,
  GET_PROFILE_SUCCESS: getProfileSuccess,
  GET_PROFILE_ERROR: getProfileError,
  EDIT_PROFILE: editProfile,
  PROFILE_UPDATED: profileUpdated,
  MESSAGE_INFORMATION: messageInformation,
  EDIT_IMAGE_PROFILE: editImageProfile
}

import React, { useEffect, useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Divider, MyLink, WrapperBreadcrumbs, WrapperHeading, WrapperLoading } from '@global/styles'
import { SITE } from '@routes/paths'
import { TEXT_CONTENT, TITLES, TOAST_POSITION, TOAST_PROPERTIES } from '@global/constants'

import { useNotification } from 'src/context/notification.context'

import FileManager from '@containers/fileManager'
import { Heading } from '@baseComponents/texts'
import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import { useWindowDimensions } from '@components/windowDimensions'
import { useUser } from '@containers/authentication/utils/hook'
import AvatarsGroup from '@components/avatarsGroup'
import { GhostButton, SolidButton } from '@baseComponents/button'

import useRedux from './redux'
import { WORKSPACE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { AvatarsContainer, ButtonsContainer } from './styles'

const Workspace = () => {
  useRedux()
  const { workspaceId } = useParams()
  const { pathname, key } = useLocation()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()
  const { getNotification } = useNotification()
  const { GetToken } = useUser()
  const childRef = useRef()

  const [workspace, setWorkspace] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [rootFolder, setRootFolder] = useState(null)
  const [usersWorkspace, setUsersWorkspace] = useState([])

  const loading = useSelector(SELECTORS.LOADING)
  const workspaceData = useSelector(SELECTORS.WORKSPACE)
  const successMessage = useSelector(SELECTORS.SUCCESS_MESSAGE)

  const folderClick = folderId => {
    setRootFolder(folderId)
  }

  const handleUploadFile = () => childRef.current?.handleUploadFile()
  const handleCreateFolder = () => childRef.current?.handleCreateFolder()

  useEffect(async () => {
    const token = await GetToken()
    dispatch(WORKSPACE_ACTIONS.GET_WORKSPACE_DATA({ token, workspaceId }))
  }, [key])

  useEffect(() => {
    if (workspaceData) {
      const firstCrubm = {
        text: TITLES.PROJECTS,
        url: SITE.DASHBOARD
      }
      const secondCrubm = {
        text: `${workspaceData?.name}`,
        url: pathname
      }
      setBreadcrumbs([firstCrubm, secondCrubm])
      setWorkspace(workspaceData)
      setRootFolder(workspaceData.rootFolder)
      setUsersWorkspace(workspaceData.users)
    }
  }, [workspaceData])

  useEffect(() => {
    if (successMessage) {
      const { status, message } = successMessage
      getNotification({
        title: TOAST_PROPERTIES.SUCCESS.title,
        message: message || status,
        icon: TOAST_PROPERTIES.SUCCESS.icon,
        color: TOAST_PROPERTIES.SUCCESS.color,
        position: TOAST_POSITION.rightTop,
        open: true
      })
      setTimeout(() => {
        dispatch(WORKSPACE_ACTIONS.CLEAR_SUCCESS_MESSAGE())
      }, 2000)
    }
  }, [successMessage])

  return (
    loading
      ? (
        <WrapperLoading>
          <Loading color='primary' size='xsmall' weight='bold' />
        </WrapperLoading>
      )
      : (
        <>
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart={1} columnEnd={6} rowStart={1} rowEnd={1}>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <ButtonsContainer columnStart={6} columnEnd={13} rowStart={1} rowEnd={1} device={device}>
              <GhostButton
                size='medium'
                color='primary'
                icon='create_new_folder'
                text={TEXT_CONTENT.CREATE_FOLDER}
                onClick={handleCreateFolder}
              />
              <SolidButton
                size='medium'
                color='primary'
                icon='upload_file'
                text={TEXT_CONTENT.UPLOAD_FILE}
                onClick={handleUploadFile}
              />
            </ButtonsContainer>
          </>
          <WrapperHeading columnStart={1} columnEnd={13} rowStart={2} rowEnd={2}>
            <Heading type='h1' size='large'>
              {`${workspace?.name} `}
              <MyLink
                to={{
                  pathname: `${pathname}/edit`,
                  state: { breadcrumbs, workspaceName: workspace?.name }
                }}
                textDecoration='underline'
              >
                (Edit)
              </MyLink>
            </Heading>
            <AvatarsContainer>
              <AvatarsGroup users={usersWorkspace} />
            </AvatarsContainer>
          </WrapperHeading>
          <Divider rowGrid={3} columnStart={1} columnEnd={13} />
          {rootFolder
            ? (
              <FileManager
                ref={childRef}
                rowGrid={4}
                columnStart={1}
                columnEnd={13}
                folderClick={folderClick}
                rootFolderId={rootFolder}
                breadcrumbs={breadcrumbs}
              />
            ) : null}
        </>
      )
  )
}

export default Workspace

import styled from 'styled-components'
import { spacing } from '@global/theme'

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  grid-column-start: ${({ columnStart }) => columnStart || 1};
  grid-column-end: ${({ columnEnd }) => columnEnd || 13};
  grid-row-start: ${({ rowGrid }) => rowGrid || 1};
  grid-row-end: ${({ rowGrid }) => rowGrid || 1};
  margin: ${spacing.two} 0;
`

export const ArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > :first-child {
    margin-right: ${spacing.two};
  }
`

import styled, { keyframes, css } from 'styled-components'

import { spacing } from '@global/theme'
import { Column, Row } from '@global/styles'
import { BREAKPOINTS, THEME_NAME } from '@global/constants'

export const openModal = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
`

export const closeModal = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
`

export const open = css`
  animation: ${openModal} .5s;
`

export const close = css`
  animation: ${closeModal} .5s;
`

export const Overlay = styled.aside`
  position: fixed;
  top: 0;
  left: ${({ isShowing }) => (isShowing ? '0%' : '100%')};
  width: 100%;
  height: 100%;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.25);

  overflow: hidden;
  ${({ isShowing }) => (isShowing ? open : close)};
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isShowing, left, device }) => {
    if (isShowing && left) return left
    if (isShowing) {
      if (device < BREAKPOINTS.TABLET) return '30%'
      if (device < BREAKPOINTS.DESKTOP) return '50%'
      return '60%'
    }
    return '100%'
  }};
  z-index: 11;
  width: ${({ widthWrapper, device }) => {
    if (widthWrapper) return widthWrapper
    if (device < BREAKPOINTS.TABLET) return '70%'
    if (device < BREAKPOINTS.DESKTOP) return '50%'
    return '40%'
  }};
  height: 100%;
  overflow: auto;
  outline: 0;
  background-color: ${({ theme }) => theme.colors.background.main};

  ${({ isShowing }) => (isShowing ? open : close)};
`

export const Area = styled(Column)`
  width: ${({ widthArea }) => (widthArea || '500px')};
  max-width: 90%;
  height: auto;
  min-height: ${({ minHeight }) => minHeight || 'auto'};

  top: ${({ top }) => top || '15%'};
  margin: auto;
  padding: ${({ transparent, padding }) => {
    if (transparent) return 0
    if (padding) return padding
    return spacing.four
  }};

  z-index: 12;
  border-radius: 8px;

  position: relative;
  align-items: center;

  background-color: ${({ theme, transparent }) => {
    if (transparent) return 'transparent'
    if (theme.name === THEME_NAME.DARK) return theme.colors.background.variant
    return theme.colors.background.content
  }};
  transform: height 1s;
  display: flex;
  flex-direction: column;
  
`

export const Close = styled.div`
  > span {
    position: absolute;
    top: -${spacing.six};
    right: 0;
    align-self: flex-end;
    cursor: pointer;

    padding: 0;
    height: auto;
    color: ${({ theme }) => theme.colors.text.content};

    &:hover {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }

  > p {
    position: absolute;
    top: -${spacing.fourAndAHalf};
    left: 0;
    align-self: flex-end;
    cursor: pointer;

    padding: 0;
    height: auto;
    color: ${({ theme }) => theme.colors.text.content};
  }
`

export const IconContainer = styled.div`
  margin-bottom: ${spacing.one};
`

export const ButtonContainer = styled(Row)`
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-top: ${spacing.threeAndAHalf};

  > :not(:first-child) {
    margin-left: ${spacing.two};
  }
`

import { SITE } from '@routes/paths'
import { TITLES } from '@global/constants'

export const navItems = [
  {
    id: 1,
    label: TITLES.PROJECTS,
    icon: 'dashboard',
    to: SITE.DASHBOARD,
    subMenus: []
  }
]

import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Axios from '@axios/'
import { CancelToken, isCancel } from 'axios'
import { ModalDataContainer, ButtonModalContainer, Column } from '@global/styles'
import {
  BREAKPOINTS,
  COLUMN_TYPE,
  FLEX_PROPERTIES,
  FUNCTION_TYPE, INITIAL_STATES,
  RADIO_BUTTON_STATUS_OPTIONS,
  SUBTITLES,
  TOAST_POSITION,
  TOAST_PROPERTIES
} from '@global/constants'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import FileUploader from '@components/fileUploader'
import { SolidButton } from 'src/baseComponents/button'
import { useWindowDimensions } from '@components/windowDimensions'
import { TextInput } from '@components/inputs/inputs'
import { useNotification } from 'src/context/notification.context'
import { TOKEN_HELPERS, changeNameForLabel } from '@utils/helpers'
import { useUser } from '@containers/authentication/utils/hook'
import Dropdown from '@components/inputs/inputs/dropdown'
import RadioInputs from '@components/inputs/inputs/components/radio'

const typeAccept = ['.jpeg', '.png', '.tif', '.bmp']

const IMAGE_PROPS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  SPECS: 'specs',
  UPDATED_AT: 'Updated_at',
  FOLDER: 'folder',
  STATUS: 'status'
}

const UploadFileModal = ({ successResult, close, rootFolderId, edit, oldData, linkedFolders = [] }) => {
  const { device } = useWindowDimensions()
  const { getNotification } = useNotification()
  const { GetToken } = useUser()

  const [filesToSend, setFilesToSend] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [uploaded, setUploaded] = useState(0)
  const [errorFileUpload, setErrorFileUpload] = useState(null)
  const [fileName, setFileName] = useState(
    edit
      ? { ...INITIAL_STATES.INPUT, value: oldData?.name }
      : INITIAL_STATES.INPUT
  )
  const [description, setDescription] = useState(
    edit
      ? { ...INITIAL_STATES.INPUT, value: oldData?.description }
      : INITIAL_STATES.INPUT
  )
  const [optionSelected, setOptionSelected] = useState()
  const [folders, setFolders] = useState([])
  const [radioButtonSelected, setRadioButtonSelected] = useState(edit ? oldData?.status : null)
  const handleInputChange = val => {
    const { name } = val
    if (name === SUBTITLES.NAME) setFileName(val)
    else setDescription(val)
  }

  const cancelFileUpload = useRef(null)
  const childRef = useRef()

  const cleanData = () => {
    setFilesToSend(null)
    setShowLoader(false)
  }

  const handleDropdownChange = e => setOptionSelected(e.value)
  const handleChangeRadioButtons = value => setRadioButtonSelected(value)

  const handleClick = async () => {
    const token = await GetToken()
    let data = null
    if (filesToSend) {
      filesToSend.append(IMAGE_PROPS.STATUS, radioButtonSelected)
      filesToSend.append(IMAGE_PROPS.FOLDER, optionSelected.id)
      filesToSend.append(IMAGE_PROPS.NAME, fileName.value)
      filesToSend.append(IMAGE_PROPS.DESCRIPTION, description.value)
      data = filesToSend
    } else {
      const formDataWithoutFile = new FormData()
      formDataWithoutFile.append(IMAGE_PROPS.STATUS, radioButtonSelected)
      formDataWithoutFile.append(IMAGE_PROPS.FOLDER, optionSelected.id)
      formDataWithoutFile.append(IMAGE_PROPS.NAME, fileName.value)
      formDataWithoutFile.append(IMAGE_PROPS.DESCRIPTION, description.value)
      data = formDataWithoutFile
    }
    setShowLoader(true)
    Axios({
      url: edit ? `image/${oldData.id}/` : 'image/',
      method: edit ? 'PUT' : 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----',
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        const percentCompleted = Math.round((loaded / total) * 100)
        setUploaded(percentCompleted)
      },
      cancelToken: new CancelToken(cancel => {
        cancelFileUpload.current = cancel
      })
    })
      .then(() => {
        cleanData()
        successResult(edit ? MESSAGES.FILE_EDITED : MESSAGES.UPLOAD_FILE_SUCCESS)
        close()
      })
      .catch(error => {
        const { status, message } = error
        getNotification({
          title: TOAST_PROPERTIES.ERROR.title,
          message: message || status,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color,
          position: TOAST_POSITION.leftTop,
          open: true
        })
        if (!isCancel(error)) {
          childRef.current?.cancelFileaUpload()
          setShowLoader(false)
        }
      })
  }

  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      setErrorFileUpload(MESSAGES.UPLOAD_CANCELED)
      cancelFileUpload.current(MESSAGES.UPLOAD_CANCELED)
      setShowLoader(false)
      childRef.current.cancelFileaUpload()
      setTimeout(() => setErrorFileUpload(false), 4000)
    }
  }

  useEffect(() => {
    const rootFolder = {
      id: rootFolderId,
      label: 'root'
    }
    const linkedFoldersFiltered = changeNameForLabel(linkedFolders.filter(lf => lf.name !== '/'))
    setOptionSelected(rootFolder)
    setFolders([...linkedFoldersFiltered, rootFolder])
  }, [])

  return (
    <ModalDataContainer flexDirection='column'>
      <RadioInputs
        type={device === BREAKPOINTS.MOBILE ? COLUMN_TYPE.VERTICAL : COLUMN_TYPE.HORIZONTAL}
        name='statusFolder'
        options={RADIO_BUTTON_STATUS_OPTIONS}
        onChange={handleChangeRadioButtons}
        alignItems={device === BREAKPOINTS.MOBILE ? FLEX_PROPERTIES.ALIGN_ITEMS.FLEX_START : 'none'}
      />
      <Column>
        <TextInput
          name={SUBTITLES.NAME}
          label={SUBTITLES.NAME}
          onChange={handleInputChange}
          color='primary'
          value={fileName.value}
        />
        <TextInput
          name={SUBTITLES.DESCRIPTION}
          label={SUBTITLES.DESCRIPTION}
          onChange={handleInputChange}
          color='primary'
          value={description.value}
        />
        <Dropdown
          name='folder'
          label={SUBTITLES.SELECT_FOLDER}
          placeholder={SUBTITLES.SELECT_FOLDER}
          options={folders}
          value={optionSelected}
          onChange={handleDropdownChange}
          color='primary'
          height='medium'
          block
        />
      </Column>
      <FileUploader
        ref={childRef}
        maxSizeInMB={100}
        sendFiles={setFilesToSend}
        showLoader={!showLoader}
        disabled={showLoader}
        fileAccept={typeAccept}
        uploaded={uploaded}
        cancelUpload={cancelUpload}
        errorFileUpload={errorFileUpload}
      />
      <ButtonModalContainer oneButton marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'large' : 'medium'}
          color='primary'
          text={edit ? FUNCTION_TYPE.EDIT.label : FUNCTION_TYPE.SAVE.label}
          icon='upload'
          onClick={handleClick}
          disabled={edit ? !edit : (!filesToSend || !radioButtonSelected)}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonModalContainer>
    </ModalDataContainer>
  )
}

UploadFileModal.propTypes = {
  close: PropTypes.func,
  successResult: PropTypes.func,
  rootFolderId: PropTypes.string,
  edit: PropTypes.bool,
  oldData: PropTypes.object,
  linkedFolders: PropTypes.array
}

export default UploadFileModal

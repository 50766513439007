import React from 'react'
import PropTypes from 'prop-types'

import MyPropTypes from '@global/propTypes'

import Icon from 'src/baseComponents/icon'
import { Text } from 'src/baseComponents/texts'
import {
  Wrapper,
  StyledCheckbox,
  MyLabel,
  Check,
  Container,
  MyMessage
} from './styles'

const Checkbox = ({ label, name, value, color, onChange, disabled, error, message, noText }) => {
  const handleClick = ({ target }) => {
    const { checked } = target
    onChange({
      name,
      value: checked
    })
  }

  const handleKeyPress = e => {
    if (e.charCode !== 13 && e.charCode !== 32) return

    e.preventDefault()
    handleClick({ target: { checked: !value } })
  }

  return (
    <Wrapper>
      <MyLabel htmlFor={`checkbox_${name}`} disabled={disabled} error={error}>
        <Container>
          <StyledCheckbox
            name={name}
            type='checkbox'
            id={`checkbox_${name}`}
            checked={value}
            disabled={disabled}
            onClick={handleClick}
            onChange={() => {}}
          />
          <Check
            color={error ? 'error' : color}
            checked={value}
            disabled={disabled}
            tabIndex={0}
            onKeyPress={handleKeyPress}
          >
            {value && <Icon name='check' size='small' disabled={disabled} />}
          </Check>
        </Container>
        {!noText
        && (
        <Text
          text={label || name}
          disabled={disabled}
          color={error ? 'error' : 'text'}
        />
        )}
      </MyLabel>
      {(error || message) && <MyMessage error={error} disabled={disabled} color={color}>{error || message}</MyMessage>}
    </Wrapper>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  color: MyPropTypes.color,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  message: PropTypes.string,
  error: PropTypes.string,
  noText: PropTypes.bool
}

export default Checkbox

import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { THEME_MODE, THEME_NAME } from '@global/constants'

const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(THEME_NAME.LIGHT)

  const toggleTheme = () => {
    if (theme === THEME_NAME.LIGHT) {
      localStorage.setItem(THEME_MODE, THEME_NAME.DARK)
      setTheme(THEME_NAME.DARK)
    } else {
      localStorage.setItem(THEME_MODE, THEME_NAME.LIGHT)
      setTheme(THEME_NAME.LIGHT)
    }
  }

  useEffect(() => {
    const selectedTheme = localStorage.getItem(THEME_MODE)
    if (selectedTheme) setTheme(selectedTheme)
    else setTheme(THEME_NAME.LIGHT)
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.any
}

export { ThemeContext, ThemeProvider }

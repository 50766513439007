import React, { Suspense, lazy, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { OPERATION_STATUS, TEXT_CONTENT, TOAST_POSITION, TOAST_PROPERTIES } from '@global/constants'
import { MESSAGES } from '@global/message'

import { useNotification } from 'src/context/notification.context'

import Icon from '@baseComponents/icon'
import { Text } from '@baseComponents/texts'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'
import Loading from '@components/loading'
import { useUser } from '@containers/authentication/utils/hook'

import useRedux from '../../redux'
import { WORKSPACE_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import { OperationContainer } from './styles'

const Modal = lazy(() => import('@components/modal'))

const OperationCard = ({ operationId, name, description, command, status, profileId }) => {
  useRedux()
  const { GetToken } = useUser()
  const dispatch = useDispatch()
  const { getNotification } = useNotification()

  const [open, setOpen] = useState(false)
  const [removeOperation, setRemoveOperation] = useState(null)

  const messageSuccess = useSelector(SELECTORS.SUCCESS_MESSAGE)
  const messageError = useSelector(SELECTORS.ERROR_MESSAGE)
  const workspaceProfiles = useSelector(SELECTORS.WORKSPACE_PROFILES)

  const handleCloseModal = () => {
    setRemoveOperation(null)
    setOpen(false)
  }

  const deleteWorkspace = async itemId => {
    const token = await GetToken()
    dispatch(WORKSPACE_PROFILE_ACTIONS.REMOVE_PROFILE_OPERATION({
      token,
      operationId: itemId,
      profileId,
      arrProfileId: workspaceProfiles.map(pi => pi.id)
    }))
  }

  const handleRemoveWorkspace = () => {
    setRemoveOperation({
      close: handleCloseModal,
      text: `${MESSAGES.REMOVE_OPERATION} Do you wish to continue?`,
      itemId: operationId,
      removeItem: deleteWorkspace
    })
    setOpen(true)
  }

  useEffect(() => {
    if (messageSuccess) {
      getNotification({
        title: TOAST_PROPERTIES.SUCCESS.title,
        message: messageSuccess.message || messageSuccess.status,
        icon: TOAST_PROPERTIES.SUCCESS.icon,
        color: TOAST_PROPERTIES.SUCCESS.color,
        position: TOAST_POSITION.leftTop,
        open: true,
        timeOut: 4000
      })

      setTimeout(() => {
        dispatch(WORKSPACE_PROFILE_ACTIONS.CLEAR_SUCCESS_MESSAGE())
      }, 2000)
    }
  }, [messageSuccess])

  useEffect(() => {
    if (messageError) {
      getNotification({
        title: TOAST_PROPERTIES.ERROR.title,
        message: messageError.message || messageError.status,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color,
        position: TOAST_POSITION.leftTopd,
        open: true,
        timeOut: 4000
      })
      setTimeout(() => {
        dispatch(WORKSPACE_PROFILE_ACTIONS.CLEAR_ERROR_MESSAGE())
      }, 2000)
    }
  }, [messageError])
  return (
    <>
      <OperationContainer>
        <Icon
          name='radio_button_checked'
          color={status === OPERATION_STATUS.ACTIVE ? 'success' : 'error'}
          size='large'
        />
        <Text text={name} size='medium' />
        <Text text={description} size='medium' />
        <Text text={command} size='medium' />
        <Icon name='delete' color='primary' size='large' onClick={handleRemoveWorkspace} />
      </OperationContainer>
      <Suspense fallback={<Loading color='primary' size='xsmall' weight='bold' />}>
        <Modal
          isShowing={open}
          close={handleCloseModal}
          title={TEXT_CONTENT.REMOVE_OPERATION}
          minHeight='auto'
        >
          {removeOperation
              && (
                <DeleteConfirmationModal
                  {...removeOperation}
                />
              )}
        </Modal>
      </Suspense>
    </>
  )
}

OperationCard.propTypes = {
  operationId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  command: PropTypes.string,
  status: PropTypes.oneOf([
    OPERATION_STATUS.ACTIVE,
    OPERATION_STATUS.INACTIVE
  ]),
  profileId: PropTypes.string
}

export default OperationCard

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import store from '@redux/store'
import history from '@redux/utils/history'

import App from '@routes/'

import WindowDimensionsProvider from '@components/windowDimensions'

import './index.css'

import Auth0ProviderWithHistory from '@containers/authentication/auth0Provider'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from './context/themeContext'

ReactDOM.render(
  <React.StrictMode>
    <WindowDimensionsProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider>
            <Auth0ProviderWithHistory>
              <App />
            </Auth0ProviderWithHistory>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </WindowDimensionsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

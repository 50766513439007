export const key = 'WORKSPACE'

export const WORKSPACE = {
  GET_WORKSPACE_DATA: 'WORKSPACE/GET/DATA',
  WORKSPACE_DATA_SUCCESS: 'WORKSPACE/GET/DATA/SUCCESS',
  GET_WORKSPACE_SUMMARY: 'WORKSPACE/SUMMARY/GET',
  GET_WORKSPACE_SUMMARY_LOADING: 'WORKSPACE/SUMMARY/LOADING',
  WORKSPACE_SUMMARY_SUCCESS: 'WORKSPACE/SUMMARY/GET/SUCCESS',
  WORKSPACE_SUMMARY_ERROR: 'WORKSPACE/SUMMARY/GET/ERROR',
  EDIT_WORKSPACE_SUMMARY: 'WORKSPACE/SUMMARY/EDIT',
  SUCCESS_MESSAGE: 'WORKSPACE/SUCCESS_MESSAGE',
  CLEAR_SUCCESS_MESSAGE: 'WORKSPACE/SUCCESS_MESSAGE/CLEAR',
  ERROR_MESSAGE: 'WORKSPACE/ERROR_MESSAGE',
  CLEAR_ERROR_MESSAGE: 'WORKSPACE/ERROR_MESSAGE/CLEAR',
  REGENERATE_API_KEY: 'WORKSPACE/REGENERATE_API_KEY',
  REMOVE_WORKSPACE: 'WORKSPACE/REMOVE_WORKSPACE'
}

export default key

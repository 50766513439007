import produce from 'immer'

import { WORKSPACE } from './types'

export const initialState = {
  loading: false,
  workspace: null,
  loadingSummary: false,
  summary: null,
  errorSummary: null,
  loadingDataSummary: false,
  messageSuccess: null,
  messageError: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case WORKSPACE.GET_WORKSPACE_DATA:
      draft.loading = true
      break
    case WORKSPACE.WORKSPACE_DATA_SUCCESS:
      draft.loading = false
      draft.workspace = payload
      break
    case WORKSPACE.GET_WORKSPACE_SUMMARY:
      draft.loadingSummary = true
      break
    case WORKSPACE.WORKSPACE_SUMMARY_SUCCESS:
      draft.loadingSummary = false
      draft.loadingDataSummary = false
      draft.summary = payload
      break
    case WORKSPACE.WORKSPACE_SUMMARY_ERROR:
      draft.loadingSummary = false
      draft.loadingDataSummary = false
      draft.errorSummary = payload
      break
    case WORKSPACE.EDIT_WORKSPACE_SUMMARY:
      draft.loadingSummary = true
      break
    case WORKSPACE.REGENERATE_API_KEY:
      draft.loadingDataSummary = true
      break
    case WORKSPACE.SUCCESS_MESSAGE:
      draft.loadingResources = false
      draft.messageSuccess = payload
      break
    case WORKSPACE.CLEAR_SUCCESS_MESSAGE:
      draft.messageSuccess = null
      break
    case WORKSPACE.ERROR_MESSAGE:
      draft.messageError = payload
      draft.loadingDataSummary = false
      break
    case WORKSPACE.CLEAR_ERROR_MESSAGE:
      draft.messageError = null
      break
    case WORKSPACE.CREATE_FOLDER:
      draft.loadingResources = true
      break
    default:
      return initialState
  }
})

export default reducer
